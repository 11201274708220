import styled, { css } from "styled-components";
import ProductCard from "./ProductCard";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "./category.css";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const ProductsHero = styled.div`
  margin-bottom: 75px;
  @media only screen and (min-width: 768px) {
    margin-top: 30px;
    opacity: 1;
    transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
`;

const ProductsHeroNavigationTabsContainer = styled.div`
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-top: 55px;
  width: 100%;
  text-align: center;
  @media only screen and (max-width: 1024px) {
    width: 80%;
  }
  @media screen and (max-width: 1030px) {
    width: 100%;
    -webkit-overflow-scrolling: touch;
  }
  @media screen and (max-width: 1170px) {
    margin-top: 0;
  }
  ul {
    @media screen and (max-width: 768px) {
      overflow-x: scroll;
    }
  }
`;

const ProductsTabs = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 1170px;
  min-height: 500px;
  background: #fff;
  padding-top: 32px;
`;

const ProductsCategory = styled.div`
  width: calc(100% - 40px);
  margin-left: auto;
  margin-right: auto;
  max-width: 1170px;
  @media only screen and (min-width: 1210px) {
    width: 100%;
    margin: 90px auto 210px;
  }
`;

const ProductsCategoryContainer = styled.div`
  margin-left: -40px;
  margin-top: -25px;
  @media only screen and (min-width: 1210px) {
    margin-left: -80px;
    margin-top: -30px;
  }
`;

export default function ProductTabs({ data }) {
  const { subcategory } = useParams();
  const [subcategoryUrlIndex, setSubcategoryUrlIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    const findSubcategory = () => {
      let subcat = subcategory
        .replace(/-/g, " ")
        .normalize("NFD")
        .replace(
          /([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,
          "$1"
        )
        .normalize();
      data[0]["subcategory"].forEach((el, idx) => {
        let name = el.name
          .replace("/", " ")
          .normalize("NFD")
          .replace(
            /([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,
            "$1"
          )
          .normalize();
        if (name.trim().toLowerCase() === subcat.toLowerCase()) {
          setSubcategoryUrlIndex(idx);
        }
      });
      setIsLoading(false);
    };
    if (subcategory) {
      findSubcategory();
    } else {
      setIsLoading(false);
    }
  }, [subcategory, data]);

  return (
    <>
      <ProductsHero>
        <ProductsHeroNavigationTabsContainer>
          <ProductsTabs>
            {!isLoading && (
              <TabsItem data={data} subcategoryUrlIndex={subcategoryUrlIndex} />
            )}
          </ProductsTabs>
        </ProductsHeroNavigationTabsContainer>
      </ProductsHero>
    </>
  );
}
const TabsItem = ({ data, subcategoryUrlIndex }) => {
  return (
    <Tabs selectedTabClassName="active" defaultIndex={subcategoryUrlIndex}>
      <TabList className="tab-list">
        {data[0]["subcategory"].map((el, idx) => (
          <Tab key={idx} className={`tab-item`}>
            {el.name}
          </Tab>
        ))}
      </TabList>

      {data[0]["subcategory"].map((el) => (
        <TabPanel>
          <ProductsCategory>
            <ProductsCategoryContainer>
              {el.products.map((i) => {
                const img = i.cover_image
                  ? `/public/${i.cover_image}`
                  : require("../../images/product/product-cover.jpg");
                return (
                  <ProductCard
                    key={i.product_code}
                    product_code={i.product_code}
                    image={img}
                    product_name={i.product_code}
                    description={i.short_description}
                  />
                );
              })}
            </ProductsCategoryContainer>
          </ProductsCategory>
        </TabPanel>
      ))}
    </Tabs>
  );
};
