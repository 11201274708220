export default function NavLearnMore () {
    return (
        <div class="o-box__nav_learn_more">
            <ul class="o-list__nav_learn_more">
                <li>
                    <a href="#lm1">
                    <picture>
                        <source 
                            type="image/png" 
                            media="(max-width:767px)" 
                            srcset={require('../../images/alpineflagship/lm/pl_learn_more1_sp.png')}
                        />
                        <img src={require('../../images/alpineflagship/lm/pl_learn_more1_pc.png')} alt="" />
                    </picture>
                    </a>
                </li>
                <li>
                    <a href="#perfect">
                    <picture>
                        <source 
                            type="image/png" 
                            media="(max-width:767px)" 
                            srcset={require('../../images/alpineflagship/lm/pl_learn_more2_sp.png')}
                        />
                        <img src={require('../../images/alpineflagship/lm/pl_learn_more2_pc.png')} alt="" />
                    </picture>
                    </a>
                </li>
                <li>
                    <a href="#lm7">
                    <picture>
                        <source 
                            type="image/png" 
                            media="(max-width:767px)" 
                            srcset={require('../../images/alpineflagship/lm/pl_learn_more3_sp.png')}
                        />
                        <img src={require('../../images/alpineflagship/lm/pl_learn_more3_pc.png')} alt="" />
                    </picture>
                    </a>
                </li>
            </ul>
        </div>
    )
}