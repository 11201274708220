import React from 'react';
import styled, {css} from 'styled-components';
import { SubscriptorService } from '../../service/SubscriptorService';
import { theme } from '../../styles/theme';
import FooterNewsletterValidation from './FooterNewsletterValidation';


const NewsCta = styled.p`
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    margin-bottom: 14px;
    margin-top: 0px;
    text-align: center;
    color: ${props => props.titlenewscolor};
    @media only screen and (min-width: 1025px) {
        font-size: 13px;
        width: 100%;
        text-align: left;
        margin-top: 40px;
    }
`

const InputContainer = styled.div`
    width: 80%;
    max-width: 500px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    @media only screen and (min-width: 1025px) {
        width: 230px;
    }
`

const NewsInput = styled.input`
    display: block;
    padding: 7px 35px 6px 16px;
    font-weight: 700;
    font-size: 15px;
    line-height: 26px;
    border: 1px solid ${theme.palette.grey400};
    background-color: transparent;
    width: 100%;
    
    // El color del placeholder que ya fue completado
	color: ${props => props.placeholdercolor};

	// El color del placeholder focus (cuando se escribe)
	&:focus {
		color: ${props => props.placeholdercolor} !important;
	}

	// El color del placeholder sin ser completado
	&::placeholder {
		color: ${props => props.placeholdercolor};
	}

    @media only screen and (min-width: 1025px) {
        font-size: 12px;
        padding: 10px 30px 11px 16px;
    }
`

const NewsButton = styled.button`
    display: inline-block;
    transform: translateY(3px);
    cursor: pointer;
    border: 0;
    background-color: transparent;
    padding: 0;
    position: absolute;
    top: 50%;
    right: 18px;
    transform: translateY(-50%);
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0;
    svg {
        display: inline-block;
        width: 1em;
        height: 1em;
        strokeWidth: 0;
        transform: rotate(-90deg);
    }
`

export default function FooterNesletter({textColor, placeholderColor, arrowColor}) {
    const service = new SubscriptorService();
    const [showValidation, setShowValidation] = React.useState(false);
    const [hasErrors, setHasErrors] = React.useState(false);
    const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    const [form, setForm] = React.useState({
        name: '',
        email: '',
    });

    const handleChange = (ev) => {
        setForm({
            ...form,
            [ev.target.name]: ev.target.value
        });
    }

    const saveSubcriptor = () => {
        setShowValidation(false);
        setHasErrors(false);
        if (form.name === '' || form.email === '') {
            setHasErrors(true);
            setShowValidation(true);
        }else if (!String(form.email).toLowerCase().match(re)) {
            setHasErrors(true);
            setShowValidation(true);
        }else{
            service.saveSubscriptor(form);
            setShowValidation(true);
        }
    }

    return (
        <div className="globalFooter__news__container hide--desktop">
            <NewsCta titlenewscolor={textColor}>
                Suscríbete a las noticias de Alpine (en inglés). ¡Entérate primero!
                <FooterNewsletterValidation
                    madeValidation={showValidation}
                    validationFail={hasErrors}
                />
            </NewsCta>
            <InputContainer>
                <NewsInput
                    name="name"
                    value={form.name}
                    onChange={handleChange}
                    type="text"
                    placeholder="Nombre"
                    placeholdercolor={placeholderColor}
                />
            </InputContainer>
            <InputContainer>
                <NewsInput
                    name="email"
                    value={form.email}
                    onChange={handleChange}
                    type="email"
                    placeholder="Dirección de correo"
                    placeholdercolor={placeholderColor}
                />
                <NewsButton type="submit" onClick={saveSubcriptor}>
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        viewBox="0 0 36 36" 
                        aria-labelledby="title"
                        arrowcolor={arrowColor}
                        style={{
                            stroke: arrowColor,
                            fill: arrowColor,
                        }}
                    >
                        <title>Arrow</title>
                        <path d="M0.098,1.369l17.05,34.107c0.235,0.468,0.803,0.658,1.271,0.424 c0.184-0.091,0.334-0.24,0.425-0.424l17.05-34.107c0.233-0.469,0.041-1.036-0.429-1.269c-0.267-0.134-0.583-0.132-0.85,0.003 L18.47,8.412L1.359,0.095C0.887-0.133,0.321,0.065,0.095,0.536C-0.032,0.8-0.031,1.108,0.098,1.369z"></path>
                    </svg>
                </NewsButton>
            </InputContainer>
        </div>
    )
}