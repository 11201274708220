import styled, {css} from 'styled-components';
import Header from "../components/header/Header";
import ProductHero from "../components/product/ProductHero";
import ProductHeroBadges from '../components/product/ProductHeroBadges';
import Breadcrumb from '../components/Breadcrumb';
import ProductParallaxBanner from '../components/ProductParallaxBanner';
import HeroImagesOverview from '../components/product/HeroImagesOverview';
import ProductSpecBuckets from '../components/product/ProductSpecBuckets';
import ProductSpecs from '../components/product/ProductSpecs';
import ProductPhones from '../components/product/ProductPhones';
import ProductSpecGrid from '../components/product/ProductSpecGrid';
import ManualBottom from '../components/product/ManualBottom';
import ProductApps from '../components/product/ProductApps';
import FeatureSlider from '../components/product/FeatureSlider';
import { IconArrow } from '../svg/IconArrow';
import { ProducService } from '../service/ProductService';
import { useParams } from 'react-router';
import React from 'react';
import { Link } from 'react-router-dom';

const ProductManager = styled.div`
`
const ProductSpec = styled.div`
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 116px;
`

export default function ProductSheet () {
    const service = new ProducService();
    const { product_code } = useParams();

    const [loading, setLoading] = React.useState(true);
    const [product, setProduct] = React.useState([]);

    React.useEffect(async () => {
        setLoading(true);
        const product = await service.getProductByProductCode(product_code);
        setProduct(product);
        setLoading(false);
    },[]);


    return (
        <>
            <Header sectionIn />
            {loading && 
                <div className='section-wrapper'>
                    <h5 style={{textAlign: "center"}}>Cargando...</h5>
                </div>
            }
            {!loading && 
                <ProductManager>
                    <div className='section-wrapper'>
                        <Breadcrumb>
                            <li>
                                <Link to={`/productos/${product.category}`}>{product.category} <IconArrow /></Link>
                            </li>
                            <li>
                                {product.product_name}
                            </li>
                        </Breadcrumb>

                        {/* Modulo 1 */}
                        <ProductHero product={product}/>

                        {/* Modulo 2 */}
                        <ProductHeroBadges
                            android={product.support_android}
                            apple={product.support_apple}
                        />
                    </div>

                    {/* Modulo 3 */}
                    {product.bg_image_1 && 
                        <ProductParallaxBanner 
                            background={product.bg_image_1} //TODO tema de rutas de imagenes
                            title={product.title_1}
                            subtitle={product.subtitle_1}
                        />
                    }

                    <ProductSpec>
                        {/* Modulo 4 */}
                        <HeroImagesOverview
                            srcLarge={product.features.main_image}
                            srcSmall={product.features.secondary_image}
                            videoSrc={product.features.video}
                            title="Descripción del producto"
                        >
                            <p>
                                {`${product.features.overview_description}`.replaceAll("#product_code#", `${product.product_name}`)}
                            </p>
                            <ProductSpecs 
                                features={product.features}
                                productName={product.product_name}
                            />
                        </HeroImagesOverview>

                        {/* Modulo 5 */}
                        <ProductSpecBuckets 
                            image1={product.features.image_1}
                            title1={product.features.title_1}
                            text1={product.features.description_1}
                            image2={product.features.image_2}
                            title2={product.features.title_2}
                            text2={product.features.description_2}
                            productName={product.product_name}
                        />
                    </ProductSpec>

                    {/* Modulo 6 */}
                    {product.bg_image_2 && 
                    <ProductParallaxBanner 
                        background={product.bg_image_2}
                        title={product.title_2}
                        subtitle={product.subtitle_2}
                    />
                    }

                    {/* Modulo 7 */}
                    {(product.support_android == 'yes' || product.support_apple == 'yes') &&
                    <ProductPhones
                        productName={product.product_name}
                        imageApple={product.apple_image}
                        titleApple="APPLE CARPLAY"
                        imageAndroid={product.android_image}
                        titleAndroid="ANDROID AUTO"
                    />
                    }

                    {/* Modulo 8 */}
                    {product.bg_image_3 && 
                    <ProductParallaxBanner
                        background={product.bg_image_3}
                        title={product.title_3}
                        subtitle={product.subtitle_3}
                    />
                    }

                    {/* Modulo 9 */}
                    {product.secondary_module9_descriptions.length > 0 && 
                        product.secondary_module9_descriptions.map(el => 
                            <ProductApps 
                                image={el.image}
                                title={el.title}
                                text={el.description}
                                productName={product.product_name}
                            />
                        )
                    }

                    {/* Modulo 10 */}
                    {product.secondary_module10_descriptions.length > 0 && 
                        <ProductSpec>
                            <ProductSpecGrid 
                                descriptions={product.secondary_module10_descriptions}
                                productName={product.product_name}
                            /> 
                        </ProductSpec>
                    }

                    {/* Modulo 11 */}
                    <FeatureSlider 
                        productName={product.product_name}
                    />

                    {/* Modulo 12 */}
                    {product.features.OwnerManualFilePath != '' &&
                    <ManualBottom 
                        link={product.features.OwnerManualFilePath}
                        productName={product.product_name}
                    />
                    }
                </ProductManager>
            }
        </>
    );
}