import axios from "axios";
const url = process.env.REACT_APP_API_URL || "https://alpine-arg.com/api";
const api = axios.create({
  baseURL: url,
});

api.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

export default api;
