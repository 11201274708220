import Header from "../components/header/Header";
import HeroImages from "../components/HeroImages";
import CenterImage from "../components/CenterImage";
import lifeCompany from '../images/lifestyle/f9fd7b4604c4aba37ef4a2eed0bd9523.jpg';
import lifeCompany1 from '../images/lifestyle/d9ac463b335605d3a4680c53d9df0835.jpg';
import LegadoLg from "../images/lifestyle/967ef6dfb5307fec5d222411ec084cb0.jpg";
import LegadoSm from '../images/lifestyle/dd9adf79c275b6e58a053a9c6dfaaf2e.jpg';
import SimpleLg from '../images/lifestyle/31f43c864d2bcd29ccaf7548506d69b6.jpg';
import SimpleSm from '../images/lifestyle/2a0fc5952031fae526ae7b06631e9900.jpg';
import InvestigacionLg from '../images/lifestyle/af73557a00a5cfc0375eae13eedb5342.jpg';
import InvestigacionSm from '../images/lifestyle/7ca9f6443717328b159b80e9eec42951.jpg';
import GlobalParallaxBanner from "../components/GlobalParallaxBanner";
import Banner from "../images/lifestyle/f85da12ae88edda4b0ffe40065536f19.jpg";
import Ul from "../components/ListText";
import ButtonArrow from "../components/ButtonArrow";
import styled from "styled-components";

export default function Company () {
    return (
        <>
            <Header sectionIn />
            <div className="section-wrapper">
                <HeroImages
                    alignRight
                    title='EN LA BÚSQUEDA DEL MEJOR SONIDO'
                    subtitle='ALPINE ELECTRONICS OF AMERICA'
                    srcLarge={lifeCompany}
                    srcSmall={lifeCompany1}
                >
                    <p>Alpine Electronics of America, Inc., el productor líder de la industria fundada en 1978, busca responder a las demandas de los consumidores y las empresas automovilísticas con productos de alta calidad y precios competitivos. Alpine Electronics, Inc. es una filial de propiedad absoluta de ALPS ALPINE CO., LTD., uno de los productores más importantes del mundo de componentes electrónicos para computadoras, comunicaciones y equipos electrónicos para autos.</p>
                </HeroImages>

                {/* Modulo 1 */}
                <CenterImage
                    imageOnLeft
                    srcTopImage={LegadoLg}
                    srcSideImage={LegadoSm}
                >  
                    <h4>LEGADO DE ENTRETENIMIENTO</h4>
                    <div>
                        Con un legado de más de 50 años entregando experiencias sonoras a la industria electrónica móvil, Alpine continúa entregando productos que satisface y supera las necesidades de nuestros clientes, los paseadores activos de fines de semana que aman la música. Las reflexiones basadas en una extensa investigación de clientes nos han llevado a crear productos que te dan el poder para experimentar excelente música en todas tus aventuras.
                    </div>
                </CenterImage>

                {/* Modulo 2 */}
                <CenterImage
                    srcTopImage={SimpleLg}
                    imageOnRight
                    srcSideImage={SimpleSm}
                >  
                    <h4>SIMPLE. SONIDO. DONDE SEA.</h4>
                    <div>
                        Cuando se trata de productos audiovisuales para tu vehículo, Alpine ha estado a la vanguardia de las innovaciones, liderando categorías de productos o incluso creándolas. La filosofía de Alpine combina una ingeniería inteligente y una búsqueda constante de materiales superiores para crear productos que deleitan tus oídos con la música que amas.
                    </div>
                </CenterImage>

                {/* Modulo 3 */}
                <CenterImage
                    imageOnLeft
                    srcTopImage={InvestigacionLg}
                    srcSideImage={InvestigacionSm}
                >  
                    <h4>Investigación y desarrollo</h4>
                    <div>
                        <div style={{marginBottom: '30px'}}>
                            Los equipos dentro del vehículo están sujetos a entornos hostiles, como vibraciones que cambian dependiendo de la superficie del camino, cambios en la temperatura y otros factores. Asimismo, para que los sistemas de audio de vehículos reproduzcan sonido de calidad, es vital tener un gran conocimiento sobre las características acústicas específicas que pueden impactar sobre la calidad de sonido, como el tamaño del interior del automóvil, la posición de los asientos y el material del tablero. Alpine ha desarrollado un sistema de investigación desarrollo avanzado que incluye entre otras cosas:
                        </div>
                        <Ul>
                            <li> Pruebas de vibración<br/></li>
                            <li>Pruebas de visibilidad<br/></li>
                            <li>Pruebas de durabilidad e impactos<br/></li>
                            <li>Pruebas de acústica<br/></li>
                            <li>Pruebas de temperatura y humedad<br/></li>
                            <li>Pruebas de onda de radio<br/></li>
                            <li>Ensayos no destructivos<br/></li>
                            <li>Centro de evaluación de asistencia al conductor<br/></li>
                        </Ul>
                        <div style={{marginTop: '30px'}}>
                            Nosotros estamos orgullosos de los productos que producimos y nuestros socios en todo Estados Unidos que trabajan con nosotros para proveer innovaciones electrónicas móviles, para asegurar que disfrutes tu viaje y estés seguro.
                        </div>
                    </div>
                </CenterImage>
                <div style={{textAlign: 'center', margin: '0 0 100px'}}>
                    <h2>Notas de prensa</h2>
                    <p style={{maxWidth: '800px', margin: '0 auto 10px',}}>Serás redirigido a la página oficial de Alpine USA, donde encontrarás las notas de prensa en inglés ordenados por año y por fecha de publicación.</p>
                    <ButtonArrow 
                        btnText="Notas de prensa"
                        link="https://www.alpine-usa.com/company/press"
                        target="_blank"
                        rel="noopener"
                    />
                    
                </div>
            </div>
            <GlobalParallaxBanner
                background={Banner}
                title="SIMPLE. SONIDO. DONDE SEA."
            />
        </>
    );
}