import Header from "../components/header/Header";
import SectionHeader from "../components/SectionHeader";

export default function Lifestyle () {
    return (
        <>
            <Header />
            <SectionHeader title="Estilo de vida" />
            <div className="section-wrapper">

            </div>
        </>
        
    );
}