import styled, { css } from "styled-components";
import Header from "../components/header/Header";
import ButtonArrow from "../components/ButtonArrow";
import GlobalParallaxBanner from "../components/GlobalParallaxBanner";
import CarouselHome from "../components/CarruselHome";
import { dataCarouselHome } from "../data/dataCarousel";
import { dataFeatureProducts } from "../data/dataFeatureProducts";
import ButtonText from "../components/ButtonText";
import FeatureProductsSlider from "../components/home/FeatureProductsSlider";
import HomeCategories from "../components/home/HomeCategories";
import BannerParalaxImg from "../images/home/paralax-home.jpg";
import InstagramFeed from "../components/home/InstagramFeed";
import { useEffect, useState } from "react";
import useToggleHeaderOnScroll from "../hooks/useToggleHeaderOnScroll";

const HomeAboutContentContainer = styled.div`
  height: 800px;
  text-align: center;
  position: relative;
`;

const HomeAboutSubtitle = styled.h3`
  font-weight: 400 !important;
  line-height: 1.5;
  text-transform: uppercase;
  font-size: 20px !important;
  @media only screen and (min-width: 768px) {
  }
`;

const HomeAboutTitle = styled.h1`
  max-width: 450px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 5px;
  font-size: 70px;
  line-height: 75px;
  letter-spacing: 0.04px;
  font-weight: 900;
  text-transform: uppercase;
  @media screen and (min-width: 769px) {
    margin-bottom: 37px;
  }
`;

const HomeAboutCopy = styled.p`
  max-width: 550px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 40px;
  overflow: hidden;
  font-size: 18px;
  line-height: 28px;
  font-weight: 300;
`;

const HomeAbout = styled.div`
  padding-bottom: 115px;
`;

const HomeAboutCaptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  max-width: 1170px;
  margin: 40px auto 0;
`;

const HomeAboutCaptionContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  width: 100%;
  p {
    max-width: 320px;
    overflow: hidden;
    padding-bottom: 8px;
  }
`;

export default function Home() {
  const { toggleHeader } = useToggleHeaderOnScroll();

  return (
    <>
      <Header sectionIn={toggleHeader} />
      <CarouselHome data={dataCarouselHome} />
      <div className="section-wrapper">
        <HomeAboutContentContainer>
          <HomeAboutSubtitle>Desde 1967</HomeAboutSubtitle>
          <HomeAboutTitle>52 años de dedicación al sonido</HomeAboutTitle>
          <HomeAboutCopy>
            Durante más de cuatro décadas, la marca Alpine ha sido sinónimo de
            una calidad de sonido premium y de innovación. Explora la pasión y
            la dedicación detrás de las mejoras para tu automóvil.
          </HomeAboutCopy>
          <ButtonArrow btnText="Aprende más" link="/compania" />
        </HomeAboutContentContainer>
      </div>
      <HomeAbout>
        <GlobalParallaxBanner
          title="Disfruta tus paseos"
          background={BannerParalaxImg}
        />
        <HomeAboutCaptionContainer>
          <HomeAboutCaptionContent>
            <p>
              Alpine sabe cómo mantenerte entretenido en el camino con las
              últimas tecnologías para vehículos y mejoras del sistema de
              sonido, ofreciendo innumerables opciones de audio y video.
            </p>
            <ButtonText link="#" text="Saber más" />
          </HomeAboutCaptionContent>
        </HomeAboutCaptionContainer>
      </HomeAbout>
      <FeatureProductsSlider data={dataFeatureProducts} />
      <HomeCategories />
      <InstagramFeed />
    </>
  );
}
