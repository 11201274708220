import styled from 'styled-components';
import { theme } from '../../styles/theme';


const Hamburger = styled.button`
    background: transparent;
    border: none;
    margin: 0;
    position: absolute;
    padding: 12px 16px;
    pointer-events: all;
    cursor: pointer;
    appearance: none;
    display:inline-block;
    z-index: 999;
    @media only screen and (min-width: 1005px) {
        display: none;
    }
`
const HamburgerBox = styled.div `
    position: relative;
    display: inline-block;
    width: 25px;
    height: 25px;
`
const HamburgerInner = styled.div`
    width: 100%;
    height: 2px;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    display: block;
    margin-top: -2px;
    background-color: ${(props) => props.hamburColor};
    &:before, &:after {
        display: block;
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: ${(props) => props.hamburColor};
    }
    &:before {
        top: -9px;
    }
    &:after {
        bottom: -9px;
    }
`

export default function MenuHamburguer ({onClick, sectionMobileIn}) {
    return (
        <Hamburger onClick={onClick}>
            <HamburgerBox>
                <HamburgerInner 
                    hamburColor={sectionMobileIn ? `${theme.palette.main500} !important` : "white"}
                >
                </HamburgerInner>
            </HamburgerBox>
        </Hamburger>
    );
}