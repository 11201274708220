import styled, {css} from 'styled-components';
import { theme } from '../../styles/theme';
import ButtonArrow from '../ButtonArrow';
import './instagram-feed.css';

export default function FakeInstagramFeed () {
    return (
        <div className='instagram-container'>

            <div className='row-collage'>
                <div className='box-sm'></div>
                <div className='box-md'></div>
                <div className='box-lg'></div>
                
            </div>

            <div className='footer-instagram'>
                <p>Configura tu escenario sonoro y disfruta de una experiencia completamente nueva.</p>
                <ButtonArrow 
                    btnText="Explora #sonidoALpine"
                    link="https://www.instagram.com/alpine_latinoamerica/"
                    rel="noreferer"
                    target="_blank"
                />
            </div>
        </div>
    );
}