import styled, {css} from 'styled-components';
import { theme } from '../styles/theme';
import { useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ButtonArrow from './ButtonArrow';


const SlideIn = styled.div`
    height: 80vh;
    position: relative;
    img {
        object-fit: cover;
        width: 100%;
        height: 110%; 
    }
`
const Content = styled.div`
    width: 100%;
    height: 100%;
`

const TitleBox = styled.div`
    position: absolute;
    width: 80%;
    max-width: 640px;
    top: 50%;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    color: white;
    margin: 0 auto;
    text-align: center;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1 {
        font-size: 35px;
        line-height: 1.2;
        color: white;
        margin: 0;
        @media only screen and (min-width: 768px) {
            font-size: 70px;
        }
    } 
`

const DotsUl = styled.ul`
    bottom: 100px;
    li {
        margin: 0 10px;
        button {
            width: 26px;
            height: 4px;
            padding: 0;
            background-color: white;
            button {
                background-color: ${theme.palette.grey400};
            }
            &:before {
                content: "";
            }
        }
    }
    li.slick-active {
        button {
            background-color: ${theme.palette.main600};
        }
    }
`

const Cover = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: black;
    opacity: ${props => props.coverOpacity};
    transition: all 0.3s;
    z-index: 9999;
`

export default function CarouselHome ({data}) {

    const settings = {
        fade: true,
        autoplay: true,
        autoplaySpeed: 6000,
        dots: true,
        appendDots: dots => <DotsUl>{dots}</DotsUl>,
        arrows: false,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: afterChangeAction,
        beforeChange: beforeChangeAction,
    };

    const [opacity, setOpacity] = useState(0);

    function beforeChangeAction() {
        setOpacity(1);
    }

    function afterChangeAction() {
        setOpacity(0);
    }

    

    const slides = data.map((i) => 
        <div key={i}>
            <SlideIn>
                <Content>
                    <img src={i.image} alt={i.title}></img>
                    <TitleBox>
                        <h1>{i.title}</h1>
                        <p>{i.description}</p>
                        <ButtonArrow 
                            btnText={i.buttontext} 
                            link={i.buttonlink}
                            bgColor="transparent"
                            textColor='white'
                            bgColorOver="white"
                            textColorOver={theme.palette.main500}
                        />
                    </TitleBox>
                </Content>
            </SlideIn>
        </div>
    );
        
    return (
        <div style={{backgroundColor: '#ccc'}}>
            <Slider {...settings}>
                {slides}
            </Slider>
        </div>
    )
}