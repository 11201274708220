import Header from "../components/header/Header";
import CenterImage from "../components/CenterImage";
import lifesyleInvestment from "../images/lifestyle/a158639d8c56caade8ae91c9cc7a5e00.jpg";
import lifesyleInvestment1 from "../images/lifestyle/c2faa7309b125f3ba8dd2a0c4a518062.jpg";
import lifestyleSafeguards from "../images/lifestyle/2a0fc5952031fae526ae7b06631e9900.jpg";
import lifestyleSafeguards1 from "../images/lifestyle/40d883263f8d883cbae8c0fed687d658.jpg";
import lifeSApproved from "../images/lifestyle/7d2284121ebf4b3bff1ef7ae66304db6.jpg";
import lifeSApproved1 from "../images/lifestyle/324aca1dde034a1042d5ca3c54f0acbc.jpg";
import ButtonArrow from "../components/ButtonArrow";
import styled from "styled-components";
import GlobalParallaxBanner from "../components/GlobalParallaxBanner";
import Banner from "../images/lifestyle/39b68a53f072a9cb9c64db21e891c755.jpg";
import Ul from "../components/ListText";
import HeroImagesApproved from "../components/HeroImagesApproved";
import useToggleHeaderOnScroll from "../hooks/useToggleHeaderOnScroll";

const AaHero = styled.div`
  margin-bottom: 50px;
  position: relative;
  media only screen and (max-width: 768px) {
    margin-bottom: 72px;
  }
`;

const AaHeroBackdrop = styled.div`
  z-index: -1;
  position: absolute;
  top: 300px;
  min-height: 900px;
  height: calc(80vh - 70px);
  width: 100%;
  content: "";
  background: linear-gradient(
    90deg,
    #003b6b,
    #00549a 40%,
    #00549a 60%,
    #003b6b
  );
  top: 0;

  @media only screen and (max-width: 768px) {
    height: 370px;
    min-height: 370px;
  }
`;

const AaHeroHeroContainer = styled.div`
  max-width: 1170px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 110px;
  padding-top: 177px;

  @media only screen and (max-width: 768px) {
    background-color: #fff;
    padding: 14px 10% 0 14px;
    margin-right: 0;
    margin-left: auto;
    width: 90%;
    z-index: 2;
    position: relative;
    top: -50px;
  }

  @media only screen and (max-width: 768px) {
    top: unset;
  }
  @media screen and (max-width: 768px) {
    position: relative !important;
    z-index: 30 !important;
  }
`;

const AaHeroHeroWrapper = styled.div`
  position: relative;
  z-index: 10;
`;

const AaHeroTitle = styled.h1`
  color: white !important;
  text-align: center;
  width: 75%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 8px;
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    text-align: left;
    margin-bottom: 5px;
    color: #212121 !important;
    width: 100%;
    font-size: 55px;
    line-height: 55px;
    letter-spacing: -0.025px;
  }

  @media only screen and (max-width: 768px) {
    font-size: 60px;
    font-size: 3.75rem;
    line-height: 60px;
    line-height: 3.75rem;
    letter-spacing: -0.025px;
    letter-spacing: -0.025rem;
  }
`;

const AaHeroSubtitle = styled.p`
  color: white;
  text-align: center;
  max-width: 800px;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    text-align: left;
    color: #212121;
    width: 100%;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0;
  }
`;

const AaBuyerBeware = styled.div`
  max-width: 1170px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 120px;
  width: 100%;

  @media only screen and (max-width: 768px) {
    margin-top: 94px;
  }
`;

const AaBuyerBewareWrapper = styled.div``;

const AaBBHeader = styled.h2`
  text-align: center;
  margin-bottom: 30px;
  width: 83.333333%;
  margin-left: 8.333333%;
  overflow: hidden;
  @media only screen and (max-width: 768px) {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
  }
`;

const AaBBBeforeContainer = styled.div`
  margin-bottom: 30px;
  @media only screen and (max-width: 768px) {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 65px;
  }
`;

const AaBBBefore = styled.div`
  width: 41.666666%;
  display: inline-block;
  vertical-align: top;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  &:first-child {
    margin-left: 8.333333%;
    padding-right: 28px;
    @media only screen and (max-width: 768px) {
      padding-right: 0;
      margin-bottom: 70px;
      margin-left: 0;
    }
  }

  &:last-child {
    padding-left: 28px;
    @media only screen and (max-width: 768px) {
      padding-left: 0;
    }
  }
`;

const AaBBCopy = styled.div``;

const TopImgMobile = styled.div`
  display: none;
  @media only screen and (max-width: 768px) {
    display: block;
    width: 80%;
    padding-top: 60px;
  }
`;

const List = styled.ul`
  list-style: outside !important;
  padding: 0 0 0 15px !important;
  li {
    margin-bottom: 10px;
  }
`

export default function AlpineApproved() {
  const { toggleHeader } = useToggleHeaderOnScroll();

  return (
    <>
      <Header sectionIn={toggleHeader} />
      <AaHero>
        <AaHeroBackdrop />
        <TopImgMobile>
          <img
            className="img-fluid"
            src={require("../images/lifestyle/7d2284121ebf4b3bff1ef7ae66304db6.jpg")}
          ></img>
        </TopImgMobile>
        <AaHeroHeroContainer>
          <AaHeroHeroWrapper>
            <AaHeroTitle>PROTEGE TU INVERSIÓN</AaHeroTitle>
            <AaHeroSubtitle>
              Alpine Electronics es uno de los mayores fabricantes del mundo de
              audio premium, navegación y sistemas de información para
              automóviles. Nuestra estricta política de garantía protege a los
              valiosos clientes y la reputación sin igual de la calidad y
              excelencia del servicio técnico.
            </AaHeroSubtitle>
          </AaHeroHeroWrapper>
        </AaHeroHeroContainer>
      </AaHero>
      <div className="section-wrapper">
        <HeroImagesApproved
          title="Aprobado por Alpine"
          srcLarge={lifeSApproved}
          srcSmall={lifeSApproved1}
        >
          <p>
            Nuestros productos son comercializados en Latinoamérica a través de
            una exclusiva red de tiendas autorizadas, seleccionadas por Alpine.
            La complejidad de los automóviles de hoy y la sofisticación de los
            productos Alpine requieren de un vendedor experto para ayudar en la
            selección, integración e instalación de tu sistema. Nuestras tiendas
            han sido elegidas específicamente por su capacidad para presentar e
            instalar los productos Alpine, y tienen los conocimientos para
            maximizar su rendimiento en tu vehículo. La política de garantía de
            Alpine cubre únicamente los productos adquiridos e instalados a
            través de nuestra red de tiendas autorizadas.{" "}
          </p>
          <p>
            Antes de comprar cualquier producto Alpine, confirma que sea una
            tienda autorizada. Clickea en <b>Buscador de tiendas</b> para una
            lista completa actualizada de todas las tiendas autorizadas en tu
            zona donde podrás comprar productos Alpine.{" "}
          </p>
          <ButtonArrow
            link="/buscador-de-tiendas"
            btnText="Buscador de Tiendas"
          />
        </HeroImagesApproved>
      </div>
      <GlobalParallaxBanner background={Banner} />
      <AaBuyerBeware>
        <AaBuyerBewareWrapper>
          <AaBBHeader>LO QUE DEBES SABER ANTES DE COMPRAR</AaBBHeader>
          <AaBBBeforeContainer>
            <AaBBBefore>
              <AaBBCopy>
                Algunas de las tiendas pueden tener sitios de Internet a través
                de los cuales están autorizadas para vender nuestros productos.
                Revisa el <a href="/buscador-de-tiendas">Buscador de tiendas</a>{" "}
                para confirmar si una tienda está autorizada para vender o
                instalar productos Alpine. Desafortunadamente, en ocasiones hay
                productos Alpine vendidos en tiendas o sitios de Internet que no
                están autorizados. En la mayoría de los casos, los productos
                vendidos por estas empresas no autorizadas no fueron comprados
                dentro de los canales oficiales de distribución de Alpine. Por
                lo tanto, Alpine no se responsabiliza por los productos
                adquiridos o instalados a través de tiendas o sitios de Internet
                no autorizados. Sólo las tiendas listadas en la sección{" "}
                <a href="/buscador-de-tiendas">Buscador de tiendas</a> de
                nuestra página web están autorizadas para ofrecer, vender e
                instalar productos Alpine.
                <div>
                  <br />
                </div>
                <div>
                  <b>
                    Los productos Alpine vendidos por tiendas no autorizados son
                    a veces:&nbsp;
                  </b>
                  <br />
                </div>
                <div>
                  <b>
                    <br />
                  </b>
                </div>
                <div>
                  <List>
                    <li>
                      Adquiridos en un mercado no oficial de otros países e
                      ingresados ilegalmente a Latinoamérica
                      <br />
                    </li>
                    <li>
                      Productos falsificados no producidos por Alpine
                      <br />
                    </li>
                    <li>
                      Productos dañados, defectuosos o utilizados anteriormente
                      (llamados stock "B")
                      <br />
                    </li>
                    <li>
                      Productos robados a los distribuidores autorizados u otras
                      fuentes
                    </li>
                  </List>
                  <div>
                    <br />
                  </div>
                </div>
              </AaBBCopy>
            </AaBBBefore>
            <AaBBBefore>
              <AaBBCopy>
                <div>
                  Muchas veces, las tiendas no autorizadas intentan eliminar o
                  alterar el número de serie. La eliminación, alteración o
                  manipulación del número de serie original de Alpine, anulará
                  automáticamente la garantía de ese producto. Tiendas no
                  autorizadas pueden engañar a los consumidores al dar a
                  entender que el producto está cubierto por la política de
                  garantía de Alpine. Ninguno de los productos vendidos por
                  tiendas no autorizadas tiene derecho a la cobertura de
                  garantía Alpine. Estamos enfocando nuestros mayores esfuerzos
                  para evitar estas prácticas ilegales que se aprovechan de los
                  consumidores, pero todavía siguen existiendo. Le recomendamos
                  tener mucho cuidado al seleccionar la tienda antes de realizar
                  su compra.
                  <br />
                </div>
                <div style={{ fontSize: "14px", marginTop: "20px" }}>
                  <i>
                    Por favor tome nota que no estamos afirmando que todas las
                    tiendas no autorizadas están involucradas en actividades
                    ilegales. Sin embargo, NINGÚN producto adquirido de una
                    tienda no autorizada tendrá la garantía de producto de
                    Alpine.
                  </i>
                </div>
                <div style={{ marginBottom: "20px" }}></div>
                <ButtonArrow
                  link="/buscador-de-tiendas"
                  btnText="Buscador de Tiendas"
                />
              </AaBBCopy>
            </AaBBBefore>
          </AaBBBeforeContainer>
        </AaBuyerBewareWrapper>
      </AaBuyerBeware>

      <div className="section-wrapper">
        <CenterImage
          srcTopImage={lifestyleSafeguards}
          imageOnLeft
          srcSideImage={lifestyleSafeguards1}
        >
          <h4>SALVAGUARDAS DE ALPINE</h4>
          <div>
            <b>
              En Alpine hemos tomado un papel de liderazgo en la lucha contra la
              distribución no autorizada:
            </b>
            <div>
              <br />
            </div>
            <div>
              <b>SEGUIMIENTO DE NÚMERO DE SERIE</b>
            </div>
            <div>
              Los productos Alpine se identifican con un número de serie único
              que se aplica a las cajas individuales y a la propia unidad. Todos
              los números de serie están representados en forma alfanumérica y
              en código de barras.
              <br />
            </div>
            <div>
              <br />
            </div>
            <div>
              <b>NÚMEROS DE SERIE OCULTOS</b>
              <br />
            </div>
            <div>
              Los productos Alpine también tienen un número de serie
              independiente integrado internamente en cada unidad. Esto facilita
              el seguimiento y la verificación del producto.
              <br />
            </div>
            <div>
              <br />
            </div>
            <div>
              <b>LA VIGILANCIA DE INTERNET</b>
              <br />
            </div>
            <div>
              Nos hemos asociado con varias empresas para identificar las ventas
              no autorizadas de nuestros productos. Cualquiera que sea
              sorprendido estará sujeto a acciones legales.
              <br />
            </div>
            <div>
              <br />
            </div>
            <div>
              <b>ESTAMPILLA DE ADUANA (ARGENTINA)</b>
              <br />
            </div>
            <div>
              En Argentina, todas las Unidades Principales, Amplificadores,
              Reproductores de DVD y Cámaras deben llevar adherida la estampilla
              de Aduana proporcionada por AFIP. Esta estampilla garantiza que el
              producto ha sido importado oficialmente. El número de
              identificación permite rastrear a qué despacho corresponde. La
              ausencia de esta estampilla implica que el producto ha sido
              ingresado al país de forma no oficial, por contrabando o para uso
              personal, que no autoriza su venta. Los productos que no posean la
              estampilla de Aduana correspondiente no cuentan con garantía en
              Argentina, y no serán recibidos para servicio a menos que sean
              acompañados por la factura de compra y la correspondiente
              declaración de Aduana.
              <br />
            </div>
          </div>
        </CenterImage>
        <CenterImage
          srcTopImage={lifesyleInvestment}
          imageOnRight
          srcSideImage={lifesyleInvestment1}
        >
          <h4>Protege tu inversión</h4>
          <div>
            <List>
              <li>
                Las tiendas autorizadas Alpine han sido cuidadosamente
                seleccionadas en base a su compromiso con la satisfacción del
                cliente y el conocimiento de la electrónica móvil.
                <br />
              </li>
              <li>
                Las tiendas Alpine tienen vasta experiencia en ventas y están
                altamente capacitadas para maximizar la calidad y el valor de
                los productos Alpine que venden.
                <br />
              </li>
              <li>
                Las tiendas Alpine han sido entrenadas por Alpine Latinoamérica
                en la aplicación e integración de nuestros productos.
                <br />
              </li>
              <li>
                Las tiendas Alpine tienen acceso y apoyo directo del equipo de
                expertos en Alpine Latinoamérica, incluyendo soporte telefónico
                y personal, así como también actualizaciones técnicas publicadas
                regularmente.
                <br />
              </li>
              <li>
                Las tiendas Alpine ofrecen la garantía de fábrica de todos los
                productos de Alpine. Los productos comprados a distribuidores o
                fuentes no autorizadas no tienen garantía de fábrica
                <br />
              </li>
              <li>
                Las tiendas autorizadas han firmado un acuerdo de distribución
                con Alpine Latinoamérica que permite ventas sólo en lugares
                aprobados y únicamente a los consumidores finales.
                <br />
              </li>
            </List>
          </div>
        </CenterImage>
      </div>
    </>
  );
}
