import styled, { css } from "styled-components";
import FooterNesletter from "./FooterNewsletter";
import { theme } from "../../styles/theme";
import { ProducService } from "../../service/ProductService";
import React from "react";
import { useNavigate } from "react-router-dom";

const GlobalFooterContentContainer = styled.div`
  padding-top: 50px;
  display: flex;
  justify-content: center;
  @media only screen and (min-width: 1025px) {
    justify-content: space-between;
  }
`;
const GlobalFooterContent = styled.div`
  display: none;
  @media only screen and (min-width: 1025px) {
    display: block;
    width: auto;
  }
  ${(props) =>
    props.newsletter &&
    css`
      display: block !important;
      width: 100%;
    `}
`;

const GlobalFooterHeader = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
  a {
    line-height: 18px;
    letter-spacing: 0.04px;
    letter-spacing: 0.04rem;
    font-family: Nunito Sans, sans-serif;
    font-weight: 900;
    font-style: normal;
    text-transform: uppercase;
  }
  ${(props) =>
    props.hidden &&
    css`
      display: none;
      @media only screen and (min-width: 1025px) {
        display: block;
      }
    `}
`;

const GlobalFooterLinks = styled.a`
  font-size: 13px;
  line-height: 26px;
  letter-spacing: -0.02rem;
  color: #00549a;
  svg {
    fill: #00549a;
  }
  &:hover {
    color: #212121;
    svg {
      fill: #212121;
    }
  }
`;

const GlobalFooterExternalLink = styled.div`
  margin-right: 5px;
  margin-left: -20px;
  width: 12px;
  height: 12px;
  display: inline-block;
  font-size: 11px;
  font-size: 0.6875rem;
  line-height: 11px;
  line-height: 0.6875rem;
  letter-spacing: 0;
`;

export default function FooterContent() {
  let navigate = useNavigate();
  const service = new ProducService();
  const [categories, setCategories] = React.useState(null);
  const showCategories = async () => {
    const _categories = await service.getCategory();
    setCategories(_categories);
  };
  const redirectToCategory = (category) => {
    navigate(`/productos/${category}`);
  };

  React.useEffect(() => {
    showCategories();
  }, []);

  return (
    <GlobalFooterContentContainer>
      <GlobalFooterContent>
        <GlobalFooterHeader>
          <a href="/productos/Amplificadores">Productos</a>
        </GlobalFooterHeader>
        <ul className="globalFooter__links">
          <li>
            <GlobalFooterLinks href="/alpineflagship">
              AlpineF#1Status
            </GlobalFooterLinks>
          </li>
          {categories &&
            categories.map((el, i) => (
              <li key={i}>
                <GlobalFooterLinks
                  href="#"
                  onClick={() => redirectToCategory(el.category)}
                >
                  {el.category}
                </GlobalFooterLinks>
              </li>
            ))}
        </ul>
      </GlobalFooterContent>
      <GlobalFooterContent>
        <GlobalFooterHeader>
          <a href="/compania">Compañia</a>
        </GlobalFooterHeader>
        <ul className="globalFooter__links">
          <li>
            <GlobalFooterLinks href="/compania">
              Acerca de Alpine
            </GlobalFooterLinks>
          </li>
          <li>
            <GlobalFooterLinks 
              href="https://www.alpine-usa.com/company/press" 
              rel="noreferer" 
              target="_blank"
            >
              Notas de prensa
            </GlobalFooterLinks>
          </li>
          <li>
            <GlobalFooterLinks href="/condiciones-de-uso">
              Condiciones de uso
            </GlobalFooterLinks>
          </li>
          <li>
            <GlobalFooterLinks href="https://www.alpine.com" target="_blank">
              <GlobalFooterExternalLink>
                <svg
                  className="icon icon-externalLink"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 36 36"
                  aria-labelledby="title"
                >
                  <polygon points="35.993,0 32,0 23.993,0 23.993,4 29.172,4 12.595,20.577 15.423,23.405 32,6.829 32,12 36,12 36,0   "></polygon>
                  <polygon points="31.993,16 27.993,16 27.993,31.986 4,31.986 4,7.993 19.993,7.993 19.993,4 0.007,3.993 0.007,4 0,4 0,31.986 0,35.986 4,35.986 27.993,35.986 27.993,35.993 31.993,35.993 31.993,35.986 32,35.986 32,31.986 31.993,31.986"></polygon>
                </svg>
              </GlobalFooterExternalLink>
              Alpine Worldwide
            </GlobalFooterLinks>
          </li>
        </ul>
      </GlobalFooterContent>
      <GlobalFooterContent>
        <GlobalFooterHeader>
          <a href="/soporte">Soporte</a>
        </GlobalFooterHeader>
        <ul className="globalFooter__links">
          <li>
            <GlobalFooterLinks href="/soporte">Contacto</GlobalFooterLinks>
          </li>
          <li>
            <GlobalFooterLinks href="/soporte">
              Service Alpine
            </GlobalFooterLinks>
          </li>
          <li>
            <GlobalFooterLinks
              href="https://kb.alpine-usa.com/"
              target="_blank"
            >
              <GlobalFooterExternalLink>
                <svg
                  className="icon icon-externalLink"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 36 36"
                  aria-labelledby="title"
                >
                  <polygon points="35.993,0 32,0 23.993,0 23.993,4 29.172,4 12.595,20.577 15.423,23.405 32,6.829 32,12 36,12 36,0   "></polygon>
                  <polygon points="31.993,16 27.993,16 27.993,31.986 4,31.986 4,7.993 19.993,7.993 19.993,4 0.007,3.993 0.007,4 0,4 0,31.986 0,35.986 4,35.986 27.993,35.986 27.993,35.993 31.993,35.993 31.993,35.986 32,35.986 32,31.986 31.993,31.986"></polygon>
                </svg>
              </GlobalFooterExternalLink>
              Knowledge Center
            </GlobalFooterLinks>
          </li>
        </ul>
      </GlobalFooterContent>
      <GlobalFooterContent newsletter>
        <GlobalFooterHeader hidden>
          <a href="/aprobado-por-alpine">Aprobado por Alpine</a>
        </GlobalFooterHeader>
        <GlobalFooterHeader hidden>
          <a href="/buscador-de-tiendas">Buscador de Tiendas</a>
        </GlobalFooterHeader>
        <FooterNesletter
          textColor={theme.palette.grey700}
          placeholderColor={theme.palette.main500}
          arrowColor={theme.palette.main500}
        />
      </GlobalFooterContent>
    </GlobalFooterContentContainer>
  );
}
