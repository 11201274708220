import api from "./api";


export const getToken = async () => {
  const { data } = await api.post("/login", {
    email: "admin@email.com",
    password: "12341234",
  });
  return data;
};
