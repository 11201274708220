import { useEffect, useState } from "react";

const useToggleHeaderOnScroll = () => {
  const [offset, setOffset] = useState(0);
  const [toggleHeader, setToggleHeader] = useState(false);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  useEffect(() => {
    if (offset >= 180) {
      setToggleHeader(true);
    } else {
      setToggleHeader(false);
    }
  }, [offset]);

  return {
    toggleHeader,
  };
};
export default useToggleHeaderOnScroll;
