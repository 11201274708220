import Header from "../components/header/Header";
import SearchProductCard from "../components/search-results/search-product-card";
import useSearch from "../hooks/useSearch";

export default function SearchResult() {
  const {
    searchValue,
    productsToSearch,
    setSearchValue,
    handleBuscar,
    currentWord,
  } = useSearch();

  const handleEnter = async (e) => {
    if (e.key === "Enter") {
      await handleBuscar(e, false);
    }
  };
  return (
    <>
      <Header sectionIn />
      <h1>Búsqueda</h1>

      <div className="searchResultsContainer">
        <div className="section-wrapper">
          <div className="searchResultsBar">
            <p className="searchResultsBar__subheader">
              Resultados para: "{currentWord}"
            </p>
            <div className="searchResultsBar__container">
              <div className="searchResultsBar__inputs">
                <div className="searchResultsBar__searchIcon__container">
                  <i className="searchResultsBar__searchIcon fas fa-search"></i>
                </div>
                <input
                  type="text"
                  className="searchResultsBar__inputText"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  onKeyDown={handleEnter}
                />
              </div>
              <div className="searchResultsBar__buttonBlue">
                <div className="buttonBlue">
                  <span className="buttonBlue__text" onClick={handleBuscar}>
                    Buscar
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="searchResultsResults">
            <p className="searchResultsResults__subheader">
              Products Related to "{currentWord}" (
              {productsToSearch && productsToSearch.length})
            </p>
            <div className="searchResultsResults__line"></div>
            <ul className="searchResultsResults__list">
              {productsToSearch &&
                productsToSearch.map((product) => (
                  <SearchProductCard
                    image={product.cover_image}
                    productName={product.product_name}
                    shortDescription={product.short_description}
                  />
                ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
