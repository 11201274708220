import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { GlobalStyles } from "../styles/globalStyles";
import Home from "../pages/Home";
import Category from "../pages/Category";
import Company from "../pages/Company";
import AlpineApproved from "../pages/AlpineApproved";
import Support from "../pages/Support";
import SearchResult from "../pages/SearchResult";
import StoreLocator from "../pages/StoreLocator";
import Flagship from "../pages/AlpineFlagship/Flagship";
import LearnMore from "../pages/AlpineFlagship/LearnMore";
import Lifestyle from "../pages/Lifestyle";
import Product from "../pages/AlpineFlagship/Product";
import ProductSheet from "../pages/ProductSheet";
import Footer from "../components/footer/Footer";
import TermsOfUse from "../pages/TermOfUse";
import Page404 from "../pages/Page404";
import Page505 from "../pages/Page505";
import ScrollToTop from "../components/ScrollToTop";

export default function Rutas() {
  return (
    <Router>
      <ScrollToTop>
        <GlobalStyles>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="productos/:category" element={<Category />} />
            <Route
              path="productos/:category/:subcategory"
              element={<Category />}
            />
            <Route path="producto/:product_code" element={<ProductSheet />} />
            <Route path="lifestyle" element={<Lifestyle />} />
            <Route path="compania" element={<Company />} />
            <Route path="buscador-de-tiendas" element={<StoreLocator />} />
            <Route path="aprobado-por-alpine" element={<AlpineApproved />} />
            <Route path="soporte" element={<Support />} />
            <Route path="busqueda" element={<SearchResult />} />
            <Route path="condiciones-de-uso" element={<TermsOfUse />} />

            <Route path="alpineflagship" element={<Flagship />} />
            <Route path="alpineflagship/aprendemas" element={<LearnMore />} />
            <Route path="alpineflagship/producto" element={<Product />} />
            <Route path="505" element={<Page505 />} />
            <Route path="404" element={<Page404 />} />
            <Route path="*" element={<Navigate to={"404"} replace />} />
          </Routes>
          <Footer />
        </GlobalStyles>
      </ScrollToTop>
    </Router>
  );
}
