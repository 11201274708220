import styled from "styled-components";
import { theme } from "./theme";

export const GlobalStyles = styled.div`
    @import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;700;900&display=swap');
    
    font-family: ${theme.font.primaryfont};
    font-weight: 300;
    line-height: 1.5;
    color: ${theme.palette.grey800};
    font-size: 18px;
    letter-spacing: 0.04em;
    font-smoothing: auto !important;
    -webkit-font-smoothing: auto !important;
    
    a {
        text-decoration: none;
        color: ${theme.palette.main500};
    }

    p {
        font-family: ${theme.font.primaryfont};
        font-weight: 400;
    }

    b {
        font-weight: 700;
    }

    ol, ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    & h1 {
        font-family: ${theme.font.primaryfont};
        font-weight: 900;
        text-transform: uppercase;
        color: ${theme.palette.grey800};
        font-size: 65px;
        line-height: 65px;
        letter-spacing: -0.01em;
        margin-top: 0;
        @media screen and (max-width: 768px) {
            font-size: 40px;
            line-height: 40px;
        }
    }

    & h2 {
        font-family: ${theme.font.primaryfont};
        font-weight: 900;
        margin-top: 0;
        font-size: 50px;
        line-height: 50px;
        letter-spacing: -.025px;
        text-transform: uppercase;
        @media screen and (max-width: 768px) {
            font-size: 35px;
            line-height: 35px;
        }
    }

    & h3 {
        font-family: ${theme.font.primaryfont};
        font-weight: 900;
        margin-top: 0;
        font-size: 40px;
        line-height: 40px;
        letter-spacing: -.025px;
        text-transform: uppercase;
        @media screen and (max-width: 768px) {
            font-size: 28px;
            line-height: 28px;
        }
    }

    & h4 {
        font-family: ${theme.font.primaryfont};
        font-weight: 900;
        font-size: 22px;
        letter-spacing: -0.01em;
        margin-top: 0;
        text-transform:uppercase;
        @media screen and (max-width: 768px) {
            font-size: 20px;
            line-height: 20px;
        }
    }

    & h5 {
        margin-top: 0;
    }

    & h6 {
        font-size: 16px;
        margin-top: 0;
    }


    article, aside, details, figcaption, figure, 
    footer, header, hgroup, menu, nav, section {
        display: block;
    }

    blockquote, q {
        quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    .global-container {
        max-width: 1170px;
        margin: 0 auto;
    }

    .section-wrapper {
        max-width: 1170px;
        margin: 100px auto 40px;
        @media only screen and (max-width: 1210px) {
            margin-left: 20px;
            margin-right: 20px;  
        }
    }

    .img-fluid {
        max-width: 100%;
        height: auto;
    }
`;


// Breakpoints 
// Menu header > @media only screen and (min-width: 1005px) {}
// General > 1024px / 1170px
