export default function SearchProductCard({ productName, shortDescription, image }) {
  return (
    <li class="searchResultsResults__item">
      <div class="searchResultsResults__img__container">
        <img
          alt="img"
          class="searchResultsResults__img"
          style={{ maxWidth: "300px" }}
          src={`/public/${image}`}
        />
      </div>
      <div class="searchResultsResults__productInfo__container">
        <p class="searchResultsResults__product__title">
          <a href={`/producto/${productName}`}>{productName}</a>
        </p>
        <p class="searchResultsResults__product__copy">{shortDescription}</p>
        <div class="searchResultsResults__details__container">
          <div class="searchResultsResults__button__container">
            <div class="searchResultsResults__buttonBlue">
              <div class="buttonBlue">
                <a class="buttonBlue__text" href={`/producto/${productName}`}>
                  <span>Ver detalles</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
