import styled, {css} from 'styled-components';
import { theme } from '../../styles/theme';
import Button from '../Button';

const ManualBottonWrapper = styled.div`
    padding: 100px 0;
    background-color: ${theme.palette.main500};
    text-align: center;
    @media only screen and (max-width: 768px) {
        padding: 50px 0;
    }
`

const ProductName = styled.p`
    font-weight: 900 !important;
    font-size: 40px;
    color: white;
    margin:0 0 20px;
`

export default function ManualBottom({productName, link}) {
    return (
        <ManualBottonWrapper>
            <ProductName>
                {productName}
            </ProductName>
            <Button 
                link={`/public/${link}`}
                bgColor={theme.palette.grey800}
                bgColorOver={theme.palette.grey800}
                btnText={productName}
            />
        </ManualBottonWrapper>
    );
}