import styled, {css} from 'styled-components';
import { theme } from '../styles/theme';


const GlobalButtonArrow = styled.a`
    cursor: pointer;
    text-decoration: none;
    position: relative;
    background: transparent;
    border: none;
    border-radius: 0;
    display: inline-flex;
    flex-direction: row;
    &:hover {
        span {
            background-color: ${props => props.bgColorOver};
            color: ${props => props.textColorOver} !important;
        }
        > div:before, &:after { 
            border-color: transparent transparent transparent ${props => props.bgColorOver};
        }
        > div:before{
            border-left-color: ${props => props.bgColorOver};
        }
    }
`

const GlobalButtonText = styled.div`
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    height: 32px;
    justify-content: center;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    z-index: 1;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: .035px;
    font-weight: 900;
    font-style: normal;
    color: ${props => props.textColor};

    &:before, &:after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 16px 0 16px 11px;
        border-color: transparent transparent transparent ${props => props.bgColor};
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
    }

    &:before{
        border-left-color: ${props => props.bgColor};
        top: 0;
        right: 1px;
        opacity: 1;
        position: absolute;
        transition: .3s ease-in-out;
        z-index: 1;
    }

    &:after {
        transition: .3s ease-in-out;
        top: 0;
    }

    span {
        background-color: ${props => props.bgColor};
        align-items: center;
        display: inline-flex;
        justify-content: center;
        padding: 4px 26px 3px 28px;
        transition: .3s ease-in-out;
        width: 100%;
        height: 100%;
        color: ${props => props.textColor};
        &:hover {
            color: ${props => props.textColorOver};
        }
    }
`



export default function ButtonArrow({
    link, 
    bgColor, 
    bgColorOver, 
    btnText,
    textColor,
    textColorOver, 
    target, 
    rel
}) {
    return (
        <GlobalButtonArrow
            href={link}
            bgColor={bgColor ? bgColor : theme.palette.main500} 
            bgColorOver={bgColorOver ? bgColorOver : theme.palette.grey800}
            target={target}
            rel={rel}
        >
            <GlobalButtonText 
                bgColor={bgColor ? bgColor : theme.palette.main500}
                bgColorOver={bgColorOver ? bgColorOver : theme.palette.grey800}
                textColor={textColor ? textColor : 'white'}
                textColorOver={textColorOver ? textColorOver : 'white'}
            >
                <span 
                    bgColor={bgColor ? bgColor : theme.palette.main500}
                    bgColorOver={bgColorOver ? bgColorOver : theme.palette.grey800}
                    textColorSpan={textColor ? textColor : 'white'}
                    textColorOver={textColorOver ? textColorOver : 'white'}
                >
                    {btnText}
                </span>
            </GlobalButtonText>
        </GlobalButtonArrow>
    )
}