import styled, {css} from 'styled-components';
import { theme } from '../../styles/theme';
import AndroidAuto from '../../images/product/icon-android-auto.png';
import AppleCarPlay from '../../images/product/icon-apple-car-play.png';

const ProductHeroBadgesWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 70px;
    @media only screen and (max-width: 1024px) {
        order: 10;
    }
`

const ProductHeroBadgesBadge = styled.div`
    margin: 50px 0 0;
    padding: 0 20px;
    @media only screen and (max-width: 1024px) {
        margin: 10px 0!important;
    }
    img {
        max-width: 133px;
    }
`

export default function ProductHeroBadges({ android, apple }) {
    return (
        <ProductHeroBadgesWrapper>
            {android === 'yes' && 
                <ProductHeroBadgesBadge>
                    <img src={AndroidAuto} alt="Logo Android Auto" />
                </ProductHeroBadgesBadge>
            }
            {apple === 'yes' && 
                <ProductHeroBadgesBadge>
                    <img src={AppleCarPlay} alt="Logo Android Auto" />
                </ProductHeroBadgesBadge>
            }
        </ProductHeroBadgesWrapper>    
    );
}