import styled, {css} from 'styled-components';
import { theme } from '../../styles/theme';
import ProductSlider from './ProductSlider';
import Button from '../Button';

const ProductHeroWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    max-width: 1170px;
    margin: 0 auto 25px;
    @media only screen and (max-width: 768px) {
        margin: 100px auto 44px;
    }
    @media only screen and (max-width: 1024px) {
        flex-direction: column;
    }
`

const ProductHeroItemContainer = styled.div`
    width: 50%;
    display: inline-block;
    vertical-align: top;
    padding: 30px;
    @media only screen and (max-width: 768px) {
       margin-top: 35px;
    }
    @media only screen and (max-width: 768px) {
        order: 1!important;
        margin-top: 0!important;
    }
    @media only screen and (max-width: 1024px) {
        order: 3;
        width: 100%;
        margin-top: 45px;
    }
`

const ProductHeroCopyContainer = styled.div`
    width: 50%;
    display: inline-block;
    vertical-align: top;
    padding: 14px;
    @media only screen and (max-width: 1024px) {
        order: 1;
        width: 100%;
    }
`

const ProductHeroName = styled.h1`
    font-weight: 900;
    font-size: 30px !important;
    margin-bottom: 20px;
    line-height: 34px;
    @media only screen and (max-width: 768px) {
        margin-bottom: 15px;
        padding-left: 0;
        padding-right: 0;
    }
`

const ProductHeroCopy = styled.div`
    margin-bottom: 20px;
    @media only screen and (max-width: 768px) {
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0;
        padding-left: 0;
        padding-right: 0;
    }
`

const ProductHeroButtons = styled.div`
    display: flex;
`


export default function ProductHero({ product }) {
    return (
        <ProductHeroWrapper>
            <ProductHeroItemContainer>
                <ProductSlider slides={product.slides} />
            </ProductHeroItemContainer>
            <ProductHeroCopyContainer>
                <ProductHeroName>
                    {product.product_code}
                </ProductHeroName>
                <ProductHeroCopy>
                    {product.short_description}
                </ProductHeroCopy>
                <ProductHeroButtons>
                    <div>
                        <Button 
                            link="/buscador-de-tiendas"
                            bgColor="black"
                            bgColorOver="black"
                            btnText="Buscador de Tiendas"
                        />
                    </div>
                </ProductHeroButtons>
            </ProductHeroCopyContainer>
        </ProductHeroWrapper>
    );
}