
export default function FooterNewsletterValidation ({madeValidation, validationFail}) {

    const validation = madeValidation;
    const validationfail = validationFail;

    return (
        <p style={{
            display: validation ? 'block' : 'none',
            textAlign: 'center'
        }}>
            
            {validationfail ? (
                <div>
                    <div>PLEASE ENTER ALL REQUIRED FIELDS:</div>
                    <div>Name is required</div>
                    <div>Valid email is required</div>
                </div>
            ) : (
                <div>Thanks for signing up!</div> 
            )
            }
        </p>        
    );
}


