import { createContext, useEffect, useState } from "react";
import { fetchProductsList } from "../service/products";
import { getToken } from "../service/user";
import { setSession } from "../utils/token";
export const SearchContext = createContext(null);
export const SearchProvider = ({ children }) => {
  const [searchValue, setSearchValue] = useState("");
  const [currentWord, setCurrentWord] = useState("");
  const [productsToSearch, setProductsToSearch] = useState(null);
  const [showSearchWidget, setShowSearchWidget] = useState(false);
  const handleShowSearchWidget = () => setShowSearchWidget(!showSearchWidget);
  useEffect(() => {
    const login = async () => {
      const { token } = await getToken();
      setSession(token);
    };
    login();
  }, []);

  const handleBuscar = async (e, showWidget = true) => {
    if (searchValue.trim() === "") return;
    const { data } = await fetchProductsList();
    let value = searchValue.toLowerCase();
    let toSearch = [];
    data.forEach((product) => {
      if (
        product.product_name.toLowerCase().includes(value) ||
        product.short_description.toLowerCase().includes(value) ||
        product.category.toLowerCase().includes(value)
      ) {
        toSearch.push(product);
      }
    });
    setProductsToSearch(toSearch);
    setCurrentWord(searchValue);
    window.scrollTo(0, 0);
    if (showWidget) {
      handleShowSearchWidget();
    }
  };
  return (
    <SearchContext.Provider
      value={{
        searchValue,
        setSearchValue,
        productsToSearch,
        handleBuscar,
        showSearchWidget,
        handleShowSearchWidget,
        currentWord,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
