import styled, { css } from "styled-components";
import "./mega-header.css";

export default function MegaHeader({
  category,
  categoryCustomLink,
  subcategories,
}) {
  const SubcatWrapper = (subcategory, category) => {
    const parseCategoryNameToUrl = (name) => {
      return name
        .replace(/ /g, "-")
        .replace("/", "-")
        .normalize("NFD")
        .replace(
          /([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,
          "$1"
        );
    };
    return (
      <div className="subcat-wrapper">
        <a
          href={`/productos/${category}/${parseCategoryNameToUrl(
            subcategory.name
          )}`}
        >
          <h6>{subcategory.name}</h6>
        </a>
        <ul className="subcat-list">
          {subcategory.products &&
            subcategory.products.map((el, i) => (
              <li key={i}>
                <a href={`/producto/${el.product_code}`}>{el.product_code}</a>
              </li>
            ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="dropdown-product-cat">
      <a
        href={
          categoryCustomLink ? categoryCustomLink : `/productos/${category}`
        }
        className="dropbtn-cat"
      >
        {category}
        <span className="arrow">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 343 492">
            <polygon points="343 246 0 492 45 246 0 0 343 246"></polygon>
          </svg>
        </span>
      </a>
      {subcategories && (
        <div className="dropdown-product-cat-content">
          <div className="dropdown-product-cat-content-wrapper">
            {subcategories.length > 0 &&
              subcategories.map((el, i) => (
                <div key={i}>{SubcatWrapper(el, category)}</div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
