import styled, { css } from "styled-components";
import { theme } from "../../styles/theme";
import LogoAlpineTagline from "../../svg/logo-alpine-tagline";
import SearchQuickLinks from "./SearchQuickLinks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import useSearch from "../../hooks/useSearch";
import { useNavigate } from "react-router-dom";

const GlobalNavSearchWidgetContainer = styled.div`
  position: absolute;
  background-color: ${theme.palette.main500};
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  padding: 0;
  transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1) 0.5s,
    opacity 0.3s ease-in-out 0.8s;
  transform: translateY(-100%);
  color: white;
  z-index: 900;

  ${(props) =>
    props.open &&
    css`
      z-index: 920;
      opacity: 1;
      transform: translateY(0);
      transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1),
        background-color 0.7s cubic-bezier(0.19, 1, 0.22, 1);
    `}

  @media only screen and (min-width: 1005px) {
    padding: 35px 35px 45px;
  }
`;

const SearchWidgetLogoContainer = styled.div`
  display: none;
  position: absolute;
  width: 120px;
  top: 40px;
  left: 50px;
  @media only screen and (min-width: 1005px) {
    display: block;
  }
`;

const SearchWidgetContainer = styled.div`
  width: 100%;
  max-width: none;
  margin-left: auto;
  margin-right: auto;
  @media only screen and (min-width: 1005px) {
    width: 30%;
    max-width: 430px;
  }
`;

const SearchWidgetSearch = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 50px;
  opacity: 0;
  transition: 1s cubic-bezier(0.19, 1, 0.22, 1);

  &:before {
    content: "";
    background: #fff;
    bottom: 0;
    left: 0;
    position: absolute;
    transition: 0.8s cubic-bezier(0.19, 1, 0.22, 1);
    width: 0;
    height: 1px;
  }

  ${(props) =>
    props.open &&
    css`
      transition: 1.2s cubic-bezier(0.19, 1, 0.22, 1) 0.2s;
      opacity: 1;
      &:before {
        transition: 0.8s cubic-bezier(0.19, 1, 0.22, 1) 0.4s;
        width: 100%;
        height: 1px;
      }
    `}

  &:hover {
    background-color: ${theme.palette.main600};
  }

  @media only screen and (min-width: 1005px) {
    justify-content: left;
  }
`;

const SearchWidgitSearchWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

const SearchWidgitIcon = styled.button`
  background: transparent;
  border-radius: none;
  border: none;
  color: #fff;
  display: inline-block;
  transform: translateY(-35px);
  width: 15%;
  font-size: 15px;
  transition: 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  ${(props) =>
    props.open &&
    css`
      transform: translateY(0);
      transition: 0.7s cubic-bezier(0.19, 1, 0.22, 1) 0.2s;
    `}
`;

const SearchWidgetInput = styled.input`
  display: inline-block;
  border: none !important;
  background-color: transparent;
  color: white !important;
  width: 100%;
  height: 100%;
  font-size: 18px;

  // El color del placeholder que ya fue completado
  color: white;

  // El color del placeholder focus (cuando se escribe)
  &:focus {
    color: white !important;
  }

  // El color del placeholder sin ser completado
  &::placeholder {
    color: white;
  }
`;

const MobileCloseContainer = styled.div`
  content: "";
  width: 26px;
  height: 26px;
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  padding: 2px;
  cursor: pointer;
  border: 1px solid white;
  z-index: 9999;

  @media only screen and (min-width: 1005px) {
    top: 20px;
    right: 20px;
  }
`;

const MobileCloseInner = styled.div`
  background-color: white;
  width: 20px;
  height: 2px;
  content: "";
  border-radius: 25%;
  transform: rotate(-45deg);
  &:after {
    display: block;
    background-color: white;
    content: "";
    height: 2px;
    width: 20px;
    border-radius: 25%;
    transform: rotate(90deg);
    margin-top: 8px;
  }
`;

export default function SearchWidget({ onClick, open }) {
  const { searchValue, setSearchValue, handleBuscar } = useSearch();
  const navigate = useNavigate();
  const handleEnter = async (e) => {
    if (e.key === "Enter") {
      await handleBuscar(e);
      navigate("/busqueda");
    }
  };

  return (
    <GlobalNavSearchWidgetContainer open={open}>
      <MobileCloseContainer onClick={onClick}>
        <MobileCloseInner />
      </MobileCloseContainer>
      <SearchWidgetLogoContainer>
        <LogoAlpineTagline fill="white" />
      </SearchWidgetLogoContainer>

      <SearchWidgetContainer>
        <SearchWidgetSearch open>
          <SearchWidgitSearchWrapper>
            <SearchWidgitIcon open onClick={handleBuscar}>
              <FontAwesomeIcon icon={faSearch} />
            </SearchWidgitIcon>
            <SearchWidgetInput
              type="text"
              value={searchValue}
              placeholder="Buscar en alpine-arg.com"
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyDown={handleEnter}
            />
          </SearchWidgitSearchWrapper>
        </SearchWidgetSearch>
        <SearchQuickLinks />
      </SearchWidgetContainer>
    </GlobalNavSearchWidgetContainer>
  );
}
