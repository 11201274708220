import styled, { css } from "styled-components";
import { theme } from "../styles/theme";
import ProductTabs from "../components/category/ProductTabs";
import Header from "../components/header/Header";
import SectionHeader from "../components/SectionHeader";
import ProductNavDropdown from "../components/category/ProductNavDropdown";
import { BrowserRouter as Router, useParams } from "react-router-dom";
import { ProducService } from "../service/ProductService";
import React from "react";
import GlobalParallaxBanner from "../components/GlobalParallaxBanner";
import BannerParalaxImg from "../images/lifestyle/e1825aca70e044abdf18c46e9760e080.jpg";
import useToggleHeaderOnScroll from "../hooks/useToggleHeaderOnScroll";

const ProductsContainer = styled.div``;

const Banner = styled.div`
  height: 560px !important;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export default function Category() {
  const { toggleHeader } = useToggleHeaderOnScroll();

  const service = new ProducService();
  const { category } = useParams();

  const [dataByCategory, setDataByCategory] = React.useState([]);

  const [categories, setCategories] = React.useState(null);
  const getCategories = async () => {
    const _categories = await service.getCategory();
    setCategories(_categories);
  };

  React.useEffect(async () => {
    const list = await service.getDataByCategory(category);
    setDataByCategory(list);
    getCategories();
  }, []);

  React.useEffect(async () => {
    const list = await service.getDataByCategory(category);
    setDataByCategory(list);
    getCategories();
  }, [category]);

  return (
    <>
      <Header sectionIn={toggleHeader} />
      <SectionHeader title={category} />
      {dataByCategory.length > 0 && (
        <>
          <ProductNavDropdown category={category} categories={categories} />
          <ProductsContainer>
            <ProductTabs data={dataByCategory} />
          </ProductsContainer>
        </>
      )}
      <GlobalParallaxBanner background={BannerParalaxImg} />
    </>
  );
}
