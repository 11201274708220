import Header from "../components/header/Header";
import { theme } from '../styles/theme';
import styled, {css} from 'styled-components';
import ButtonArrow from '../components/ButtonArrow';


const ContainerTerm = styled.div`
    margin: 0 0 40px;
    h2 {
        font-size: 20px !important;
        line-height: 1.5;
        margin-bottom: 10px !important;
    }
`



export default function TermsOfUse () {
    return (
        <>
            <Header sectionIn />
            <div className="section-wrapper">
                <div style={{padding: '30px 0 50px'}}></div>
                <h1 style={{textAlign:'center'}}>
                    Condiciones de Uso
                </h1>
                <div style={{maxWidth: '800px', margin: '0 auto'}}>
                    <ContainerTerm>    
                        <h2>CONDICIONES DEL SITIO WEB (VIGENCIA 1º DE ABRIL DE 2017)</h2>
                        <p>Este sitio web (“Sitio”) y todas sus páginas (“Páginas del Sitio”) son producidos por Alpine Electronics of America, Inc., Sucursal Buenos Aires ("Alpine") y todos sus derechos son específicamente reservados. El uso de este Sitio está sujeto a las siguientes condiciones de uso (“Condiciones del Sitio”) y Alpine podrá a su exclusiva discreción cambiar, alterar, modificar, adicionar y denegar accesos, sobre los contenidos de este Sitio, sus Páginas y Condiciones en cualquier momento con y sin notificación, por lo que deben chequearse las Condiciones del Sitio previamente a cada visita o uso.</p>
                    </ContainerTerm>
                    <ContainerTerm>    
                        <h2>Precios</h2>
                        <p>Los precios exhibidos en el Sitio están basados en los Precios Sugeridos al Público, y pueden diferir de aquellos promocionados por nuestras tiendas autorizadas. Si bien dedicamos todos nuestros esfuerzos en proveer información precisa, pueden producirse errores en forma ocasional e involuntaria. Los precios y disponibilidades están sujetos a cambios sin previo aviso.</p>
                    </ContainerTerm>
                    <ContainerTerm>    
                        <h2>CONTENIDOS DESCARGABLES, INCLUYENDO SOFTWARE</h2>
                        <p>Todos los softwares, mejoras, actualizaciones, utilidades y otros archivos de información (“Contenido Descargable”) que están disponibles para su descarga desde el sitio de Alpine USA contienen materiales sujetos a derechos de propiedad e informaciones confidenciales protegidos por leyes de propiedad intelectual y otras de aplicación. Al descargar un Contenido Descargable a su computadora y/o transfiera dicho material a su producto Alpine, Usted acuerda en que no copiará, modificará, decompilará, hará ingeniería inversa o cualquier otro intento para descubrir los códigos fuente del Contenido Descargable. Usted acuerda también en no vender, distribuir o reproducir los Contenidos Descargables. En ningún caso Alpine será responsable de modo alguno por cualquier daño que pudiese sufrir o incurrir Usted u otra tercera parte (incluyendo pero no limitado a pérdidas generales, especiales, directas, indirectas, consecuenciales, incidentales y otras) como consecuencia de o en relación con la descarga y uso de los Contenidos Descargables, y acuerda en indemnizar y mantener libre de culpa a Alpine por cualquier pérdida o daño emergente de cualquier descarga o uso de los Contenidos Descargables.</p>
                    </ContainerTerm>
                    <ContainerTerm>    
                        <h2>USO RESTRINGIDO Y DERECHOS DE AUTOR</h2>
                        <p>Las informaciones, fotos, imágenes, íconos de especificaciones, gráficos, links, códigos, formatos de diseño incluyendo layouts y estructuras de datos, y cualquier otro contenido del Sitio, están protegidos en la Argentina y otros países por una variedad de leyes, incluyendo pero no limitado a derechos de autor, provisiones de tratados internacionales, marcas registradas y leyes de derechos de propiedad. El uso de los textos, audios, imágenes o fotos, la reproducción, transferencia, distribución o almacenaje de parte o todo el contenido del Sitio en cualquiera de sus formas, están estrictamente prohibidos sin el consentimiento previo y por escrito de Alpine, salvo las excepciones contempladas en las Condiciones del Sitio. Las imágenes y fotos de los productos de Alpine, pueden ser utilizados por nuestras tiendas autorizadas con el permiso previo y por escrito de Alpine. Alpine consiente la navegación del Sitio, el copiado electrónico y/o en papel de las páginas del Sitio solamente para uso personal y no comercial, incluyendo pero no limitado al desarrollo de sistemas de productos e instalaciones, y cada copia deberá exhibir las notas de derecho de autor o marca registrada que correspondieren.</p>
                        
                        <p>Este Sitio y cualquier información o contenido en este Sitio, son provistos “como son” y “disponibles” con sus fallas eventuales. Alpine y sus empresas afiliadas, no representan ni proveen de garantías de ningún tipo, tanto expresas o implícitas, con respecto a este Sitio, sus contenidos e informaciones disponibles en o a través del mismo. Exceptuando las provisiones previstas bajo leyes aplicables, tanto Alpine como sus afiliados, sus directores gerentes, empleados, agentes, contratistas, sucesores o asignados, no serán responsables por ningún daño que pudiera surgir de o en relación al uso del Sitio y/o cualquier otro sitio relacionado. Esta limitación de responsabilidades se extiende a todo daño directo, indirecto, consecuencial, especial, punitivo u otros que pudieran sufrir Usted u otra tercera parte, como también a los daños o pérdidas de ganancia, interrupción de negocios o pérdida de datos e informaciones, incluyendo cualquier daño por virus que pudieran infectar su computadora u otra propiedad a cuenta de o debido al uso o acceso a este Sitio, aún si Alpine fuera previamente notificada del potencial de tales daños.</p>
                    </ContainerTerm>
                    <ContainerTerm>    
                        <h2>SITIOS LINKEADOS</h2>
                        <p>Alpine y sus empresas afiliadas no representan ni son responsables sobre ningún sitio que pudiera accederse a través de este Sitio. Tales sitios son independientes del Sitio y Alpine y sus empresas afiliadas no tienen control ni responsabilidad con respecto a las informaciones provistas o actividades promovidas por esto sitios. El acceso a estos sitios a través de este Sitio no implica que Alpine y sus empresas afiliadas aprueben los mismos o los productos y servicios ofrecidos. Las interacciones y comunicaciones con los sitios de tercera parte serán considerados como decisiones personales de los usuarios, totalmente independientes de Alpine.</p>
                        <p>En caso de accederse al sitio <a href="https://www.alpine-usa.com" target="_blank" rel="noreferer">www.alpine-usa.com</a>, se aplicarán las condiciones de uso y política de privacidad especificadas en el mismo.</p>
                        
                    </ContainerTerm>
                    <ContainerTerm>    
                        <h2>POLITICA DE INTERNET</h2>
                        <p>Se detallan a continuación los usos prohibidos de este Sitio</p>
                        <ol style={{listStyle: 'lower-alpha'}}>
                            <li>
                                Infringir los derechos legales de otros (incluyendo los derechos de privacidad y publicidad);
                            </li>
                            <li>
                                Causar cualquier daño o desventaja a otros;
                            </li>
                            <li>
                                Alterar el orden público;
                            </li>
                            <li>
                                Actos criminales;   
                            </li>
                                Subir o distribuir cualquier información privada de una tercera parte sin el consentimiento previo de la misma;
                            <li>
                                Usar este Sitio para cualquier propósito comercial;
                            </li>
                            <li>
                                Difamar, avergonzar o calumniar a otros;
                            </li>
                            <li>
                                Subir archivos que contengan virus, estén corrompidos y que puedan dañar el funcionamiento de otras computadoras;
                            </li>
                            <li>
                                Cualquier otra actividad ilegal o prohibida por las leyes aplicables; y
                            </li>
                            <li>
                                Cualquier otra actividad que Alpine considere inapropiada.
                            </li>
                        </ol>
                    </ContainerTerm>
                    <ContainerTerm>    
                        <h2>USUARIOS DE OTROS PAISES</h2>
                        <p>Este Sitio está destinado para su uso en la República Argentina. De no mediar otra consideración, los productos exhibidos son presentados únicamente con el propósito de promocionarlos y usarlos en el mencionado país. Alpine no garantiza que tales productos sean apropiados o estén disponibles para su uso en otros países. Aquellos que elijan acceder a este Sitio desde otros países, lo pueden hacer por su propia iniciativa y serán responsables por el cumplimiento de las leyes aplicables locales.</p>
                    </ContainerTerm>
                    <ContainerTerm>    
                        <h2>LEYES APLICABLES</h2>
                        <p>Para los residentes de la Argentina: Cualquier controversia, reclamo o disputa que surgiera por o en relación al uso del Sitio en cualquiera de sus formas, incluyendo reclamos o controversias sobre arbitrajes de los mismos, serán canalizados a través de los tribunales arbitrales de la Ciudad Autónoma de Buenos Aires, de acuerdo a las leyes arbitrales vigentes, y todas las partes involucradas acuerdan en someterse a esta instancia arbitral. Los eventuales juicios sobre las decisiones arbitrales podrán ser iniciados en cualquier corte de jurisdicción competente.</p>
                    </ContainerTerm>
                    <ContainerTerm>    
                        <h2>DERECHOS DE PROPIEDAD DE ALPINE</h2>
                        <p>Las palabras “Alpine” y Alpine Electronics of America, Inc., Sucursal Buenos Aires, el logo con las 5 franjas de excelencia, Mobile Multimedia Excellence™, Mobile Media Solutions™, Bass Engine™ y otros nombres de productos Alpine, logos, símbolos comerciales, nombres comerciales y slogans, son marcas y nombres registrados por Alpine Electronics, Inc., y/o Alpine Electronics of America, Inc. Se prohíbe el uso de todas las marcas que aparezcan en el Sitio, o hacer imitaciones de las mismas en todas sus formas o propósitos sin el consentimiento previo por escrito de Alpine. Otras marcas y logos mostrados en este Sitio pueden pertenecer a terceras partes no afiliadas a Alpine y/o sus compañías relacionadas. Tales marcas aparecen únicamente por propósitos identificatorios y son propiedad de sus respectivas compañías. Ningún permiso, licencia o derecho de uso de las marcas registradas, marcas de servicio o nombres registrados exhibidos en este Sitio podrán ser interpretados como otorgados implícitamente, y deberán contar con el consentimiento previo por escrito de Alpine o de la tercera parte que posea la marca registrada, la marca de servicio o nombre registrado implicados. Alpine prohíbe, sin mediar un consentimiento previo por escrito, del uso de cualquier logo o marca de Alpine como un hipervínculo a este Sitio u otro sitio de los afiliados de Alpine.</p>
                    </ContainerTerm>
                    <ContainerTerm>    
                        <h2>NOTIFICACIONES DE CAMBIOS SOBRE ESTA POLITICA</h2>
                        <p>Por favor visite este Sitio en forma periódica. La versión más actualizada de esta Política se exhibe con la fecha de entrada en vigencia. Alpine podrá revisar y actualizar esta Política anualmente.</p>
                    </ContainerTerm>
                </div>
            </div>
        </>
    );
}