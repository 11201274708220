import styled, {css} from 'styled-components';
import { theme } from '../../styles/theme';
import AndroidAuto from '../../images/product/icon-android-auto.png';
import AppleCarPlay from '../../images/product/icon-apple-car-play.png';

const ProductBuckets = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1170px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 108px;
    padding: 0 20px;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
        text-align: center;
    }
`

const ProductBucketsBucket = styled.div`
    width: 45%;
    display: inline-block;
    vertical-align: top;
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`

const ProductBucketsImage = styled.div`
    margin-bottom: 32px;
`

const ProductBucketsHeader = styled.div`
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 22px;
    line-height: 24px;
    letter-spacing: -.025px;
`

const ProductBucketsText = styled.p`
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 1em;
`


export default function ProductPhones({
    imageApple, 
    titleApple, 
    imageAndroid, 
    titleAndroid, 
    productName
}) {
    return (
        <ProductBuckets>
            <ProductBucketsBucket>
                <ProductBucketsImage>
                    <img 
                        style={{width:'100%', maxWidth:'100%'}} 
                        src={`/public/${imageApple}`}
                        alt={titleApple}
                    />
                </ProductBucketsImage>
                <div>
                    <img src={AppleCarPlay} alt="Apple Car Play Badge" style={{width: '150px'}} />
                </div>
                <ProductBucketsHeader>
                    <h3>
                        {titleApple}
                        {(productName == "iLX-507" || productName == "iLX-F509" || productName == "iLX-F511") && 
                            <span> (CON Y SIN CABLE)</span>
                        }
                    </h3>
                </ProductBucketsHeader>
                    {(productName == "iLX-F411" || productName == "iLX-407" || productName == "iLX-F409" || productName == "iLX-W650") &&
                        <ProductBucketsText>
                            El {productName} “Trabaja con Apple CarPlay” y permite a los usuarios de iPhone hacer y recibir llamadas, acceder a los mensajes de texto, reproducir música y recibir direcciones en una manera que les permite permanecer concentrados en la calle. Se accede al Apple CarPlay mediante la pantalla táctil capacitiva o mediante el botón de micrófono para activar el control de voz Siri, y es compatible con iPhone 5 y posteriores (el iPhone debe estar conectado mediante un cable lightning de Apple).
                        </ProductBucketsText>
                    }
                    {(productName == "INE-W970HD") && 
                        <ProductBucketsText>
                            El {productName} “Trabaja con Apple CarPlay” y permite a los usuarios de iPhone hacer y recibir llamadas, acceder a los mensajes de texto, reproducir música y recibir direcciones en una manera que les permite permanecer concentrados en la calle. Se accede al Apple CarPlay mediante la pantalla táctil WVGA, y es compatible con iPhone 5 y posteriores (el iPhone debe estar conectado mediante una entrada USB).
                        </ProductBucketsText>
                    }
                    {(productName == "INE-W987HD") && 
                        <ProductBucketsText>
                            El {productName} “Trabaja con Apple CarPlay” y permite a los usuarios de iPhone hacer y recibir llamadas, acceder a los mensajes de texto, reproducir música y recibir direcciones en una manera que les permite permanecer concentrados en la calle. Se accede al Apple CarPlay mediante la pantalla táctil capacitiva o mediante el botón de micrófono para activar el control de voz Siri, y es compatible con iPhone 5 y posteriores (el iPhone debe estar conectado mediante un cable lightning de Apple).
                        </ProductBucketsText>
                    }
                    {(productName == "iLX-507" || productName == "iLX-F509" || productName == "iLX-F511") && 
                        <ProductBucketsText>
                            El {productName} “Trabaja con Apple CarPlay” y permite a los usuarios de iPhone hacer y recibir llamadas, acceder a los mensajes de texto, reproducir música y recibir direcciones en una manera que les permite permanecer concentrados en la calle. Se accede al Apple CarPlay mediante la pantalla táctil capacitiva o mediante el botón de micrófono para activar el control de voz Siri, y es compatible con iPhone 5 y posteriores.
                        </ProductBucketsText>
                    }
            </ProductBucketsBucket>
            <ProductBucketsBucket>
                <ProductBucketsImage>
                    <img 
                        style={{width:'100%', maxWidth:'100%'}} 
                        src={`/public/${imageAndroid}`}
                        alt={titleAndroid} 
                    />
                </ProductBucketsImage>
                <div>
                    <img src={AndroidAuto} alt="Android Auto Badge" style={{width: '150px'}} />
                </div>
                <ProductBucketsHeader>
                    <h3>
                        {titleAndroid}
                        {(productName == "iLX-507" || productName == "iLX-F509" || productName == "iLX-F511") && 
                            <span> (CON Y SIN CABLE)</span>
                        }           
                    </h3>
                </ProductBucketsHeader>
                    {(productName == "iLX-F411" || productName == "iLX-407" || productName == "iLX-F409" || productName == "iLX-W650") &&
                        <ProductBucketsText>
                            El {productName} incluye Android Auto y trabaja con el Asistente de Google (requiere tanto un cable USB como conexión Bluetooth; requiere un teléfono Android con [Lollipop] 5.0 o más adelante en funcionamiento y la aplicación de Android Auto). Para acceder al Asistente de Google en Android Auto, di “OK Google” o selecciona el botón de micrófono. El Asistente de Google en Android Auto permite a los usuarios reproducir su música favorita, recibir direcciones para llegar a su destino, interactuar con los dispositivos de hogar inteligentes y responder a sus mensajes de texto - sin usar sus manos.
                        </ProductBucketsText>
                    }
                    {(productName == "INE-W970HD" || productName == "INE-W987HD") && 
                        <ProductBucketsText>
                            El {productName} incluye Android Auto y trabaja con el Asistente de Google (requiere tanto un cable USB como conexión Bluetooth; requiere un teléfono Android con [Lollipop] 5.0 o más adelante en funcionamiento y la aplicación de Android Auto). El Asistente de Google en Android Auto permite a los usuarios reproducir su música favorita, recibir direcciones para llegar a su destino, interactuar con los dispositivos de hogar inteligentes y responder a sus mensajes de texto - sin usar sus manos.
                        </ProductBucketsText>
                    }
                    {(productName == "iLX-507" || productName == "iLX-F509" || productName == "iLX-F511") && 
                        <ProductBucketsText>
                            El {productName} incluye Android Auto y trabaja con el Asistente de Google (requiere tanto un cable USB como conexión Bluetooth; requiere un teléfono Android con [Lollipop] 5.0 o más adelante en funcionamiento y la aplicación de Android Auto). Para acceder al Asistente de Google en Android Auto, di “OK Google” o selecciona el botón de micrófono. El Asistente de Google en Android Auto permite a los usuarios reproducir su música favorita, recibir direcciones para llegar a su destino, interactuar con los dispositivos de hogar inteligentes y responder a sus mensajes de texto – sin usar sus manos.
                        </ProductBucketsText>
                    }
            </ProductBucketsBucket>
        </ProductBuckets>
    );
}