import Header from "../components/header/Header";
import { theme } from '../styles/theme';
import styled, {css} from 'styled-components';
import ButtonArrow from '../components/ButtonArrow';


const ButtonKnowledge = styled.a`
    font-weight: 900;
    font-size: 14px;
    display: inline-block;
    margin-top: 20px;
    svg {
        margin-right: 5px;
    }
`



export default function Support () {
    return (
        <>
            <Header sectionIn />
            <div className="section-wrapper">
                <div style={{padding: '30px 0 50px'}}></div>
                <h1 style={{textAlign:'center'}}>
                    Soporte
                </h1>
                <div>
                    <h4>Contactos</h4>
                    <h5>SERVICIO AL CONSUMIDOR</h5>
                    <p>
                        ¿Tienes preguntas? Prueba el Centro de Conocimientos de Alpine <br />
                        (serás redirigido a la página en inglés)
                        <br />
                        <ButtonKnowledge 
                            href="https://kb.alpine-usa.com/" 
                            rel="noreferer" 
                            target="_blank"
                        >
                            <svg 
                                width="10px"
                                height="10px"
                                fill={theme.palette.main500}
                                xmlns="http://www.w3.org/2000/svg" 
                                viewBox="0 0 36 36" 
                                aria-labelledby="title">
                                    <polygon points="35.993,0 32,0 23.993,0 23.993,4 29.172,4 12.595,20.577 15.423,23.405 32,6.829 32,12 36,12 36,0   "></polygon><polygon points="31.993,16 27.993,16 27.993,31.986 4,31.986 4,7.993 19.993,7.993 19.993,4 0.007,3.993 0.007,4 0,4 0,31.986 0,35.986 4,35.986 27.993,35.986 27.993,35.993 31.993,35.993 31.993,35.986 32,35.986 32,31.986 31.993,31.986"></polygon>
                            </svg>
                            KNOWLEDGE CENTER
                        </ButtonKnowledge>
                    </p>

                    <div style={{marginTop: '40px'}}>
                        <h4>Búsqueda de manuales de usuario</h4>
                        <h5>BÚSQUEDA DE MANUALES DE USUARIO</h5>
                        <p>Serás redirigido a la página oficial de soporte de Alpine USA, en donde encontrarás un buscador de los Manuales de Usuario (Owner's Manual Search) para ingresar el número modelo a buscar</p>
                        <ButtonArrow 
                            link='https://www.alpine-usa.com/support/owners-manual'
                            btnText='MANUALES DE USUARIO' 
                            target='_blank'
                            rel='noreferer'
                        />
                    </div>
                
                </div>
            </div>
        </>
    );
}