export default function CommitmentFlagship () {
    return (
    <div className="o-section__commitment">
        <div className="p-index__commit_wrap">
          <div className="o-box__fade_item js-fade_item is-fade_active">
            <div className="p-index__commit_box">

              <h2 className="p-index__commit_title">
                <div className="p-index__commit_title_thumb is-l_us">
                  <picture>
                    <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/commitment_title_sp.png')} />
                      <img src={require('../../images/alpineflagship/commitment_title_pc.png')} alt="Unending Pursuit of Premium Sound Quality is what makes AlpineF#1Status special" />
                  </picture>
                </div>
              </h2>
  
              <div className="o-text_box__base is-commit_text">
                <p>El dispositivo clave para mejorar la performance y la calidad de sonido de un procesador de audio es un amplificador operacional. En la búsqueda inflexible del sonido del Alpine F#1 Status, nosotros seleccionamos MUSES05, el cual se enorgullece de la mejor performance de la industria, y nuestro maestro del sonido ha producido una calidad de sonido supremo.
Supervisamos atentamente el cambio en la calidad de sonido causado por variaciones mínimas de la corriente en el circuito interno, y la influencia de las ondas armónicas en el ancho de banda de audio de varios MHz. Luego, mejoramos la pureza del procesamiento de señales modernizando el estándar de la industria de dos circuitos por chip a un solo circuito por chip.
Esto permite un completo aislamiento de las etapas internas del amplificador, eliminando la interferencia entre las etapas.
<span className="is-sup">*2</span></p>
              <p>*2: MUSES es una marca registrada de New Japan Radio Co., Ltd.</p>
              </div>

              <div className="p-index__item_thumb1">
                <picture>
                  <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/item_thumb1_sp.png')} />
                    <img src={require('../../images/alpineflagship/item_thumb1_pc.png')} alt="" />
                </picture>
              </div>
  
              <a href="/alpineflagship/learn_more/#lm7" className="p-index__learn_more7 is-l_us">
                <picture>
                  <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/learn_more7_sp.png')} />
                    <img src={require('../../images/alpineflagship/learn_more7_pc.png')} alt="Learn more: What is special about the operation amplifier" />
                </picture>
              </a>
  
            </div>
          </div>
        </div>
    </div>
    )
} 
