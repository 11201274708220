
// Home 

export const dataCarouselHome = 
[
    {
        "image": "/public/assets/main_slider_home/Carrusel-AD-SPK1.jpg", 
        "title": "Alpine Turn1", 
        "description":"¡Sonido Alpine para todas tus aventuras!",
        "buttontext": "Ver AD-SPK1",
        "buttonlink": "/producto/AD-SPK1",
    }, 
    {
        "image": "/public/assets/main_slider_home/Carrusel-iLX-507.jpg", 
        "title": "iLX-507", 
        "description":"El nuevo receptor multimedia 2-DIN de 7 pulgadas con pantalla HD y reproducción de audio Hi-Res",
        "buttontext": "Ver iLX-507",
        "buttonlink": "/producto/iLX-507",
    },
    {
        "image": "/public/assets/main_slider_home/Carrusel-receptores.jpg", 
        "title": "Halo de la próxima generación", 
        "description":"¡Lleva tu tablero a nuevas alturas con las pantallas Alpine Halo de la próxima generación!",
        "buttontext": "Ver Receptores",
        "buttonlink": "/productos/Receptores",
    },
    {
        "image": "/public/assets/main_slider_home/Carrusel-Serie-X.jpg", 
        "title": "Serie X", 
        "description":"Experimenta la música como lo pensó originalmente el artista con los productos de la Serie X",
        "buttontext": "Serie X",
        "buttonlink": "/productos/Altavoces/Serie-X",
    },
];



