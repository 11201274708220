export default function LearnMoreBox () {
    return (
        <div class="l-main__learn_more_box">
          <a name="lm1" class="anchor"></a>
          <h2 id="lm1" class="o-title__bk_band is-l_us1">
            <picture>
              <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/lm/learn_more_title1_sp.png')} />
                <img src={require('../../images/alpineflagship/lm/learn_more_title1_pc.png')} alt="" />
            </picture>
          </h2>
  
          <div class="o-text_box__base bold">
            <p>Las fuentes de audio de alta resolución pueden hacer cosas que los CD tradicionales y las fuentes de audio comprimido no pueden:
            <span class="is-indent_half">• Capturar rangos de frecuencia ultra altos, inaudibles al oído humano. <br class="display_mq_l__inline" /></span>
            <span class="is-indent_half">• Reproducir sin problemas los sonidos incluso a diferentes niveles de volumen.</span></p>
          </div>
  
          <div class="o-text_box__base is-font_type1">
            <p>(Fig.1) El eje vertical representa el número de bits, indicando qué tan fiel se puede grabar un sonido. Con el CD, es posible grabar sonido a 16 bits. Con alta resolución, es posible grabar sonido en el rango de 24 a 32 bits.</p>
            <p>El eje horizontal representa la frecuencia de muestreo. Cuando la frecuencia de muestreo es alta, el sonido es grabado en intervalos de tiempo más cortos, haciendo posible grabar datos más cercanos a la forma de la onda sonora original. Compara la frecuencia de muestreo más alta del CD de 44,1 kHz, con la frecuencia de muestreo de alta resolución, que ahora se ha extendido a 384 kHz.</p>
            <p>Este avance ha hecho posible convertir las ondas analógicas suaves (Fig. 2) en datos digitales más precisos (Fig. 3). Por consiguiente, es posible grabar y reproducir archivos de datos digitales de música que contienen una mayor cantidad de información de la onda analógica original.</p>
            <p>Examinemos la reproducción de rangos de frecuencia ultra altos, usando el violín como un ejemplo. (Fig. 4) La medición del sonido del violín revela que tiene una forma de onda como esta.</p>
          </div>
        </div>
    )
}