import styled, {css} from 'styled-components';
import { theme } from '../styles/theme';
import ProductSpecBuckets from './product/ProductSpecBuckets';
import { IconArrow } from '../svg/IconArrow';

const Breadcrumb = styled.ul`
    margin-top: 138px;
    padding-bottom: 20px;
    max-width: 1170px;
    margin: 0 auto;

    @media only screen and (max-width: 500px) {
        padding-left: 34px;
        max-width: 100%;
    }

    @media only screen and (max-width: 768px) {
        margin-top: 66px;
        margin-bottom: -108px;
    }

    @media only screen and (max-width: 1005px) {
        padding-left: 14px;
        max-width: 80%;
    }

    @media only screen and (max-width: 1170px) {
        padding-left: 15px;
    }

    li {
        display: inline-block;
        font-size: 14px;
        color: ${theme.palette.main500} !important;
        a {
            color: ${theme.palette.main500};
        }
        &:first-child, &:first-child a {
            color: ${theme.palette.grey800} !important;
        }
    }

`




export default function ProductHero({children}) {
    return (
        <Breadcrumb>
            <li>
                <a href="#">Productos <IconArrow /></a>
            </li>
            {children}
        </Breadcrumb>
    );
}