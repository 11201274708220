import styled, {css} from 'styled-components';
import { theme } from '../../styles/theme';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from 'react';

const SlideIn = styled.div`
    img {
        object-fit: contain;
        width: 100%;
        height: 100%; 
    }
`
const Content = styled.div`
    width: 100%;
    height: 343px;
    padding: 0 30px;
`

const DotsUl = styled.ul`
    /* bottom: 120px; */
    li {
        margin: 0 10px;
        button {
            width: 26px;
            height: 4px;
            padding: 0;
            background-color: ${theme.palette.grey400};
            &:before {
                content: "";
            }
        }
    }
    li.slick-active {
        button {
            background-color: ${theme.palette.main500};
        }
    }
`

const ArrowWrapper = styled.div`
    z-index: 1;
    &:before{
        content: "";
    }
` 

const Arrow = ({rotate}) => {
    return (
        <svg 
            className="icon icon-arrow" 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 36 36" 
            aria-labelledby="title" 
            fill={theme.palette.main500}
            style={{
                transform: `rotate(${rotate})`,
                width: '12px',
            }}
        >
            <title>Arrow</title>
            <path d="M0.098,1.369l17.05,34.107c0.235,0.468,0.803,0.658,1.271,0.424 c0.184-0.091,0.334-0.24,0.425-0.424l17.05-34.107c0.233-0.469,0.041-1.036-0.429-1.269c-0.267-0.134-0.583-0.132-0.85,0.003 L18.47,8.412L1.359,0.095C0.887-0.133,0.321,0.065,0.095,0.536C-0.032,0.8-0.031,1.108,0.098,1.369z"></path>
        </svg>
    )    
}


export default function ProductSlider({ slides }) {
    const settings = {
        autoplay: false,
        autoplaySpeed: 3000,
        dots: true,
        appendDots: dots => <DotsUl>{dots}</DotsUl>,
        accessibility: true,
        arrows: true,
        prevArrow: <ArrowWrapper><Arrow rotate="90deg" /></ArrowWrapper>, 
        nextArrow: <ArrowWrapper><Arrow rotate="-90deg" /></ArrowWrapper>,
        infinite: true,
        speed: 350,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: afterChangeAction,
        beforeChange: beforeChangeAction,
    };
    
    
    function beforeChangeAction() {
        console.log('before')
    }
    
    function afterChangeAction() {
        console.log('after')
    }

    return (
        <Slider {...settings}>
            {slides.map(el => 
                <div key={el}>
                    <SlideIn>
                        <Content>
                            <img src={`/public/${el}`} alt="" className='' />
                        </Content>
                    </SlideIn>
                </div>
            )}
        </Slider>
    )
}


