import styled from 'styled-components';
import LogoAlpine from '../../svg/logo-alpine.js';
import FooterSocial from './FooterSocial.js';
import FooterBottom from './FooterBottom.js';
import FooterContent from './FooterContent.js';
import { theme } from '../../styles/theme.js';

const GlobalFooter = styled.div `
    max-width: 1170px;
    margin: 100px auto 40px;
    @media only screen and (max-width: 1210px) {
        margin-left: 20px;
        margin-right: 20px;  
    }
`
const GlobalFooterLogo = styled.div `
    width: 100%;
    text-align: center;
`
const GlobalFooterLine = styled.div`
    margin-top: 40px;
    padding-top: 2px;
    background-color: #e2e2de;
`
const GlobalFooterHeader = styled.div`
    line-height: 1.25rem;
    letter-spacing: .04rem;
    font-family: Nunito Sans,sans-serif;
    font-weight: 900;
    font-style: normal;
    text-transform: uppercase; 
`

export default function Footer() {
    return (
        <GlobalFooter> 
            <GlobalFooterLogo>
                <LogoAlpine width="160px"/>
            </GlobalFooterLogo>
            <GlobalFooterLine />
            <FooterContent />
            <FooterSocial 
                socialColor={theme.palette.grey800} 
                socialColorOver={theme.palette.main500}
            />
            <FooterBottom />
        </GlobalFooter>
    );
}