import styled, {css} from 'styled-components';
import {Link} from 'react-router-dom';

const GlobalNavLink = styled.li`
    display: block;
    margin-right: 0;
    position: relative;
    z-index: 910;
    
    &:before {
        content: "";
        background-color: #e2e2de;
        bottom: 0;
        left: 50%;
        position: absolute;
        transform: translate3d(-50%,1px,0);
        transition: transform .6s cubic-bezier(.19,1,.22,1) .2s,height .6s cubic-bezier(.19,1,.22,1),width .6s cubic-bezier(.19,1,.22,1),background-color .6s cubic-bezier(.19,1,.22,1) .4s;
        z-index: 1;
        width: 0;
        height: 1px;
    }
    &:hover {
        &:before {
            background-color: ${props => props.underlineColor ? props.underlineColor : 'white'}; 
            transform: translate3d(-50%,2px,0);
            transition: transform .5s cubic-bezier(.19,1,.22,1) .3s,height .5s cubic-bezier(.19,1,.22,1) .3s,width .5s cubic-bezier(.19,1,.22,1);
            width: 100%;
            height: 4px;
        }
    }
    
    &:last-child {
        margin-right: 0;
    }
    @media only screen and (min-width: 1005px) {
        display: ${props => props.search ? 'inline-flex' : 'inline-block'};
        margin-right: 10px;
    }
    a {
        color: ${props => props.textColor ? props.textColor : 'white'}; 
        font-weight: 900;
        font-style: normal;
        font-size: 20px;
        line-height: 12px;
        letter-spacing: .02px;
        text-transform: uppercase;
        padding-top: 31px;
        padding-bottom: 33px;
        display: flex;
        justify-content: center;
        height: 100%;
        transition: .4s cubic-bezier(.19,1,.22,1);

        @media only screen and (min-width: 1005px) {
            font-size: 14px;
        }

    }
`

export default function MainMenuNavLink ({
    link, 
    buttonText, 
    search, 
    onMouseEnter, 
    onMouseLeave,
    textColor,
    underlineColor,
    className
}) {
    return (
        <GlobalNavLink 
            search={search}
            onMouseEnter={onMouseEnter} 
            onMouseLeave={onMouseLeave}
            textColor={textColor}
            underlineColor={underlineColor}
            className={className}
        >
            <Link to={link}>{buttonText}</Link>
        </GlobalNavLink>
    )
}