import styled, {css} from 'styled-components';
import { theme } from '../styles/theme';


const GlobalCenterImage = styled.div`
    margin-left: 25%;
    width: 50%;
    margin-bottom: -10px;
    display: inline-block;
    overflow: hidden;
    @media screen and (max-width: 768px) {
        margin-left: 0;
        width: 100%;
    }
`

const Img = styled.img`
    width: 100%;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
`

const ContentContainer = styled.div`
    width: 50%;
    display: inline-block;
    vertical-align: top;
    padding: 44px 48px;
    margin-left: ${props => props.marginLeft};
    @media screen and (max-width: 768px) {
        width: 100%;
        margin-left: 0;
    }
`

const ImageContainer = styled.div`
    width: 25%;
    vertical-align: top;
    margin-top: -6px;
    padding-right: ${props => props.paddingRight};
    display: inline-block;
    overflow: hidden;
    @media screen and (max-width: 768px) {
        display: none;
        padding-right: 0;
    }
`


export default function CenterImage(props) {
    return (
        <div style={{paddingBottom: '80px'}}>
            <GlobalCenterImage>
                <Img src={props.srcTopImage} />
            </GlobalCenterImage>
            <div>
                {props.imageOnLeft &&
                    <ImageContainer paddingRight='0'>
                        <Img src={props.srcSideImage} />
                    </ImageContainer>
                }
                <ContentContainer marginLeft={props.imageOnRight ? '25%' : '0'}>
                    {props.children}
                </ContentContainer>
                {props.imageOnRight &&
                    <ImageContainer paddingRight='45px'>
                        <Img src={props.srcSideImage} />
                    </ImageContainer>
                }
            </div>
        </div>
    )
}