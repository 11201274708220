import api from "./api";

const base = "/product";
export class ProducService {
  getCategory() {
    return api.get(`${base}/category`).then((res) => res.data.data);
  }

  getDataByCategory(cat) {
    return api
      .get(`${base}/category?category=${cat}`)
      .then((res) => res.data.data);
  }

  getProductByProductCode(code) {
    return api
      .get(`${base}?product_code=${code}`)
      .then((res) => res.data.data[0]);
  }
}
