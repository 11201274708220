import styled, {css} from 'styled-components';
import { theme } from '../../styles/theme';
import LogoAlpineTagline from '../../svg/logo-alpine-tagline';
import FooterNesletter from '../footer/FooterNewsletter';
import FooterSocial from '../footer/FooterSocial';
import MainMenu from './MainMenu';

const GlobalNavMobileBkg = styled.div`
    width: 100%;
    background-color: ${theme.palette.main500};
    min-height: 100%;
    height: 100%;
    position: fixed;
    display: initial;
    padding: 43px 1em;
    left: -100%;
    transition: transform .3s;
    z-index: 1000;
    overflow-y: scroll;
    ${props => props.active && css`
        transform: translateX(100%);
    `}
`

const Logo = styled.div`
    width: 116px;
    left: 50%;
    position: absolute;
    transform: translate(-50px);
    top: 9px;
`

const MobileCloseContainer = styled.div`
    content: "";
    width: 25px;
    height: 25px;
    position: absolute;
    top: 0;
    right: 0;
    margin: 13px 16px 10px;
    cursor: pointer;
`

const MobileCloseInner = styled.div`
    background-color: white;
    width: 25px;
    height: 2px;
    content: "";
    border-radius: 25%;
    transform: rotate(-45deg);
    &:after {
        display: block;
        background-color: white;
        content: "";
        height: 2px;
        width: 25px;
        border-radius: 25%;
        transform: rotate( 90deg);
        margin-top: 8px;
    };
`

export default function SidebarMenuMobile ({onClick, active}) {

    return (
        <GlobalNavMobileBkg active={active}>
            <MobileCloseContainer onClick={onClick}>
                <MobileCloseInner />
            </MobileCloseContainer>
            <a href="/">
                <Logo>
                    <LogoAlpineTagline fill="white" />
                </Logo>
            </a>
            <MainMenu displayMobile='block' />
            <FooterNesletter 
                textColor="white" 
                placeholderColor="white" 
                arrowColor="white" 
            />
            <FooterSocial 
                socialColor='white' 
                socialColorOver={theme.palette.grey300} 
            />
        </GlobalNavMobileBkg>
    );
}
