import { Link } from 'react-router-dom';
import styled, {css} from 'styled-components';
import { theme } from '../../styles/theme';

const ProductsCategoryCategoryContainer = styled.div`
    display: inline-block;
    width: 100%;
    vertical-align: top;
    @media only screen and (min-width: 768px) {
        width: 50%;
    }
    @media only screen and (min-width: 1024px) {
        width: 33.3333%;
    }
`

const ProductsCategoryCategory = styled.div`
    margin-left: 40px;
    margin-top: 25px;
    border: 1px solid transparent;
    position: relative;
    transition: 1.2s cubic-bezier(.23,1,.32,1);
    @media only screen and (min-width: 1024px) {
        margin-left: 80px;
        margin-top: 30px;
    }

    &:hover {
        border: 1px solid #d9d9d5;
        cursor: pointer;
    }
`

const ProductsCategoryImageContainer = styled.div`
    padding: 0;
    margin-bottom: 20px;
    @media only screen and (min-width: 768px) {
        padding: 40px 35px 15px;
        margin: 0;
    }
`

const ProductsCategoryImage = styled.div`
    padding-top: 62.5%;
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    transform: scale(.95);
    transition: .8s cubic-bezier(.23,1,.32,1);
    ${ProductsCategoryCategory}:hover & {
        transform: scale(1);
    }
`

const ProductsCategoryCopyContainer = styled.div`
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 0px;
    @media only screen and (min-width: 768px) {
        margin-bottom: 30px;
    }
`

const ProductsCategoryName = styled.p`
    color: ${theme.palette.grey800};
    white-space: normal;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -.025px;
    font-weight: 900 !important;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 0;
`

const ProductsCategoryCopy = styled.p`
    font-size: 18px;
    line-height: 28px;
    letter-spacing: .04px;
    font-weight: 300;
    font-style: normal;
    text-align: center;
    white-space: normal;
    color: #212121;
    width: 60%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 0;
    margin-bottom: 0;
`

export default function ProductCard({ product_code, image, product_name, description }) {
    return (

        <ProductsCategoryCategoryContainer>
            <Link to={`/producto/${product_code}`}>
                <ProductsCategoryCategory>
                    <ProductsCategoryImageContainer>
                        <ProductsCategoryImage 
                            style={{
                                backgroundImage: `url(${image})`,
                            }}
                        />
                    </ProductsCategoryImageContainer>
                    <ProductsCategoryCopyContainer>
                        <ProductsCategoryName>
                            {product_name}
                        </ProductsCategoryName>
                        <ProductsCategoryCopy>
                            {description}
                        </ProductsCategoryCopy>
                    </ProductsCategoryCopyContainer>
                </ProductsCategoryCategory>
            </Link>
        </ProductsCategoryCategoryContainer>

    );
}