import styled, { css } from "styled-components";
import React, { useState } from "react";
import SearchDesktop from "./SearchDesktop";
import MainMenuNavLink from "./MainMenuNavLink";
import "./header.css";
import MegaHeader from "./MegaHeader";
import { ProducService } from "../../service/ProductService";

const GlobalNavLinksContainer = styled.div`
  display: ${(props) => props.displayMobile};
  padding-top: 3em;
  padding-bottom: 4em;
  @media only screen and (min-width: 1005px) {
    display: block;
    padding: 0 1em;
  }
  @media only screen and (min-width: 1170px) {
    padding: 0;
  }
`;

const GlobalNavLinks = styled.ul`
  width: 100%;
  height: auto;
  display: inline-flex;
  justify-content: space-between;

  padding: 0 1em;
  display: block;
  ${(props) =>
    props.left &&
    css`
      padding-left: 0;
    `}
  ${(props) =>
    props.right &&
    css`
      padding-right: 0;
      text-align: center;
      float: none;
      @media only screen and (min-width: 1005px) {
        text-align: right;
        float: right;
      }
    `}
    @media only screen and (min-width: 1005px) {
    width: 40%;
    height: 75px;
    display: inline-flex;
  }
`;

export default function MainMenu({
  displayMobile,
  isDesktop,
  onClick,
  textColor,
  underlineColor,
  onClickMenuItem,
}) {
  const service = new ProducService();
  const [categories, setCategories] = React.useState(null);
  const showCategories = async () => {
    const _categories = await service.getCategory();
    setCategories(_categories);
  };

  React.useEffect(() => {
    showCategories();
  }, []);

  return (
    <GlobalNavLinksContainer displayMobile={displayMobile}>
      <GlobalNavLinks left>
        <div className="dropdown">
          <MainMenuNavLink
            link="#"
            buttonText="Productos"
            textColor={textColor}
            underlineColor={underlineColor}
          />
          <div className="dropdown-content">
            <div className="dropdown-content-wrapper">
              <MegaHeader
                category="AlpineF#1Status"
                categoryCustomLink="/alpineflagship"
              />

              {categories &&
                categories.map((el, i) => (
                  <div key={i}>
                    <MegaHeader
                      category={el.category}
                      subcategories={el.subcategory}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
        <MainMenuNavLink
          className="btn-productos-mobile"
          link="/productos/Amplificadores"
          buttonText="Productos"
          textColor={textColor}
          underlineColor={underlineColor}
          onClick={onClickMenuItem}
        />
        <MainMenuNavLink
          link="/productos/estilo de vida"
          buttonText="Estilo de vida"
          textColor={textColor}
          underlineColor={underlineColor}
          onClick={onClickMenuItem}
        />
        <MainMenuNavLink
          link="/aprobado-por-alpine"
          buttonText="Aprobado por Alpine"
          textColor={textColor}
          underlineColor={underlineColor}
          onClick={onClickMenuItem}
        />
      </GlobalNavLinks>
      <GlobalNavLinks right>
        <MainMenuNavLink
          link="/compania"
          buttonText="Compañía"
          textColor={textColor}
          underlineColor={underlineColor}
          onClick={onClickMenuItem}
        />
        <MainMenuNavLink
          link="/soporte"
          buttonText="Soporte"
          textColor={textColor}
          underlineColor={underlineColor}
          onClick={onClickMenuItem}
        />
        <MainMenuNavLink
          link="/buscador-de-tiendas"
          buttonText="Buscador de Tiendas"
          textColor={textColor}
          underlineColor={underlineColor}
          onClick={onClickMenuItem}
        />
        {isDesktop && <SearchDesktop onClick={onClick} color={textColor} />}
      </GlobalNavLinks>
    </GlobalNavLinksContainer>
  );
}
