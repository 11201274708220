import styled, {css} from 'styled-components';
import { theme } from '../../styles/theme';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";

const ProductsNavigationDropdown = styled.div`
    position: relative;
    z-index: 100;
    margin-top: 130px;
    display: block;
    margin-right: auto;
    margin-left: auto;
    max-width: 500px;
    width: 90%;
    @media only screen and (min-width: 1005px) {
        margin-top: 117px;
    }
    @media only screen and (min-width: 1170px) {
        display: none;
    }    
`

const SupportInputContainer = styled.div`
`

const DropdownOutline = styled.div`
    display: inline-block;
    width: calc(100% - 54px);
    margin-left: 27px;
    margin-right: 27px;
    position: relative;
    vertical-align: top;
`

const DropdownOutlineContainer = styled.div`
`

const DropdownOutlineButton = styled.div`
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
    font-weight: 700;
    color: ${theme.palette.main500};
    text-align: left;
    border: 1px solid ${theme.palette.grey400};
    background-color: transparent;
    border-radius: 0;
    padding: 18px 30px 20px;
    position: relative;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
`

const DropdownOutlineArrow = styled.div`
    display: inline-block;
    position: absolute;
    right: 17px;
    top: 27px;
    transition: transform .3s;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0;
`

const Icon = styled.svg`
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: ${theme.palette.main500};
`

const DropdownOutlineContent = styled.ul`
    background-color: #fff;
    box-shadow: 0 2px 10px #00000012;
    position: absolute;
    transition: max-height .3s ease-in-out;
    overflow: hidden;
    width: 100%;
    z-index: 1;

    max-height: ${props => props.height};
    overflow-y: ${props => props.overflow};
`
/* 
 max-height: 500px;
 overflow-y:  scroll;
*/


const DropdownOutlineItem = styled.li`
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
    padding: 18px 17px 20px 30px;
    border-bottom: 1px solid #d9d9d5;
    cursor: pointer;
    text-decoration: none;
`

export default function ProductNavDropdown({ category, categories }) {
    let navigate = useNavigate();
    const [showDropdownCategory, setShowDropdownCategory] = useState(true);
    const handleClickDropdown = () => {
        setShowDropdownCategory(!showDropdownCategory)
    }

    const redirectToCategory = (category) => {
        handleClickDropdown();
        navigate(`/productos/${category}`);
    }

    return (
        <ProductsNavigationDropdown>
            <SupportInputContainer>
                <DropdownOutlineContainer>
                    <DropdownOutlineButton onClick={handleClickDropdown} >
                        {category}
                        <DropdownOutlineArrow>
                            <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" aria-labelledby="title">
                                <path d="M0.098,1.369l17.05,34.107c0.235,0.468,0.803,0.658,1.271,0.424 c0.184-0.091,0.334-0.24,0.425-0.424l17.05-34.107c0.233-0.469,0.041-1.036-0.429-1.269c-0.267-0.134-0.583-0.132-0.85,0.003 L18.47,8.412L1.359,0.095C0.887-0.133,0.321,0.065,0.095,0.536C-0.032,0.8-0.031,1.108,0.098,1.369z"></path>
                            </Icon>
                        </DropdownOutlineArrow>
                    </DropdownOutlineButton>
                    <DropdownOutlineContent
                        height={!showDropdownCategory ? '500px' : '0px'}
                        overflow={!showDropdownCategory ? 'scroll' : 'hidden'}
                    >
                        {categories && categories.map((el, ind) => (
                            <DropdownOutlineItem
                                key={ind}
                                onClick={() => redirectToCategory(el.category)}
                            >
                                {el.category}
                            </DropdownOutlineItem>
                        ))}
                    </DropdownOutlineContent>
                </DropdownOutlineContainer>
            </SupportInputContainer>
        </ProductsNavigationDropdown>
    );
}