export const theme = {
    palette: {

       main200: "#B1D6F4",
       main300: "#6BA7D9",
       main400: "#3576AD",
       main500: "#00549a",
       main600: "#043E6F",
       main700: "#0C2942",
       main800: "#021423",

       grey200: "#FFFFFF",
       grey300: "#F4F4F5",
       grey400: "#D9D9D5",
       grey500: "#B7B7B7",
       grey600: "#7D7D7D",
       grey700: "#4A4A4A",
       grey800: "#212121",

    },

    font: {
        primaryfont: "'Nunito Sans', sans-serif",
    }
    
}