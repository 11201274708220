export default function PerfectSyncFlagship () {
    return (
        <div id="js-ps_line" className="o-section__ps">
            <div className="o-box__fade_item js-fade_item is-fade_active">
                <div className="p-index__ps_top_cnt">

                    <h2 className="p-index__ps_line">
                        <picture>
                            <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/ps_line_sp.png')} />
                            <img src={require('../../images/alpineflagship/ps_line_pc.png')} alt="Perfect Synchronization" />
                        </picture>
                        <span id="js-ps_line_target" className="p-index__ps_line_bg" style={{height: '3718.48px'}}></span>
                    </h2>
        
                    <div className="p-index__ps_title is-l_us">
                        <picture>
                            <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/ps_title_sp.png')} />
                            <img src={require('../../images/alpineflagship/ps_title_pc.png')} alt="Experience the soul of the music. The best high-resolution sound source makes the car interior the ultimate listening space." />
                        </picture>
                    </div>
        
                    <div className="o-text_box__sub_title">
                        <p>Tecnología de “Sincronización Perfecta”</p>
                    </div>
        
                    <div className="p-index__perfect_logo">
                        <picture>
                            <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/perfect_logo_sp.png')} />
                            <img src={require('../../images/alpineflagship/perfect_logo_pc.png')} alt="PERFECT SYNCHRONIZATION" />
                        </picture>
                    </div>
        
                    <div className="o-text_box__base is-ps_top">
                        <p>A diferencia de un sistema de audio doméstico, el sistema de audio vehicular necesita separar la unidad principal del procesador de audio debido a la limitación del espacio de instalación. Además, los altavoces también tienen limitada la ubicación de instalación, y no pueden ser instalados en posiciones ideales para la escucha. Este entorno crea dificultades acústicas como el reflejo y la absorción de sonido, el ruido de las calles, la vibración del vehículo y cambios de la temperatura que deben ser superados. Incluso en un sistema de audio doméstico, donde hay menos limitaciones en el espacio de instalación y las condiciones, es difícil ajustar un sistema de sonido de alta resolución para una reproducción de sonido exacta; esto es aun más difícil en un vehículo. El Alpine F#1 Status incorpora la tecnología de “Sincronización Perfecta” que resuelve estas dificultades de instalación para lograr una reproducción de sonido de alta resolución dentro del vehículo.</p>
                    </div>
        
                    <div className="p-index__ps_thumb1">
                        <picture>
                            <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/ps_thumb1_sp.png')} />
                            <img src={require('../../images/alpineflagship/ps_thumb1_pc.png')} alt="" />
                        </picture>
                    </div>

                    

                </div>
            </div>
            <ul className="p-index__ps_list">
                <li>
                    <div className="o-box__fade_item js-fade_item is-fade_active">
                        <h3 className="p-index__ps_list_title">
                            <picture>
                            <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/ps_num01_sp.png')} />
                                <img src={require('../../images/alpineflagship/ps_num01_pc.png')} alt="Perfect Synchronization" />
                            </picture>
                        </h3>
            
                        <div className="p-index__ps_list_sub is-l_us">
                            <p>El Sistema de Administración Master Clock <br /> sincroniza el pulso de la señal</p>
                        </div>
                                
                        <div className="o-box__ps_list">
                            <div className="o-text_box__ps_list">
                            <p>Tradicionalmente, cada dispositivo que manejaba una señal digital era procesado por un reloj independiente para controlar las señales y el pulso de cada uno de los componentes. Sin embargo, esto causaba una leve desalineación llamada “jitter (fluctuación)” al momento de la transmisión de datos. La existencia de esta leve desalineación de señales (distorsión por fluctuación) causaba que los detalles de la música se pierdan con el transcurso del tiempo. Para lograr la reproducción de alta resolución con las especificaciones más altas de 384 kHZ/32 bits, este problema debía ser tratado. Y para resolverlo, Alpine ha desarrollado el Sistema de Administración Master Clock. El Sistema de Administración Master Clock usa un único “reloj maestro” altamente preciso que sincroniza completamente las señales de audio entre la unidad principal y el procesador de audio, eliminando casi completamente las desincronizaciones durante la transmisión. El Alpine F#1 Status es el primer sistema de audio para automóvil en utilizar esta estructura.</p>
                            </div>
            
                            <div className="p-index__ps_list_thumb is-num01_thumb">
                                <picture>
                                    <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/ps_num01_thumb1_sp.png')} />
                                    <img src={require('../../images/alpineflagship/ps_num01_thumb1_pc.png')} alt="" />
                                </picture>
                            </div>
            
                            <a href="/alpineflagship/aprendemas/#lm3" className="p-index__learn_more3 is-l_us">
                                <picture>
                                    <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/learn_more3_sp.png')} />
                                    <img src={require('../../images/alpineflagship/learn_more3_pc.png')} alt="Learn more: About jitter distortion" />
                                </picture>
                            </a>
            
                        </div>
                    </div>

                    <div className="o-box__fade_item js-fade_item is-fade_active">
        
                        <h4 className="p-index__ps_list_sub is-l_us is-mt_type1">Oscilador de Cristal OCXO DuCULoN<span className="is-sup">®</span><span className="is-sup">*1</span></h4>
            
                        <div className="o-box__ps_list">
                            <div className="o-text_box__ps_list">
                            <p>Un oscilador de cristal llamado “OCXO DuCULoN®” se ubica en el centro del Sistema de Administración Master Clock del Alpine F#1 Status, para regular el reloj maestro para la reproducción de audio de alta resolución y elevar al mayor nivel de precisión posible. Sin embargo, los cristales de cuarzo son susceptibles a cambios de temperatura que impactan en la precisión del desempeño. Para solucionar este tema, el oscilador de cristal está insertado en una caja especializada con un mejorado aislamiento térmico y control de temperatura. Una vez que se enciende el sistema, la temperatura interna del cristal se ajusta hasta que se estabiliza a la temperatura necesaria para la precisión del reloj maestro. Una vez que se estabiliza, el indicador se enciende y la temperatura del cristal se mantiene constante. Además, insertar el cristal en una caja especializada reduce el ruido y las variaciones de temperatura. El resultado es un reloj estabilizado en una precisión 500 veces mejor que el de un cristal de cuarzo utilizado en los productos digitales estándar. Mediante el uso de un oscilador de cristal con la mayor precisión y control de temperatura de la industria, el Alpine F#1 Status minimiza la alteración del reloj maestro y el deterioro de la calidad de audio debido a la variación de temperatura.</p>
                            </div>
            
                            <div className="p-index__ps_list_thumb is-text_bottom">
                            <picture>
                                <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/ps_num01_thumb2_sp.png')} />
                                <img src={require('../../images/alpineflagship/ps_num01_thumb2_pc.png')} alt="" />
                            </picture>
                            </div>
            
                            <a href="/alpineflagship/aprendemas/#lm4" className="p-index__learn_more4 is-l_us">
                            <picture>
                                <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/learn_more4_sp.png')} />
                                <img src={require('../../images/alpineflagship/learn_more4_pc.png')} alt="Learn more: About crystal with highest accuracy" />
                            </picture>
                            </a>
            
                        </div>
                    </div>
                </li>
                <li>
                    <div className="o-box__fade_item js-fade_item is-fade_active">
                        <h3 className="p-index__ps_list_title is-num2">
                            <picture>
                            <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/ps_num02_sp.png')} />
                                <img src={require('../../images/alpineflagship/ps_num02_pc.png')} alt="Perfect Synchronization" />
                            </picture>
                        </h3>
            
                        <div className="p-index__ps_list_sub is-l_us">
                            <p>El sistema utiliza cuatro procesadores<br /> de señales digitales de alta gama, <br /> que optimizan el entorno sonoro del vehículo <br />sincronizando todos los altavoces del sistema</p>
                        </div>
            
                        <div className="o-box__ps_list">
                            <div className="o-text_box__ps_list">
                            <p>A pesar de que la precisión de la señal digital y el desempeño acústico son factores importantes para considerar, tener un sistema de altavoces ultra premium determinará la calidad final del sonido. Adicionalmente, es necesario realizar un ajuste altamente preciso para asegurar la calidad del sonido de alta resolución. En los sistemas de audio tradicionales para automóviles, la distancia del asiento del conductor a los altavoces derechos e izquierdos es asimétrica debido a las restricciones de los lugares de instalación de los altavoces. Por ende, es difícil posicionar los altavoces para un entorno sonoro óptimo comparado a un sistema de audio doméstico. Esto plantea un problema, ya que la discrepancia en la distancia entre los altavoces y el oyente hace que el sonido llegue a los oídos en diferentes momentos, causando distorsión, y en definitiva, un sonido que difiere de la intención original. Para lograr una sincronización precisa, proveer una experiencia estéreo tridimensional y un rango de frecuencia equilibrado que es fiel al sonido original, es necesario proveer un ajuste de sonido altamente preciso hecho a medida para el entorno dentro del vehículo. Por esta razón, el Alpine F#1 Status está equipado con cuatro unidades del mejor procesador de señales digitales (DSP) de la industria funcionando a 1GHz/64bit para lograr un ajuste de sonido preciso para la reproducción de audio de alta resolución de 384-kHz/32-bit. Esto también ha permitido al Alpine F#1 Status realizar ajustes de alineamiento de tiempo con una precisión de aproximadamente 0,9mm (a una temperatura ambiente de 20°C), mejorando del equivalente de 7,2mm (a 20°C), lo cual era la unidad mínima en los productos existentes.</p>
                            </div>

                            <div className="p-index__ps_list_thumb is-text_bottom">
                            <picture>
                                <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/ps_num02_thumb1_sp.png')} />
                                <img src={require('../../images/alpineflagship/ps_num02_thumb1_pc.png')} alt="" />
                            </picture>
                            </div>
            
                            <a href="/alpineflagship/aprendemas/#lm5" className="p-index__learn_more5 is-l_us">
                                <picture>
                                    <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/learn_more5_sp.png')} />
                                    <img src={require('../../images/alpineflagship/learn_more5_pc.png')} alt="Learn more: About arrival time compensation?" />
                                </picture>
                            </a>
            
                        </div>
                    </div>
                </li>
                <li>
                    <div className="o-box__fade_item js-fade_item is-fade_active">
                        <h3 className="p-index__ps_list_title is-num3">
                            <picture>
                            <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/ps_num03_sp.png')} />
                                <img src={require('../../images/alpineflagship/ps_num03_pc.png')} alt="Perfect Synchronization" />
                            </picture>
                        </h3>
            
                        <div className="p-index__ps_list_sub is-l_us">
                            <p>“El sistema de banda extendida de 4 vías <br /> para lograr la coherencia tonal” sincroniza <br /> los tonos en todos los anchos de banda</p>
                        </div>
            
                        <div className="o-box__ps_list">
                            <div className="o-text_box__ps_list">
                            <p>Los altavoces convencionales están hechos con materiales optimizados y una estructura adecuada para una reproducción específica, pero las características de los tonos difieren en cada altavoz. Para vencer esta cuestión, el Alpine F#1 Status intentó unificar los tonos de los altavoces igualando los materiales y sus estructuras. Luego de infinitas pruebas y errores, se eligieron Plásticos Reforzados con Fibra de Carbono (CFRP), el cual es un material ligero pero altamente rígido. El CFRP es entre cinco y seis veces más fuerte que el acero y entre tres y cuatro veces más fuerte que el aluminio con la misma masa. Es utilizado frecuentemente para las estructuras de los aviones y videocámaras profesionales. Es ligero y reduce la distorsión debido a su alta rigidez, haciendo el CFRP la mejor opción para reproducción de sonido de alta resolución.</p>
                            </div>
            
                            <div className="p-index__ps_list_thumb is-num03_thumb">
                                <picture>
                                    <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/ps_num03_thumb1_sp.png')} />
                                    <img src={require('../../images/alpineflagship/ps_num03_thumb1_pc.png')} alt="" />
                                </picture>
                            </div>
            
                            <a href="/alpineflagship/aprendemas/#lm6" className="p-index__learn_more6 is-l_us">
                                <picture>
                                    <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/learn_more6_sp.png')} />
                                    <img src={require('../../images/alpineflagship/learn_more6_pc.png')} alt="Learn more: Reason why CFRP has been employed" />
                                </picture>
                            </a>
            
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    )
}