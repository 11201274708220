import styled, {css} from 'styled-components';
import { theme } from '../styles/theme';

const ParallaxBanner = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 50vh;
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, 0);
    content: "";
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 36.45833%;
    max-height: 600px;
    z-index: 1;
`

const ParallaxBannerLayer = styled.div`
    background-position: center center;
    background-size: cover;
    position: absolute;
    inset: -15% 0px;
    background-image: url(${props => props.background});
    background-color: #f1f1f1;
`

const InterstitialTitle = styled.div`
    color: #fff;
    text-align: center;
    max-width: 750px;
    margin-right: auto;
    margin-left: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    position: absolute;
    font-size: 50px;
    line-height: 1.1;
    letter-spacing: -.025px;
    font-weight: 900;
    text-transform: uppercase;
    @media only screen and (min-width: 768px) {
        font-size: 65px;
    }
`

const InterstitialCopyContainer = styled.div`
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    padding-left: 10px;
    padding-right: 10px;
`

const Circle = styled.div`
    content: "";
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: #d9d9d5;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 15px;
    padding-top: 3px;
`

const CircleInner = styled.div`
    height: 10px;
    width: 10px;
    margin: auto;
    content: "";
    border: 3px solid #fff;
    border-radius: 50%;
`

const InterstitialCaption = styled.div`
    text-align: center;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: .04px;
`

export default function GlobalParallaxBanner({background, title, subtitle}) {
    return (
        <>
        {(background !=='') && 
            <div style={{marginBottom: '85px'}}>
                <ParallaxBanner>
                    <ParallaxBannerLayer background={background}  />
                    {title && 
                        <InterstitialTitle>
                            {title}
                        </InterstitialTitle>
                    }
                </ParallaxBanner>
                {subtitle && 
                    <InterstitialCopyContainer>
                        <Circle>
                            <CircleInner />
                        </Circle>
                        <InterstitialCaption>
                            <div>{subtitle}</div>
                        </InterstitialCaption>
                    </InterstitialCopyContainer>
                }
            </div>
        }
        </>
    )
}