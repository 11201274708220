import styled, {css} from 'styled-components';
import { theme } from '../styles/theme';

const HeroContainer = styled.div`
    padding-left: 45px;
    padding-right: 45px;
    padding-top: 45px;
    padding-bottom: 45px;
    max-width: 1170px;
    margin-right: auto;
    margin-left: auto;
    background-color: #fff;
    position: relative;
    @media screen and (max-width: 768px) {
        padding: 0;
    }
`

const ImageConatiner = styled.div`
    position: absolute;
    right: 45px;
    left: 45px;
    height: calc(100% - 45px);
    @media screen and (max-width: 768px) {
        position: static;
    }
`

const LargeImg = styled.div`
    width: 70%;
    position: relative;
    margin-left: ${props => props.marginLeft};
    margin-right: ${props => props.marginRigh};
    overflow: hidden;
    z-index: 20;
    @media screen and (max-width: 768px) {
        width: 100%;
    }
`

const SmallImg = styled.div`
    width: 50%;
    margin-top: 15px;
    position: relative;
    margin-left: ${props => props.marginLeft};
    margin-right: ${props => props.marginRigh};
    overflow: hidden;
    z-index: 20;
    @media screen and (max-width: 768px) {
        display: none;
    }
`

const Img = styled.img`
    width: 100%;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
`

const ContentContainer = styled.div`
    position: relative;
    z-index: 100;
`

const Content = styled.div`
    max-width: 540px;
    padding: 50px 40px;
    margin-top: 280px;
    margin-left: ${props => props.marginLeft}; auto;
    margin-right: ${props => props.marginRigh}; 0;
    background-color: #fff;
    z-index: 30;
    position: relative;
    @media screen and (max-width: 768px) {
        width: 100%;
        margin-top: 0;
        padding: 0 0 40px 0;
        max-width: unset;
    }
`


export default function HeroImages({alignRight, srcLarge, srcSmall, title, subtitle, children,}) {

    return (
        <HeroContainer>
            <ImageConatiner>
                <LargeImg 
                    marginLeft={alignRight ? '0' : 'auto'}
                    marginRigh={alignRight ? 'auto' : '0'}
                >
                    <Img src={srcLarge}  />
                </LargeImg>
                <SmallImg
                    marginLeft={alignRight ? '0' : 'auto'}
                    marginRigh={alignRight ? 'auto' : '0'}
                >
                    <Img src={srcSmall}  />
                </SmallImg>
            </ImageConatiner>
            <ContentContainer>
                <Content
                    marginLeft={alignRight ? 'auto' : '0'}
                    marginRigh={alignRight ? '0' : 'auto'}
                >   
                    <p>{subtitle}</p>
                    <h1>{title}</h1>
                    <div>{children}</div>
                </Content>
            </ContentContainer>
        </HeroContainer>
    )
}