import React, { Component } from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import { useState } from "react";
import { MapStyles } from "./MapStyles";
import styled, { css } from "styled-components";
import { theme } from "../../styles/theme";
import { dataResellers } from "../../data/dataResellers";
import ResellersDropdown from "./ResellersDropdown";

const FloatingWindow = styled.div`
  position: absolute;
  top: 20px;
  left: 5vw;
  min-width: 500px;
  min-height: 150px;
  background-color: white;
  padding: 20px;
  @media screen and (max-width: 768px) {
    right: 5vw;
    min-width: unset;
  }
`;

const mapContainerStyles = {
  width: "100%",
  height: "550px",
  display: "block",
  position: "relative",
  marginBottom: "40px",
  paddingBottom: "20px",
};

const infoWindow = {
  minWith: "300px",
  minHeight: "200px",
  zIndex: 9999,
  backgroundColor: "#ffffff",
};

const InfoWindowBox = styled.div`
  min-width: 300px;
  max-width: 350px;
  padding: 20px;
  h4 {
    margin-bottom: 0;
    color: ${theme.palette.main500};
  }
  span {
    margin-bottom: 10px;
    font-size: 16px;
    display: block;
  }
  p {
    font-size: 14px;
    margin: 2px 0;
  }
`;

export class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoom: 12,
      lat: -34.6158238,
      lng: -58.4332985,
      showingInfoWindow: false, // Hides or shows the InfoWindow
      activeMarker: {}, // Shows the active marker upon click
      selectedPlace: {}, // Shows the InfoWindow to the selected place upon a marker
    };
  }
  onMarkerClick = (props, marker, e) => {
    console.log("eze", e);
    this.setState({
      ...this.state,
      map: null,
      selectedPlace: e,
      activeMarker: marker,
      showingInfoWindow: true,
    });
  };

  onClose = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        ...this.state,
        showingInfoWindow: false,
        activeMarker: {},
        selectedPlace: {},
      });
    }
  };

  _mapLoaded(mapProps, map) {
    map.setOptions({
      styles: MapStyles,
    });
  }

  handleSelection = (item) => {
    setTimeout(() => {
      this.setState({
        ...this.state,
        lat: item.lat,
        lng: item.lng,
        zoom: 13,
      });
    }, 300);
    setTimeout(() => {
      this.setState({
        ...this.state,
        lat: item.lat,
        lng: item.lng,
        zoom: item.zoom,
      });
    }, 500);
  };

  

  render() {

    function shortWebsite (www) {
        if (www.indexOf('https://') != -1) {
            return www.slice(8).slice(0, -1)
        } else {
            return www.slice(7).slice(0, -1)
        }
    }
    
    return (
      <Map
        google={this.props.google}
        zoom={this.state.zoom}
        style={mapContainerStyles}
        initialCenter={{
          lat: this.state.lat,
          lng: this.state.lng,
        }}
        center={{
          zoom: this.state.zoom,
          lat: this.state.lat,
          lng: this.state.lng,
        }}
        mapTypeControl={false}
        streetViewControl={false}
        onReady={(mapProps, map) => this._mapLoaded(mapProps, map)}
      >
        {dataResellers.map((i, index) => (
          <Marker
            key={index}
            title={i.name}
            name={i.name}
            position={{ lat: `${i.lat}`, lng: `${i.lng}` }}
            onClick={(props, marker) => this.onMarkerClick(props, marker, i)}
          />
        ))}
        <InfoWindow
          style={infoWindow}
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}
          onClose={this.onClose}
        >
          <InfoWindowBox>
            <h4>{this.state.activeMarker.name}</h4>
            <span>
              <b>{this.state.selectedPlace.city}</b>
            </span>
            <p>
              {this.state.selectedPlace.address
                ? `${this.state.selectedPlace.address}`
                : ""}
            </p>
            <p>
              {this.state.selectedPlace.phone
                ? `Tel. ${this.state.selectedPlace.phone}`
                : ""}
            </p>
            <p>
              {this.state.selectedPlace.mobile
                ? `Cel. ${this.state.selectedPlace.mobile}`
                : ""}
            </p>
            <p>
              {this.state.selectedPlace.mail
                ? <a href={`mailto:${this.state.selectedPlace.mail}`} rel="noreferrer" target="_blank">{this.state.selectedPlace.mail}</a>
                : ""}
            </p>
            <p>
              {this.state.selectedPlace.website
              
                ? <a href={this.state.selectedPlace.website} rel="noreferrer" target="_blank">{shortWebsite(`${this.state.selectedPlace.website}`)}</a>
                : ""}
            </p>
            <p>
              {this.state.selectedPlace.Instagram
                ? <a href={`https://www.instagram.com/${this.state.selectedPlace.Instagram}`} rel="noreferrer" target="_blank">@{this.state.selectedPlace.Instagram}</a>
                : ""}
            </p>
          </InfoWindowBox>
        </InfoWindow>

        <FloatingWindow>
          <h4>Buscá una tienda</h4>
          <ResellersDropdown handleSelection={this.handleSelection} />
        </FloatingWindow>
      </Map>
    );
  }
}

export default GoogleApiWrapper((props) => ({
  apiKey: props.apiKey,
}))(MapContainer);
