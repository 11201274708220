import styled, {css} from 'styled-components';
import { theme } from '../../styles/theme';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./feature-product-slider.css";

const FeatureProductsWrapper = styled.div`
    margin: 60px 0 155px;
    padding: 60px 0 100px;
    position: relative;
    background: #f1f1f1;;
`

const FeatureTitle = styled.div`
    display: block;
    text-align: center;
    position: relative;
    margin-bottom: 60px;
    h2 {
        font-size: 45px;
        margin-bottom: 0;
    }
    p {
        margin-top: 10px;
    }
`

const SliderWrapper = styled.div`
    padding: 0px 5vw;
`

const Content = styled.div`
    padding: 15px;
    background-color: #f1f1f1;
    &:hover {
        transition: .6s cubic-bezier(.23,1,.32,1);
        cursor: pointer;
    }
`

const Img = styled.img`
    transition: .8s cubic-bezier(.23,1,.32,1);
    mix-blend-mode: multiply;
    ${Content}:hover & {
    }
`

const SlideInfo = styled.div`
    text-align: center;
    p {
        font-size: 14px;
        margin-bottom: 0px;
    }
    h4 {
        margin: 0;
    }
`

const DotsUl = styled.ul`
    bottom: -50px;
    li {
        margin: 0 10px;
        button {
            width: 26px;
            height: 4px;
            padding: 0;
            background-color: ${theme.palette.grey400};
            &:before {
                content: "";
            }
        }
    }
    li.slick-active {
        button {
            background-color: ${theme.palette.main500};
        }
    }
`

const ArrowWrapper = styled.div`
    z-index: 1;
    &:before{
        content: "";
    }
` 

const Arrow = ({rotate}) => {
    return (
        <svg 
            className="icon icon-arrow" 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 36 36" 
            aria-labelledby="title" 
            fill={theme.palette.main500}
            style={{
                transform: `rotate(${rotate})`,
                width: '12px',
            }}
        >
            <title>Arrow</title>
            <path d="M0.098,1.369l17.05,34.107c0.235,0.468,0.803,0.658,1.271,0.424 c0.184-0.091,0.334-0.24,0.425-0.424l17.05-34.107c0.233-0.469,0.041-1.036-0.429-1.269c-0.267-0.134-0.583-0.132-0.85,0.003 L18.47,8.412L1.359,0.095C0.887-0.133,0.321,0.065,0.095,0.536C-0.032,0.8-0.031,1.108,0.098,1.369z"></path>
        </svg>
    )    
}


export default function ProductSlider({data}) {

    const settings = {
        centerMode: true,
        centerPadding: '60px',
        autoplay: true,
        autoplaySpeed: 3000,
        dots: true,
        appendDots: dots => <DotsUl>{dots}</DotsUl>,
        accessibility: true,
        arrows: true,
        prevArrow: <ArrowWrapper><Arrow rotate="90deg" /></ArrowWrapper>, 
        nextArrow: <ArrowWrapper><Arrow rotate="-90deg" /></ArrowWrapper>,
        infinite: true,
        speed: 350,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    };
    
    
    function beforeChangeAction() {
        console.log('before')
    }
    
    function afterChangeAction() {
        console.log('after')
    }

    const slides = data.map((i) => 
        <div key={i}>
            <Content className="content">
                <a href={`/producto/${i.title}`}>
                    <Img 
                        src={`/public/${i.image}`}
                        alt={i.title} 
                        className='img-fluid slide-img'
                    />
                </a>
                <SlideInfo className="slide-info">
                    <p>{i.description}</p>
                    <h4>{i.title}</h4>
                </SlideInfo>
            </Content>
        </div>
    );
    
    return (
        <FeatureProductsWrapper>
            <FeatureTitle>
                <h2>Destacados</h2>
                <p><b>Explora algunos de nuestros productos más populares de Alpine</b></p>
            </FeatureTitle>
            
            <SliderWrapper className='feature-prod-slider'>
                <Slider {...settings}>
                    {slides}
                </Slider>
            </SliderWrapper>
        </FeatureProductsWrapper>
    )
}


