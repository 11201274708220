import Header from "../components/header/Header";
import styled from "styled-components";
import { theme } from '../styles/theme';
import GoogleApi from "../components/store-locator/Map";
import { dataResellers, dataZoneResellers } from "../data/dataResellers";

const MapWrapper = styled.div`
    background-color: ${theme.palette.grey300};
    margin-top: 75px;
    overflow: hidden;
    clear: both;
    height: 550px;
    position: relative;
`

const ResellerContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const ResellerCard = styled.div`
    width: 33.3333%;
    padding: 0 40px 0 0;
    margin-bottom: 45px;
    font-size: 16px;
    @media screen and (max-width: 768px) {
        width: 100%;
        border-bottom: 1px solid #e5e5e5;
        padding-bottom: 30px;
    }
    @media screen and (min-width: 769px) and (max-width: 992px) {
        width: 50%;
    }
`

const resellersByZone = (zone) => {
    const title = zone;
    const _resellers = dataResellers.filter(el => el.zone == title);


    function shortWebsite (www) {
        if (www.indexOf('https://') != -1) {
            return www.slice(8).slice(0, -1)
        } else {
            return www.slice(7).slice(0, -1)
        }
    }

    return (
        <div key={title}>
            <h4>{title}</h4>
            <ResellerContainer>
                {_resellers.map(i => 
                    <ResellerCard key={i.name}>
                        <ul>
                            <li><b>{i.name}</b></li>
                            <li>{i.address}</li>
                            <li>{i.city}</li>
                            {i.phone &&
                                <li>Tel. {i.phone}</li>
                            }
                            {i.mobile &&
                                <li>Cel. {i.mobile}</li>
                            }
                            <li><a href={`mailto:${i.mail}`}>{i.mail}</a></li>
                            <li>
                                <a href={i.website} rel="noreferer" target="_blank">
                                    {shortWebsite(`${i.website}`)}
                                </a>
                            </li>
                            {i.Instagram &&
                                <li>
                                    <a href={`https://www.instagram.com/${i.Instagram}`} rel="noreferrer" target="_blank">@{i.Instagram}</a>
                                </li>
                            }
                        </ul>
                        
                    </ResellerCard>
                )}
            </ResellerContainer>
        </div>
    )
}

const resellers = () => 
    <div className="section-wrapper">
        {dataZoneResellers.map(zone => resellersByZone(zone.zone))}
    </div>


export default function StoreLocator () {
    return (
        <>
            <Header sectionIn />
            <MapWrapper>
                <GoogleApi apiKey='AIzaSyAn1ypih3OxwhVJZcM3jZY2XrEkRgLrG6M'/>
            </MapWrapper>
            <div className="section-wrapper">
                <h3>Tiendas Autorizadas</h3>
                {resellers()}
            </div>
        </>
        
    );
}