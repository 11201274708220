import styled, {css} from 'styled-components';

const SearchWidgitQuickLinks = styled.div`
    padding: 50px 0 64px;
    margin: 0 auto;
    max-width: 300px;
    text-align: center;
    @media only screen and (min-width: 1005px) {
        padding: 30px 0 0;
    }
`

const SearchWidgitTitle = styled.p`
    font-weight: 700 !important;
    font-size: 18px;
    margin-bottom: 30px;
    text-transform: uppercase;
}
`

const SearchWidgitItem = styled.li`
    margin-bottom: 20px;
`

const SearchWidgitLink = styled.a`
    color: white !important;
    display: inline-block;
    text-decoration: none;
    font-size:19px;
    position: relative;

    &:before {
        background: #fff;
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        transition: .5s cubic-bezier(.19,1,.22,1);
        width: 0;
        height: 1px;
    }

    &:hover {
        &:before {
            width: 100%;
            height: 1px;
        }
    }
`

export default function SearchWidget ({onClick, open}) {
    return (
        <SearchWidgitQuickLinks>
            <SearchWidgitTitle>
                <span>Accesos rápidos</span>
            </SearchWidgitTitle>
            <ul>
                <SearchWidgitItem>
                    <SearchWidgitLink href='/buscador-de-tiendas'>
                        Buscador de tiendas
                    </SearchWidgitLink>
                </SearchWidgitItem>
                <SearchWidgitItem>
                    <SearchWidgitLink href='/soporte'>
                        Soporte
                    </SearchWidgitLink>
                </SearchWidgitItem>
                <SearchWidgitItem>
                    <SearchWidgitLink href='/productos/Receptores'>
                        Productos
                    </SearchWidgitLink>
                </SearchWidgitItem>
                <SearchWidgitItem>
                    <SearchWidgitLink href='/soporte'>
                        Contactanos
                    </SearchWidgitLink>
                </SearchWidgitItem>
            </ul>
        </SearchWidgitQuickLinks>
    );
}