import styled, {css} from 'styled-components';
import { theme } from '../styles/theme';
import ImgBg from '../images/alpine-products-backdrop.jpeg';

const ProductsNavigation = styled.div`
    margin-bottom: 50px;
    position: relative; 
`

const ProductsNavigationBackdrop = styled.div`
    z-index: -1;
    position: absolute;
    height: 280px;
    width: 100%;
    content: "";
    background: url(${ImgBg}),linear-gradient(90deg,#003b6b,#00549a 40%,#00549a 60%,#003b6b);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
`

const ProductsNavigationHeroContainer = styled.div`
    max-width: 1170px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: -10px;
    padding-top: 127px;
`

const ProductsNavigationHeroWrapper = styled.div`
`
const ProductsNavigationTitle = styled.h1`
    color: white !important;
    text-align: center;
    width: 75%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 8px;
    overflow: hidden;
`

export default function SectionHeader({title}) {
    return (
        <ProductsNavigation>
            <ProductsNavigationBackdrop />
            <ProductsNavigationHeroContainer>
                <ProductsNavigationHeroWrapper>
                    <ProductsNavigationTitle>
                        {title}
                    </ProductsNavigationTitle>
                </ProductsNavigationHeroWrapper>
            </ProductsNavigationHeroContainer>
        </ProductsNavigation>
    );
}