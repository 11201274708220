import styled, {css} from 'styled-components';
import { theme } from '../../styles/theme';


const ProductAppsWrapper = styled.div`
    max-width: 1170px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 114px;

    @media only screen and (max-width: 768px) {
        margin-bottom: 75px;
    }
`

const ProductAppsContent = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 100px;
    padding: 0 7%;

    &:last-child {
        margin-bottom: 0;
    }

    @media only screen and (max-width: 768px) {
        display: inline-block;
        margin-bottom: 0;
        padding: 0;
    }

`
const ProductAppsImageContainer = styled.div`

    @media only screen and (max-width: 768px) {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }

    @media only screen and (max-width: 768px) {
        margin-bottom: 30px;
        max-width: 500px;
    }

    width: 60%;

`

const ProductAppsCopyContainer = styled.div`
    display: inline-block;
    vertical-align: middle;
    padding: 0 20px;
    width: 40%;

    p {
        white-space: pre-line;
    }

    @media only screen and (max-width: 768px){
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
    
`


export default function ProductApps({image, title, text, productName}) {
    return (
        <ProductAppsWrapper>
            <ProductAppsContent>
                {image && 
                    <ProductAppsImageContainer>
                        <div>
                            <img src={`/public/${image}`} alt={title} className="img-fluid" />
                        </div>
                    </ProductAppsImageContainer>
                }
                <ProductAppsCopyContainer>
                    <h4>{title}</h4>
                    <p>
                        <div dangerouslySetInnerHTML={{ __html: text.replaceAll("#product_code#", `${productName}`) }}></div>
                    </p>
                </ProductAppsCopyContainer>
            </ProductAppsContent>
        </ProductAppsWrapper>
    );
}