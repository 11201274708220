


// Home Feature Products

export const dataFeatureProducts = 
[
    {
        "id": 0,
        "image": "/assets/product_assets/AD-SPK1/AD-SPK1_Angle-Front-Left-Wet.jpg", 
        "title": "AD-SPK1", 
        "description":"Altavoz con Bluetooth® resistente al agua Alpine Turn1™",
    }, 
    {
        "id": 1,
        "image": "/assets/product_assets/iLX-507/iLX-507_Detail_3.jpg", 
        "title": "iLX-507", 
        "description":"Receptor multimedia digital de 7 pulgadas con pantalla HD y reproducción de audio Hi-Res",
    }, 
    {
        "id": 2,
        "image": "/assets/product_assets/iLX-F509/iLX-F509_agl_Sound_Home_US-AU-AP-JP.jpg", 
        "title": "iLX-F509", 
        "description":"Receptor multimedia digital Alpine Halo9 de 9 pulgadas con pantalla HD y reproducción de audio Hi-Res",
    }, 
    {
        "id": 3,
        "image": "/assets/product_assets/iLX-F511/iLX-F511_agl_Sound_Home_US-AU-AP-JP.jpg", 
        "title": "iLX-F511", 
        "description":"Receptor multimedia digital Alpine Halo11 de 11 pulgadas con pantalla HD y reproducción de audio Hi-Res",
    }, 
    {
        "id": 4,
        "image": "/assets/product_assets/X-Series_Amplifiers/X-A90V_Angle.jpg", 
        "title": "X-A90V", 
        "description":"Amplificador de densidad de potencia de 5 canales",
    }, 
    {
        "id": 5,
        "image": "/assets/product_assets/X-Series_Speakers/X-S65C.jpg", 
        "title": "X-S65C", 
        "description":"Conjunto componente de 6-1/2 pulgadas de 2 vías",
    }, 
];




