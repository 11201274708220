import styled, {css} from 'styled-components';
import { theme } from '../../styles/theme';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { dataCarouselFeatures } from '../../data/dataCarousel';

const FeatureSliderWrapper = styled.div`
    background-color: #f5f5f5;
    padding: 80px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`

const SlideIn = styled.div`
    h6 {
        margin-top: 20px;
        margin-bottom:0;
    }
    p {
        font-size: 15px;
    }
  
`
const Content = styled.div`
    padding: 20px;
    text-align: center;
    background-color: #f5f5f5;
    img {
        display: inline-block;
        mix-blend-mode: multiply;
    }
`

const DotsUl = styled.ul`
    /* bottom: 120px; */
    li {
        margin: 0 10px;
        button {
            width: 26px;
            height: 4px;
            padding: 0;
            background-color: ${theme.palette.grey400};
            &:before {
                content: "";
            }
        }
    }
    li.slick-active {
        button {
            background-color: ${theme.palette.main500};
        }
    }
`

const ArrowWrapper = styled.div`
    z-index: 1;
    &:before{
        content: "";
    }
` 

const CarruselWraper = styled.div `
    max-width: 1000px;
    width: 100%;
    padding: 40px;
`

const Arrow = ({rotate}) => {
    return (
        <svg 
            className="icon icon-arrow" 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 36 36" 
            aria-labelledby="title" 
            fill={theme.palette.main500}
            style={{
                transform: `rotate(${rotate})`,
                width: '12px',
            }}
        >
            <title>Arrow</title>
            <path d="M0.098,1.369l17.05,34.107c0.235,0.468,0.803,0.658,1.271,0.424 c0.184-0.091,0.334-0.24,0.425-0.424l17.05-34.107c0.233-0.469,0.041-1.036-0.429-1.269c-0.267-0.134-0.583-0.132-0.85,0.003 L18.47,8.412L1.359,0.095C0.887-0.133,0.321,0.065,0.095,0.536C-0.032,0.8-0.031,1.108,0.098,1.369z"></path>
        </svg>
    )    
}

export default function FeatureSlider({
    made_for, 
    bluetooth, 
    pandora, 
    hdmi, 
    flac,
    productName
}) {

    const settings = {
        autoplay: false,
        autoplaySpeed: 3000,
        dots: true,
        appendDots: dots => <DotsUl>{dots}</DotsUl>,
        accessibility: true,
        arrows: true,
        prevArrow: <ArrowWrapper><Arrow rotate="90deg" /></ArrowWrapper>, 
        nextArrow: <ArrowWrapper><Arrow rotate="-90deg" /></ArrowWrapper>,
        infinite: true,
        speed: 350,
        slidesToShow: 3,
        //slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };


    const pulgadas = (product) => {
        switch(product) {
            case 'iLX-F411': return 11;
            case 'iLX-F409': return 9;
            case 'iLX-W650': return 7;
            case 'INE-970HD': return 6.5;
            default: return '';
        }
    }
    
    function slidesByProduct() {
        if (productName == 'INE-W987HD') {
            return (
                <Content style={{maxWidth: '450px', margin: '0 auto'}}>
                    <img 
                        src={require(`../../images/product/features-icons/onpoint1.jpg`)} 
                        alt="ONPOINT" 
                    />
                    <h6>PRECISIÓN CON LA LOCALIZACIÓN GPS AVANZADA ONPOINT™</h6>
                    <p>El {productName} utiliza la localización GPS avanzada OnPoint Plus de Alpine. Esto asegura que tu vehículo se mantiene localizado de manera precisa sobre el mapa, incluso en áreas donde es difícil recibir señal GPS, como dentro de los túneles o alrededor de edificios altos. OnPoint Plus utiliza tres tecnologías simultáneamente: datos de localización vinculados por satélite, tecnología de sensor giroscópico (para determinar la dirección del vehículo) y tecnología de acelerómetro (para medir la velocidad del vehículo), lo cual resulta en una cobertura continua de la ubicación de tu vehículo. Como un aseguramiento adicional de la precisión, está disponible una conexión opcional a la señal de pulso de velocidad del vehículo.</p>
                </Content>
            )
        }
        if (productName == 'iLX-F411' || productName == 'iLX-F409' || productName == 'iLX-W650' || productName == 'INE-W970HD' || productName == 'INE-W987HD') {
            return (
                <Slider {...settings}>
                    <SlideIn>
                        <Content >
                            <img 
                                src={require(`../../images/product/features-icons/made-for.jpg`)} 
                                alt="Made for" 
                            />
                                <h6>HECHO PARA IPOD®/ IPHONE®</h6>
                                <p>Es cómodo controlar tu iPod o iPhone en tu automóvil: disfruta de una operación fluida de tu iPod, además de un control completo de todos tus medios favoritos. Controla todas las opciones de búsqueda de tu iPod directamente desde el {productName}, por lista de reproducción, artista, álbum, canción, género, podcast o compositor. La conexión USB permite una reproducción de alta calidad del iPod/iPhone, y podrás encontrar tus canciones tan rápido como lo haces en tu iPod/iPhone.</p>
                        </Content>
                    </SlideIn>
                    <SlideIn>
                        <Content >
                            <img 
                                src={require(`../../images/product/features-icons/bluetooth.png`)} 
                                alt="Bluetooth" 
                            />
                                <h6>LLAMADAS MANOS LIBRES Y AUDIO STREAMING POR BLUETOOTH</h6>
                                <p>Sé inteligente cuando usas tu teléfono en el camino. La tecnología inalámbrica integrada de Bluetooth permite llamadas manos libres, emparejamiento simple seguro (SSP) y llamadas en espera. En la pantalla de {pulgadas(productName)} pulgadas se muestran íconos que indican el nivel de batería y de señal del celular. Expande tus opciones de entretenimiento con audio streaming desde tu celular inteligente.</p>
                        </Content>
                    </SlideIn>
                    <SlideIn>
                        <Content >
                            <img 
                                src={require(`../../images/product/features-icons/hdmi.jpg`)} 
                                alt="HDMI" 
                            />
                            <h6>HDMI: MÁS ENTRETENIMIENTO CON UN SOLO CABLE</h6>
                            <p>Ahora es posible disfrutar de mucho más contenido que antes mediante tu {productName}. El {productName} tiene compatibilidad HDMI, para una conexión de audio y video totalmente digital mediante un solo cable.</p>
                        </Content>
                    </SlideIn>
                    <SlideIn>
                        <Content>
                            <img 
                                src={require(`../../images/product/features-icons/flac.jpg`)} 
                                alt="Flac" 
                                />
                            <h6>COMPATIBLE CON ARCHIVOS DE AUDIO FLAC HI-RES</h6>
                            {productName == 'INE-W970HD' ? (
                                <p>¿Disfrutas de archivos de música lossless? El {productName} puede reproducir archivos de música FLAC mediante USB, así que puedes disfrutar de tus archivos digitales de música sin pérdidas en la calidad de compresión.</p>
                                ) : (
                                <p>El {productName} puede reproducir archivos 24/96 FLAC mediante USB. Otros formatos compatibles incluyen reproducción de música MP3/WMA/AAC/HE-AAC mediante USB.</p>
                                )
                            }
                            
                        </Content>
                    </SlideIn>
                </Slider>
            )
        }
        if (productName == 'iLX-F509' || productName == 'iLX-F511') {
            return (
                <Slider {...settings}>
                    <SlideIn>
                        <Content >
                            <img 
                                src={require(`../../images/product/features-icons/made-for.jpg`)} 
                                alt="Made for" 
                            />
                        </Content>
                    </SlideIn>
                    <SlideIn>
                        <Content >
                            <img 
                                src={require(`../../images/product/features-icons/bluetooth.png`)} 
                                alt="Bluetooth" 
                            />
                        </Content>
                    </SlideIn>
                    <SlideIn>
                        <Content >
                            <img 
                                src={require(`../../images/product/features-icons/hdmi.jpg`)} 
                                alt="HDMI" 
                            />
                        </Content>
                    </SlideIn>
                    <SlideIn>
                        <Content>
                            <img 
                                src={require(`../../images/product/features-icons/flac.jpg`)} 
                                alt="Flac" 
                                />
                        </Content>
                    </SlideIn>
                    <SlideIn>
                        <Content>
                            <img 
                                src={require(`../../images/product/features-icons/spotify.png`)} 
                                alt="Spotify" 
                                />
                        </Content>
                    </SlideIn>
                    <SlideIn>
                        <Content>
                            <img 
                                src={require(`../../images/product/features-icons/waze.png`)} 
                                alt="Waze" 
                                />
                        </Content>
                    </SlideIn>
                </Slider>
            )
        }
    }

    
    if (productName == 'iLX-F411' || productName == 'iLX-F409' || productName == 'iLX-F509' || productName == 'iLX-F511' || productName == 'iLX-W650' || productName == 'INE-W970HD' || productName == 'INE-W987HD') {
        return (
            <div className='slider-apps'>
                <FeatureSliderWrapper>
                    <div style={{maxWidth: '800px', margin: '0 auto', padding: '20px'}}>

                        {(productName == 'iLX-F411' || productName == 'iLX-F409' || productName == 'iLX-W650' || productName == 'INE-W970HD' || productName == 'INE-W987HD') &&
                            <h2 style={{marginBottom: '20px',}}>FUNCIONES INTEGRADAS</h2>
                        }
                        {(productName == 'iLX-F509' || productName == 'iLX-F511') &&
                            <h2 style={{marginBottom: '20px',}}>TECNOLOGÍA DE LA PRÓXIMA GENERACIÓN</h2>
                        }
                        
                        {(productName == 'iLX-F411' || productName == 'iLX-F409' || productName == 'iLX-W650' || productName == 'INE-W970HD') &&
                            <p style={{margin: '0 0 20px',}}>El {productName} sabe cómo mantenerte entretenido en el camino, ofreciendo numerosas opciones de entretenimiento.</p>
                        }
                        
                        {productName == 'iLX-F509' &&
                            <p style={{margin: '0 0 20px',}}>Mejora tu paseo con todas las últimas funciones y aplicaciones ahora disponibles en el Alpine Halo9.</p>
                        }
                        {productName == 'iLX-F511' &&
                            <p style={{margin: '0 0 20px',}}>Mejora tu paseo con todas las últimas funciones y aplicaciones ahora disponibles en el Alpine Halo11.</p>
                        }
                    </div>
                    <CarruselWraper>
                        {slidesByProduct()}
                    </CarruselWraper>
                </FeatureSliderWrapper>
            </div>
        );
    } else {
        return null
    }
}



