import styled, {css} from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const Search = styled.button`
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
    pointer-events: all;
    cursor: pointer;
    color: ${props => props.color ? props.color : 'white'}; 
    z-index: 9;
    svg {
        width:16px !important;
        height: 16px;
    }
`

export default function SearchDesktop ({onClick, color}) {
    return (
        <Search onClick={onClick} color={color}>
            <FontAwesomeIcon icon={faSearch} />
        </Search>
    );
}