import { useState } from "react";
import styled from "styled-components";
import useSearch from "../../hooks/useSearch.js";
import { theme } from "../../styles/theme.js";
import LogoAlpineTagline from "../../svg/logo-alpine-tagline.js";
import MainMenu from "./MainMenu";
import MenuHamburguer from "./MenuHamburguer";
import SearchMobile from "./SearchMobile";
import SearchWidget from "./SearchWidget.js";
import SidebarMenuMobile from "./SidebarMenuMobile";

const GlobalNav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${(props) => props.sectionIn};
  z-index: 900;
  @media only screen and (min-width: 1005px) {
    background-color: transparent;
  }
`;

const GlobalNavContainer = styled.div`
  max-width: 1170px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
`;

const Logo = styled.div`
  width: 116px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  top: 9px;
  @media only screen and (min-width: 1005px) {
    top: 26px;
  }
`;

const GlobalNavLine = styled.div`
  content: "";
  height: 1px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: #d9d9d5;
  margin-top: 44px;
  max-width: calc(100% + 2em);
  position: fixed;

  @media only screen and (min-width: 1005px) {
    max-width: 1170px;
    content: "";
    height: 1px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    background-color: #d9d9d5;
  }
`;

const BgHeaderMobile = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 45px;
  background-color: white;
`

export default function Header({ sectionIn }) {
  const [showSidebar, setShowSidebar] = useState(false);
  const handleShowSidebar = () => setShowSidebar(!showSidebar);
  const { showSearchWidget, handleShowSearchWidget } = useSearch();

  return (
    <GlobalNav sectionIn={sectionIn ? "white !important" : "white"}>
      <GlobalNavContainer>
        <SearchWidget
          onClick={handleShowSearchWidget}
          open={showSearchWidget}
        />
        <SidebarMenuMobile onClick={handleShowSidebar} active={showSidebar} />
        <MenuHamburguer 
          sectionMobileIn={sectionIn}
          onClick={handleShowSidebar}
        />
        {sectionIn && <BgHeaderMobile />}
        <MainMenu
          displayMobile="none"
          isDesktop
          onClick={handleShowSearchWidget}
          onClickMenuItem={handleShowSidebar}
          textColor={
            sectionIn ? `${theme.palette.main500} !important` : "white"
          }
          underlineColor={
            sectionIn ? `${theme.palette.main500} !important` : "white"
          }
        />
        <a href="/">
          <Logo>
            <LogoAlpineTagline
              width="116px"
              fill={sectionIn ? `${theme.palette.grey800} !important` : "white"}
            />
          </Logo>
        </a>
        <SearchMobile
          onClick={handleShowSearchWidget}
          iconColor={
            sectionIn ? `${theme.palette.main500} !important` : "white"
          }
        />
        <GlobalNavLine />
      </GlobalNavContainer>
    </GlobalNav>
  );
}
