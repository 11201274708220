import styled from "styled-components";

const Ul = styled.ul`
    list-style: outside !important;
    padding: 0 0 0 15px !important;
    li {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0;
        padding-left: 15px;
        margin-bottom: 3px;
    }
`

export default function ListText({children}) {
    return (
        <Ul>
            {children}
        </Ul>
    )
}