import styled from 'styled-components';

const GlobalFooterContainer = styled.div`
    font-size: 13px;
    font-size: .8rem;
    line-height: 20px;
    line-height: 1.25rem;
    letter-spacing: 0;
    margin-top: 30px;
    text-align: center;
    font-weight: 300;
    font-style: normal;
    font-smoothing: antialiased;
`

export default function FooterBottom() {
    return (
        <GlobalFooterContainer>
            © Alpine Electronics of America, Inc. | Todos los derechos reservados.
        </GlobalFooterContainer>
    )
}