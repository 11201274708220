export default function IntroFlagship () {
    return (
        <div id="js-intro_line" className="o-section__intro">

        <div className="o-box__fade_item js-fade_item is-fade_active">
          <div className="p-index__intro_top">

            <div className="p-index__intro_cnt">

              <h2 className="p-index__intro_line">
                <picture>
                  <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/intro_line_sp.png')} />
                    <img src={require('../../images/alpineflagship/intro_line_pc.png')} alt="introduction" />
                </picture>
                <span id="js-intro_line_target" className="p-index__intro_line_bg" style={{height: '385.75px'}}></span>
              </h2>

              <div className="p-index__intro_title is-l_us">
                <picture>
                  <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/intro_title_sp.png')} />
                    <img src={require('../../images/alpineflagship/intro_title_pc.png')} alt="Experience music so accurate it transforms you" />
                </picture>
              </div>

              <div className="o-text_box__base">
                <p>El sistema Alpine F#1 Status entregará la experiencia y la emoción de una actuación musical en vivo.<br />
Te brindaremos las intenciones originales de los artistas capturando los detalles producidos en un estudio de grabación, para que puedas sentir la energía de una actuación en vivo. 
</p>
              </div>

            </div>

            <div className="p-index__intro_thumb">
              <picture>
                <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/intro_thumb1_sp.png')} />
                  <img src={require('../../images/alpineflagship/intro_thumb1_pc.png')} alt="" />
              </picture>
            </div>

          </div>
        </div>

        <div className="o-box__fade_item js-fade_item is-fade_active">
          <div className="p-index__intro_bottom">
            <ul className="o-list__top_intro">
              <li>
                <picture>
                  <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/intro_thumb2_sp.png')} />
                    <img src={require('../../images/alpineflagship/intro_thumb2_pc.png')} alt="" />
                </picture>
              </li>
              <li>
                <picture>
                  <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/intro_thumb3_sp.png')} />
                    <img src={require('../../images/alpineflagship/intro_thumb3_pc.png')} alt="" />
                </picture>
              </li>
              <li>
                <picture>
                  <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/intro_thumb4_sp.png')} />
                    <img src={require('../../images/alpineflagship/intro_thumb4_pc.png')} alt="" />
                </picture>
              </li>
              <li>
                <picture>
                  <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/intro_thumb5_sp.png')} />
                    <img src={require('../../images/alpineflagship/intro_thumb5_pc.png')} alt="" />
                </picture>
              </li>
            </ul>
          </div>
        </div>

      </div>
    )
} 
