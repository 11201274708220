import GlobalFlagshipNav from "../../components/flagship/GlobalFlagshipNav";
import Header from "../../components/header/Header";

export default function Product () {
    return (
        <>
            <Header sectionIn />
            <div className="p-index">

                <main>
                    <div className="l-main__wrap">
                        <div className="o-box__nav_flagship is-mb_type1">
                            <GlobalFlagshipNav />
                        </div>

                        <div class="o-box__fade_item js-fade_item is-fade_active">
                            <div class="o-single_img__pi_thumb">
                            <picture>
                                <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/pi/pi_thumb1_sp.png')} />
                                <img src={require('../../images/alpineflagship/pi/pi_thumb1_pc.png')} alt="" />
                            </picture>
                            </div>
                        </div>

                        <div class="o-box__fade_item js-fade_item is-fade_active">
                            <a name="pl_hds" class="anchor"></a>
                            <div id="pl_hds" class="o-single_img__pi_thumb">
                            <picture>
                                <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/pi/pi_thumb2_sp.png')} />
                                <img src={require('../../images/alpineflagship/pi/pi_thumb2_pc.png')} alt="" />
                            </picture>
                            </div>
                        </div>

                        <div class="o-box__fade_item js-fade_item is-fade_active">
                            <div class="o-single_img__pi_thumb">
                            <picture>
                                <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/pi/pi_thumb3_sp.png')} />
                                <img src={require('../../images/alpineflagship/pi/pi_thumb3_pc.png')} alt="" />
                            </picture>
                            </div>
                        </div>

                        <div class="o-box__fade_item js-fade_item is-fade_active">
                            <div class="o-single_img__pi_thumb">
                            <picture>
                                <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/pi/pi_thumb4_sp.png')} />
                                <img src={require('../../images/alpineflagship/pi/pi_thumb4_pc.png')} alt="" />
                            </picture>
                            </div>
                        </div>

                        <div class="o-box__fade_item js-fade_item is-fade_active">
                            <div class="o-single_img__pi_thumb is-to_mq">
                            <img src={require('../../images/alpineflagship/pi/pi_thumb5_sp.png')} alt="" />
                            </div>
                        </div>

                        <div class="o-box__fade_item js-fade_item is-fade_active">
                            <div class="o-single_img__pi_thumb is-to_mq">
                            <img src={require('../../images/alpineflagship/pi/pi_thumb6_sp.png')} alt="" />
                            </div>
                        </div>

                        <div class="o-box__fade_item js-fade_item is-fade_active">
                            <div class="o-single_img__pi_thumb is-to_mq">
                            <img src={require('../../images/alpineflagship/pi/pi_thumb7_sp.png')} alt="" />
                            </div>
                        </div>

                        <div class="o-box__fade_item js-fade_item is-fade_active">
                            <div class="o-single_img__pi_thumb is-to_mq">
                            <img src={require('../../images/alpineflagship/pi/pi_thumb8_sp.png')} alt="" />
                            </div>
                        </div>

                        <div class="o-box__fade_item js-fade_item is-fade_active">
                            <a name="pl_dap" class="anchor"></a>
                            <div id="pl_dap" class="o-single_img__pi_thumb">
                            <picture>
                                <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/pi/pi_thumb9_sp.png')} />
                                <img src={require('../../images/alpineflagship/pi/pi_thumb5_pc.png')} alt="" />
                            </picture>
                            </div>
                        </div>

                        <div class="o-box__fade_item js-fade_item is-fade_active">
                            <div class="o-single_img__pi_thumb">
                            <picture>
                                <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/pi/pi_thumb10_sp.png')} />
                                <img src={require('../../images/alpineflagship/pi/pi_thumb6_pc.png')} alt="" />
                            </picture>
                            </div>
                        </div>

                        <div class="o-box__fade_item js-fade_item is-fade_active">
                            <div class="o-single_img__pi_thumb">
                            <picture>
                                <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/pi/pi_thumb11_sp.png')} />
                                <img src={require('../../images/alpineflagship/pi/pi_thumb7_pc.png')} alt="" />
                            </picture>
                            </div>
                        </div>

                        <div class="o-box__fade_item js-fade_item is-fade_active">
                            <div class="o-single_img__pi_thumb">
                            <picture>
                                <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/pi/pi_thumb12_sp.png')} />
                                <img src={require('../../images/alpineflagship/pi/pi_thumb8_pc.png')} alt="" />
                            </picture>
                            </div>
                        </div>

                        <div class="o-box__fade_item js-fade_item is-fade_active">
                            <div class="o-single_img__pi_thumb is-to_mq">
                            <img src={require('../../images/alpineflagship/pi/pi_thumb13_sp.png')} alt="" />
                            </div>
                        </div>

                        <div class="o-box__fade_item js-fade_item is-fade_active">
                            <a name="pl_hdp" class="anchor"></a>
                            <div id="pl_hdp" class="o-single_img__pi_thumb">
                            <picture>
                                <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/pi/pi_thumb14_sp.png')} />
                                <img src={require('../../images/alpineflagship/pi/pi_thumb9_pc.png')} alt="" />
                            </picture>
                            </div>
                        </div>

                        <div class="o-box__fade_item js-fade_item is-fade_active">
                            <div class="o-single_img__pi_thumb">
                            <picture>
                                <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/pi/pi_thumb15_sp.png')} />
                                <img src={require('../../images/alpineflagship/pi/pi_thumb10_pc.png')} alt="" />
                            </picture>
                            </div>
                        </div>

                        <div class="o-box__fade_item js-fade_item is-fade_active">
                            <div class="o-single_img__pi_thumb">
                            <picture>
                                <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/pi/pi_thumb16_sp.png')} />
                                <img src={require('../../images/alpineflagship/pi/pi_thumb11_pc.png')} alt="" />
                            </picture>
                            </div>
                        </div>

                        <div class="o-box__fade_item js-fade_item is-fade_active">
                            <div class="o-single_img__pi_thumb is-to_mq">
                            <img src={require('../../images/alpineflagship/pi/pi_thumb17_sp.png')} alt="" />
                            </div>
                        </div>

                        <div class="o-box__fade_item js-fade_item is-fade_active">
                            <a name="pl_hda" class="anchor"></a>
                            <div id="pl_hda" class="o-single_img__pi_thumb">
                            <picture>
                                <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/pi/pi_thumb18_sp.png')} />
                                <img src={require('../../images/alpineflagship/pi/pi_thumb12_pc.png')} alt="" />
                            </picture>
                            </div>
                        </div>

                        <div class="o-box__fade_item js-fade_item is-fade_active">
                            <div class="o-single_img__pi_thumb">
                            <picture>
                                <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/pi/pi_thumb19_sp.png')} />
                                <img src={require('../../images/alpineflagship/pi/pi_thumb13_pc.png')} alt="" />
                            </picture>
                            </div>
                        </div>

                        <div class="o-box__fade_item js-fade_item is-fade_active">
                            <div class="o-single_img__pi_thumb is-to_mq">
                            <img src={require('../../images/alpineflagship/pi/pi_thumb20_sp.png')} alt="" />
                            </div>
                        </div>

                        <div class="o-box__fade_item js-fade_item is-fade_active">
                            <a name="pl_hdz" class="anchor"></a>
                            <div id="pl_hdz" class="o-single_img__pi_thumb">
                            <picture>
                                <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/pi/pi_thumb21_sp.png')} />
                                <img src={require('../../images/alpineflagship/pi/pi_thumb14_pc.png')} alt="" />
                            </picture>
                            </div>
                        </div>

                        <div class="o-box__fade_item js-fade_item is-fade_active">
                            <div class="o-single_img__pi_thumb">
                            <picture>
                                <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/pi/pi_thumb22_sp.png')} />
                                <img src={require('../../images/alpineflagship/pi/pi_thumb15_pc.png')} alt="" />
                            </picture>
                            </div>
                        </div>

                        <div class="o-box__fade_item js-fade_item is-fade_active">
                            <div class="o-single_img__pi_thumb">
                            <picture>
                                <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/pi/pi_thumb23_sp.png')} />
                                <img src={require('../../images/alpineflagship/pi/pi_thumb16_pc.png')} alt="" />
                            </picture>
                            </div>
                        </div>

                        <div class="o-box__fade_item js-fade_item is-fade_active">
                            <div class="o-single_img__pi_thumb is-to_mq">
                            <img src={require('../../images/alpineflagship/pi/pi_thumb24_sp.png')} alt="" />
                            </div>
                        </div>

                        <div class="o-box__fade_item js-fade_item is-fade_active">
                            <div class="o-single_img__pi_thumb is-to_mq">
                            <img src={require('../../images/alpineflagship/pi/pi_thumb25_sp.png')} alt="" />
                            </div>
                        </div>

                        <div class="o-box__fade_item js-fade_item is-fade_active">
                            <div class="o-single_img__pi_thumb is-to_mq">
                            <img src={require('../../images/alpineflagship/pi/pi_thumb26_sp.png')} alt="" />
                            </div>
                        </div>

                    </div> 

                    <div class="o-box__nav_flagship is-mt_type2">
                        <GlobalFlagshipNav />
                    </div>
                </main>

                <div class="p-index__footer is-mt_type1">
                    <picture>
                    <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/copyright_sp.png')} />
                        <img src={require('../../images/alpineflagship/copyright_pc.png')} alt="copyright" />
                    </picture>
                </div>

            </div>
        </>
        
    );
}