import { theme } from '../styles/theme';

export const IconArrow = () => {
    return (
        <svg 
            style={{
                margin: '0 8px',
                fill: `${theme.palette.main500}`, //'#00549a',
                height: '9px',
                verticalAlign: 'middle',
                display: 'inline-block',
                marginTop: '-2px',
            }}  
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 343 492" fill={theme.palette.main500} width="5px" height="5px">
            <polygon points="343 246 0 492 45 246 0 0 343 246"></polygon>
        </svg>
    )
}
