import styled, {css} from 'styled-components';
import { theme } from '../../styles/theme';

const ProductSpecBucketContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding-left: 45px;
    padding-right: calc(6% + 20px);
    align-items: flex-end;
    @media only screen and (max-width: 1024px) {
        flex-direction: column;
        padding-left: 0;
        padding-right: 0;
    }

    ${props => props.secondrow && css`
        align-items: flex-start;
    `}

`

const ProductSpecBucketImageContainer = styled.div`
    max-width: 53.6%;
    @media only screen and (max-width: 1024px) {
        margin: 0 auto;
        max-width: 100%;
        width: 90%;
    }
    ${props => props.secondrow && css`
        order: 2;
        max-width: 46.4%;
        @media only screen and (max-width: 1024px) {
            order: 1;
        }
    `}
`

const ProductSpecImage = styled.img`
    align-self: center;
    width: 100%;
    @media only screen and (max-width: 1024px) {
        margin-bottom: 40px;
    }
    ${props => props.secondrow && css`
        align-self: start;
    `}
`

const ProductSpecBucketCopy = styled.div`
    width: 45%;
    display: inline-block;
    padding-left: 50px;
    padding-bottom: 45px;
    vertical-align: bottom;
    @media only screen and (max-width: 1024px) {
        width: 90%;
        margin-right: auto;
        margin-left: auto;
        padding-left: 0;
        padding-right: 0;
        display: block;
        order: 1;
    }

    ${props => props.secondrow && css`
        width: 54.7%;
        padding-right: 14%;
        display: inline-block;
        vertical-align: top;
        margin-top: 40px;
        order: 1;
        @media only screen and (max-width: 1024px) {
            margin-top: 0px;
        }
    `}
`

const ProductSpecCopy = styled.p`
    
`

export default function ProductSpecBuckets({
    image1, 
    title1, 
    text1, 
    image2, 
    title2, 
    text2,
    productName
    }) {
    return (
        <>
        {(title1 && title2) &&
            <>
            <ProductSpecBucketContainer>
                {image1 && 
                    <ProductSpecBucketImageContainer>
                        <ProductSpecImage src={`/public/${image1}`} alt={title1} className='img-fluid' />
                    </ProductSpecBucketImageContainer>
                }
                <ProductSpecBucketCopy>
                    <h3>{title1}</h3>
                    <p style={{fontSize: '18px',lineHeight: '28px',marginBottom: '1em',}}>
                        <div dangerouslySetInnerHTML={{ __html: text1.replaceAll("#product_code#", `${productName}`) }}></div>
                    </p>
                </ProductSpecBucketCopy>
            </ProductSpecBucketContainer>
            <ProductSpecBucketContainer secondrow>
                {image2 &&
                    <ProductSpecBucketImageContainer secondrow>
                        <ProductSpecImage src={`/public/${image2}`} alt={title2} className='img-fluid' secondrow />
                    </ProductSpecBucketImageContainer>
                }
                <ProductSpecBucketCopy secondrow>
                    <h3>{title2}</h3>
                    <p style={{fontSize: '18px',lineHeight: '28px',marginBottom: '1em',}}>
                        <div dangerouslySetInnerHTML={{ __html: text2.replaceAll("#product_code#", `${productName}`) }}></div>
                    </p>
                </ProductSpecBucketCopy>
            </ProductSpecBucketContainer>
            </>
        }
        </>
    );
}