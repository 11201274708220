import styled, {css} from 'styled-components';
import { theme } from '../styles/theme';


const GlobalButtonText = styled.a`
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    font-weight: 900;
    text-transform: uppercase;
    color: ${props => props.color};
    &:hover {
        color: ${props => props.colorOver}
    }
`


export default function ButtonText({link, color, colorOver, text, noreferrer}) {
    return (
        <GlobalButtonText
            href={link}
            color={color ? color : theme.palette.main500} 
            colorOver={colorOver ? colorOver : theme.palette.grey800}
            rel={noreferrer ? 'noreferrer' : ''}
        >
            {text}
        </GlobalButtonText>
    )
}