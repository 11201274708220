import styled, { css } from "styled-components";
import { theme } from "../../styles/theme";
import { useState } from "react";

const ProductSpecDrawersContainer = styled.div`
  margin-top: 40px;
`;

const ProductSpecDrawerContainer = styled.div`
  border-top: 1px solid ${theme.palette.main500};
  cursor: pointer;
`;

const ProductSpecLabel = styled.p`
  font-weight: 900 !important;
  color: ${theme.palette.main500};
  text-transform: uppercase;
  position: relative;
  font-size: 16px;
  padding: 30px 30px 27px 20px;
  margin: 0;
`;

const ProductSpecArrow = styled.span`
    font-size: 11px;
    line-height: 11px;
    letter-spacing: 0;
    display: inline-block;
    position: absolute;
    right: 0;
    margin-top: 8px;
    transition: translate .3s;
    pointer-events: none;
    transform: ${(props) => props.rotate};,
`;

const ProductSpecCopy = styled.div`
  max-height: ${(props) => props.height};
  overflow-y: ${(props) => props.overflow};
`;
const ProductText = styled.div`
  color: ${theme.palette.grey700};
  text-transform: none !important;
  font-weight: 300;
  ul {
    list-style-type: none;
    li {
      text-indent: -12px;
      margin-bottom: 5px;
    }
    li:before {
      content: "-";
      text-indent: -5px;
      margin-right: 5px;
    }
  }
`;

const ListSpecs = styled.ul`
    list-style: none;
    padding: 0 0 0 15px !important;
    li {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0;
        padding-left: 15px;
        margin-bottom: 3px;
    }
    h5 {
      margin-top: 24px;
      margin-bottom: 10px;
    }
`

const ProductTextTitle = styled.h4`
  margin-bottom: 0;
  margin-top: 10px !important;
`;

const Icon = ({ rotate }) => {
  return (
    <ProductSpecArrow rotate={rotate}>
      <svg
        style={{
          fill: `${theme.palette.main500}`,
          display: "inline-block",
          width: "1em",
          height: "1em",
          strokeWidth: 0,
          stroke: "currentColor",
        }}
        viewBox="0 0 36 36"
        aria-labelledby="title"
      >
        <title>Arrow</title>
        <path d="M0.098,1.369l17.05,34.107c0.235,0.468,0.803,0.658,1.271,0.424 c0.184-0.091,0.334-0.24,0.425-0.424l17.05-34.107c0.233-0.469,0.041-1.036-0.429-1.269c-0.267-0.134-0.583-0.132-0.85,0.003 L18.47,8.412L1.359,0.095C0.887-0.133,0.321,0.065,0.095,0.536C-0.032,0.8-0.031,1.108,0.098,1.369z"></path>
      </svg>
    </ProductSpecArrow>
  );
};

export default function ProductSpecs({ features, productName }) {
  const [showDropdownFeature, setShowDropdownFeature] = useState(true);
  const handleClickDropdownFeature = () => {
    setShowDropdownFeature(!showDropdownFeature);
  };

  const [showDropdownTechSpec, setShowDropdownTechSpec] = useState(true);
  const handleClickDropdownTechSpec = () => {
    setShowDropdownTechSpec(!showDropdownTechSpec);
  };

  const [showDropdownManuals, setShowDropdownManuals] = useState(true);
  const handleClickDropdownManuals = () => {
    setShowDropdownManuals(!showDropdownManuals);
  };

  return (
    <ProductSpecDrawersContainer>
      {features.features != "" && (
        <ProductSpecDrawerContainer>
          <ProductSpecLabel onClick={handleClickDropdownFeature}>
            Características
            <Icon
              rotate={!showDropdownFeature ? "rotate(180deg)" : "rotate(0deg)"}
            />
            <ProductSpecCopy
              height={!showDropdownFeature ? "auto" : "0px"}
              overflow={!showDropdownFeature ? "visible" : "hidden"}
            >
              <ProductText>
                <ListSpecs>
                  {features.features.map((f) => (
                    <div dangerouslySetInnerHTML={{ __html: f }}></div>
                  ))}
                </ListSpecs>
              </ProductText>
            </ProductSpecCopy>
          </ProductSpecLabel>
        </ProductSpecDrawerContainer>
      )}

      {features.tech_specs != "" && (
        <ProductSpecDrawerContainer>
          <ProductSpecLabel onClick={handleClickDropdownTechSpec}>
            Especificaciones
            <Icon
              rotate={!showDropdownTechSpec ? "rotate(180deg)" : "rotate(0deg)"}
            />
            <ProductSpecCopy
              height={!showDropdownTechSpec ? "auto" : "0px"}
              overflow={!showDropdownTechSpec ? "visible" : "hidden"}
            >
              <ProductText>
                <ListSpecs>
                  {features.tech_specs.map((f) => (
                    <div dangerouslySetInnerHTML={{ __html: f }}></div>
                  ))}
                </ListSpecs>
              </ProductText>
            </ProductSpecCopy>
          </ProductSpecLabel>
        </ProductSpecDrawerContainer>
      )}

      {(features.OwnerManualFilePath[0] !== "" ||
        features.InstallationManualFilePath[0] !== "" ||
        features.NavigationUserManualFilePath[0] !== "") && (
        <ProductSpecDrawerContainer
          style={{ borderBottom: `1px solid ${theme.palette.main500}` }}
        >
          <ProductSpecLabel onClick={handleClickDropdownManuals}>
            Manuales
            <Icon
              rotate={!showDropdownManuals ? "rotate(180deg)" : "rotate(0deg)"}
            />
            <ProductSpecCopy
              height={!showDropdownManuals ? "auto" : "0px"}
              overflow={!showDropdownManuals ? "visible" : "hidden"}
            >
              <ProductText>
                {features.OwnerManualFilePath[0] !== "" && (
                  <div>
                    <a
                      target="_blank"
                      rel="noopener"
                      href={`../public/${features.OwnerManualFilePath[0]}`}
                    >
                      Manual de usuario
                    </a>
                  </div>
                )}
                {features.InstallationManualFilePath[0] !== "" && (
                  <div>
                    <a
                      target="_blank"
                      rel="noopener"
                      href={`../public/${features.InstallationManualFilePath[0]}`}
                    >
                      Manual de instalación
                    </a>
                  </div>
                )}
                {features.NavigationUserManualFilePath[0] !== "" && (
                  <div>
                    <a
                      target="_blank"
                      rel="noopener"
                      href={`../public/${features.NavigationUserManualFilePath[0]}`}
                    >
                      Manual de navegación de usuario
                    </a>
                  </div>
                )}
                {features.dataSheetFilePath[0] !== "" && (
                  <div>
                    <a
                      target="_blank"
                      rel="noopener"
                      href={`../public/${features.dataSheetFilePath}`}
                    >
                      Ficha técnica
                    </a>
                  </div>
                )}
                {productName === "R-A90S" && (
                  <div>
                    <a
                      target="_blank"
                      rel="noopener"
                      href="../public/assets/manuals/AG_R-A90S.pdf"
                    >
                      Guía de aplicación
                    </a>
                  </div>
                )}
              </ProductText>
            </ProductSpecCopy>
          </ProductSpecLabel>
        </ProductSpecDrawerContainer>
      )}
    </ProductSpecDrawersContainer>
  );
}
