import styled, {css} from 'styled-components';
import { theme } from '../../styles/theme.js';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slider-flagship.css";
import GlobalFlagshipNav from './GlobalFlagshipNav.js';


const SlideIn = styled.div`
    position: relative;
    margin-top: 75px;
    img {
        width: 100%;
    }
`
const Content = styled.div`
    width: 100%;
    height: 100%;
`

const TitleBox = styled.div`
    position: absolute;
    width: 80%;
    max-width: 640px;
    top: 50%;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    color: white;
    margin: 0 auto;
    text-align: center;
    h1 {
        font-size: 70px;
        line-height: 1;
        color: white;
        margin: 0;
    } 
`

const DotsUl = styled.ul`
    bottom: 100px;
    li {
        margin: 0 10px;
        button {
            width: 26px;
            height: 4px;
            padding: 0;
            background-color: white;
            button {
                background-color: ${theme.palette.grey400};
            }
            &:before {
                content: "";
            }
        }
    }
    li.slick-active {
        button {
            background-color: ${theme.palette.main600};
        }
    }
`


export default function Carousel ({data}) {

    const settings = {
        fade: true,
        autoplay: true,
        autoplaySpeed: 4000,
        dots: false,
        appendDots: dots => <DotsUl>{dots}</DotsUl>,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: "",
        beforeChange: "",
    };


    return (
    <div style={{position: "relative"}}>
        <Slider {...settings}>
            <div>
                <SlideIn>
                    <Content>
                        <picture>
                            <source 
                                type="image/png" 
                                media="(max-width:767px)" 
                                srcset={require('../../images/alpineflagship/slide1_sp.png')} 
                            />
                            <source 
                                type="image/png" 
                                media="(min-width:768px)" 
                                srcset={require('../../images/alpineflagship/slide1_pc.jpg')} 
                            />
                            <img alt="" />
                        </picture>
                    </Content>
                </SlideIn>
            </div>
            <div>
                <SlideIn>
                    <Content>
                        <picture>
                            <source 
                                type="image/png" 
                                media="(max-width:767px)" 
                                srcset={require('../../images/alpineflagship/slide2_sp.png')} 
                            />
                            <source 
                                type="image/png" 
                                media="(min-width:768px)" 
                                srcset={require('../../images/alpineflagship/slide2_pc.jpg')} 
                            />
                            <img alt="" />
                        </picture>
                    </Content>
                </SlideIn>
            </div>
            <div>
                <SlideIn>
                    <Content>
                        <picture>
                            <source 
                                type="image/png" 
                                media="(max-width:767px)" 
                                srcset={require('../../images/alpineflagship/slide3_sp.png')} 
                            />
                            <source 
                                type="image/png" 
                                media="(min-width:768px)" 
                                srcset={require('../../images/alpineflagship/slide3_pc.jpg')} 
                            />
                            <img alt="" />
                        </picture>
                    </Content>
                </SlideIn>
            </div>
        </Slider>
        <div class="nav">
            <GlobalFlagshipNav />
        </div>
    </div>
    )
}