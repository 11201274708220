import React from "react";
import styled, { css } from "styled-components";
import { theme } from "../../styles/theme";

const ProductSpecBucketsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 45px;

  @media only screen and (max-width: 560px) {
    flex-direction: column;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 24px;
  }
`;

const ProductSpecBucketContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: flex-start !important;
  max-width: 100%;
  width: 100%;
  @media only screen and (max-width: 560px) {
    max-width: 100%;
    width: 100%;
  }
`;

const ProductSpecBucketCopy = styled.div`
  padding: 0 45px;
  p {
    white-space: pre-line;
  }
`;

export default function ProductSpecGrid({ descriptions, productName }) {
  return (
    <ProductSpecBucketsContainer>
      <ProductSpecBucketContainer>
        {descriptions.map((el, i) => (
          <ProductSpecBucketCopy key={i}>
            <h5>{el.title}</h5>
            <p>
            <div 
              dangerouslySetInnerHTML={{ __html: `${el.description}`.replaceAll("#product_code#", `${productName}`) }}>
            </div>
            </p>
          </ProductSpecBucketCopy>
        ))}
      </ProductSpecBucketContainer>
    </ProductSpecBucketsContainer>
  );
}
