import styled, {css} from 'styled-components';
import React from 'react';
import { theme } from '../../styles/theme';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { useState } from 'react';

const HeroContainer = styled.div`
    padding-left: 45px;
    padding-right: 45px;
    padding-top: 45px;
    padding-bottom: 45px;
    max-width: 1170px;
    margin-right: auto;
    margin-left: auto;
    background-color: #fff;
    position: relative;
`

const ImageConatiner = styled.div`
    position: absolute;
    right: 45px;
    left: 45px;
    height: calc(100% - 45px);
`

const LargeImg = styled.div`
    width: 70%;
    position: relative;
    margin-left: ${props => props.marginLeft}; auto;
    margin-right: ${props => props.marginRigh}; 0;
    overflow: hidden;
    z-index: 20;
    @media only screen and (max-width: 768px) {
        width: 100%;s
    }
`

const SmallImg = styled.div`
    width: 50%;
    margin-top: 15px;
    position: relative;
    margin-left: ${props => props.marginLeft}; auto;
    margin-right: ${props => props.marginRigh}; 0;
    overflow: hidden;
    z-index: 20;
    @media only screen and (max-width: 768px) {
        display: none;
    }
`

const Img = styled.img`
    width: 100%;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
`

const ContentContainer = styled.div`
    position: relative;
    z-index: 100;
    max-width: 540px;
`

const Content = styled.div`
    max-width: 540px;
    padding: 50px 40px;
    margin-top: 280px;
    margin-left: ${props => props.marginLeft}; auto;
    margin-right: ${props => props.marginRigh}; 0;
    background-color: #fff;
    z-index: 30;
    position: relative;
    @media only screen and (max-width: 768px) {
        padding: 30px 20px;
    }
`

const Play = styled.span`
    justify-content: center;
    align-items: center;
    background: #00549a;
    border-radius: 50%;
    display: flex;
    position: absolute;
    top: 50%; 
    left: 50%;
    opacity: .9;
    transition: opacity .2s ease-in-out;
    transform: translate(-50%,-50%);
    width: 100px;
    height: 100px;
    svg {
        fill: #fff;
        width: 35px;
        margin-left: 8px;
    }
`

    
function ModalVideo ({src, videoSrc}) {

    const customStyles = {
        overlay: {
            position: 'fixed',
            zIndex: 9999,
            backgroundColor: 'rgba(40,35,31,.6)'
        },
        content: {
            backgroundColor: 'white',
            boxSizing: 'border-box',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            height: 'calc(100% - 120px)',
            position: 'absolute',
            top: '50%',
            left: '50%',
            padding: 0,
            transform: 'translate(-50%,-50%)',
            width: 'calc(100% - 120px)',
            zIndex: '50',
        },
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <div>
            <div onClick={openModal} style={{cursor: 'pointer'}}>
                <Play>
                    <svg class="icon icon-play" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                        <path class="st0" d="M2.9,1.3C1.4,0.6,0.2,1.3,0.2,3v25.7c0,1.6,1.2,2.4,2.7,1.7l26.3-13.1c1.5-0.7,1.5-1.9,0-2.7L2.9,1.3z"></path>
                    </svg>
                </Play>
                <Img src={src} />
            </div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <button 
                    onClick={closeModal}
                    style={{
                        background: 'transparent',
                        border: 0,
                        borderRadius: '50%',
                        boxShadow: 'none',
                        cursor: 'pointer',
                        padding: 0,
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        transition: 'background .2s ease-in-out',
                        zIndex: 100,
                        
                    }}
                >
                    <svg viewBox="0 0 16 15" fill="white" width='20px' height='20px'>
                        <polygon points="9.3,7.5 15.4,1.5 14.7,0.8 14,0.1 8,6.2 2,0.1 1.3,0.8 0.6,1.5 6.7,7.5 0.6,13.5 1.3,14.2 2,14.9 8,8.8 14,14.9  14.7,14.2 15.4,13.5 "></polygon>
                    </svg>
                </button>
                
                <iframe 
                    width="100%" 
                    height="100%" 
                    src={videoSrc} 
                    title="YouTube video player" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen
                    autoplay
                >
                </iframe>
            </Modal>
        </div>
    )
}



export default function HeroImages({
    alignRight, 
    srcLarge, 
    srcSmall, 
    title, 
    subtitle, 
    children, 
    videoSrc
}) {

    return (
        <HeroContainer>
            <ImageConatiner>
                {srcLarge &&
                    <LargeImg 
                        marginLeft={alignRight ? '0' : 'auto'}
                        marginRigh={alignRight ? 'auto' : '0'}
                    >
                        <Img src={`/public/${srcLarge}`}  />
                    </LargeImg>
                }
                {srcSmall && 
                    <SmallImg
                        marginLeft={alignRight ? '0' : 'auto'}
                        marginRigh={alignRight ? 'auto' : '0'}
                    >
                        {videoSrc ? (
                        <ModalVideo 
                            src={`/public/${srcSmall}`}
                            videoSrc={`https://www.youtube.com/embed/${videoSrc}`}
                        />
                        ) : ( 
                        <Img src={`/public/${srcSmall}`}  />
                        )}
                        
                    </SmallImg>
                }
            </ImageConatiner>
            <ContentContainer>
                <Content
                    marginLeft={alignRight ? 'auto' : '0'}
                    marginRigh={alignRight ? '0' : 'auto'}
                >   
                    {subtitle &&
                        <p>{subtitle}</p>
                    }
                    <h1>{title}</h1>
                    <div>{children}</div>
                </Content>
            </ContentContainer>
        </HeroContainer>
    )
}