import styled, {css} from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const Search = styled.button`
    position: absolute;
    right: 0;
    background: transparent;
    border: none;
    margin: 0;
    padding: 12px 16px;
    pointer-events: all;
    cursor: pointer;
    color: ${props => props.iconColor ? props.iconColor : 'white'};
    @media only screen and (min-width: 1005px) {
        display: none;
    }
    svg {
        width: 20px !important;
        height: 20px;
    }
    
`

export default function SearchMobile ({onClick, iconColor}) {
    return (
        <Search onClick={onClick} iconColor={iconColor}>
            <FontAwesomeIcon icon={faSearch} />
        </Search>
    );
}