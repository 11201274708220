import Header from "../components/header/Header";
import ButtonArrow from "../components/ButtonArrow";

const Page404 = () => {
  return (
    <>
      <Header sectionIn />
      <div 
        style={{
          textAlign:'center', 
          minHeight:'400px',
          margin: '150px auto 0',
          maxWidth: '800px',
        }}
      >
        <p>Error 404</p>
        <h3>La página que estás buscando parace que no existe.</h3>
        <ButtonArrow 
          link="/"
          btnText="ir al inicio"
        />
      </div>
    </>
  )
};
export default Page404;
