import styled, { css } from "styled-components";
import { theme } from "../../styles/theme";
import { dataCategories } from "../../data/dataCategories";

const HomeCategoriesWrapper = styled.div`
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin: 0 auto 50px;
    padding: 0 20px;
  }

  @media only screen and (max-width: 1210px) {
    width: calc(100% - 40px);
    margin-left: auto;
    margin-right: auto;
  }

  max-width: 1250px;
  width: 100%;
  margin: 0 auto 100px;
  opacity: 1;
  transition: 0.3s ease-in-out;
`;

const HomeCategoriesHeader = styled.h1`
  text-align: center;
  padding-bottom: 60px;
  overflow: hidden;
  font-size: 50px !important;

  @media only screen and (max-width: 768px) {
    line-height: 50px;
    letter-spacing: -0.025px;
  }
`;

const HomeCategoriesContainer = styled.div`
  @media only screen and (max-width: 768px) {
    margin-left: auto;
    margin-top: auto;
  }

  @media only screen and (max-width: 1024px) {
    margin-left: -40px;
    margin-top: -25px;
  }

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const HomeCategoriesCategoryContainer = styled.div`
  display: inline-block;
  padding: 0 20px;
  width: 100%;

  @media only screen and (min-width: 767px) and (max-width: 1023px) {
    width: 50%;
  }

  @media only screen and (min-width: 1024px) {
    width: 33.333%;
  }

`;

const HomeCategoriesCategoryWrapper = styled.div``;

const HomeCategoriesCategory = styled.div`
  @media only screen and (max-width: 768px) {
    margin-left: auto;
    margin-top: auto;
    margin-bottom: 10%;
  }
  @media only screen and (max-width: 1024px) {
    margin-left: 40px;
    margin-top: 25px;
  }
  margin-bottom: 50px;
  padding: 0 0 40px;
  position: relative;
  border: 2px solid ${theme.palette.grey300};
  transition: all 0.2s;
  &:hover {
    border-color: ${theme.palette.main500};
    transition: all 0.2s;
  }
`;

const HomeCategoriesLabel = styled.p`
  font-weight: 900 !important;
  font-size: 15px;
  text-transform: uppercase;
  padding: 3px 23px 2px 20px;
  position: absolute;
  top: -1px;
  left: -1px;
  color: #fff;
  overflow: hidden;
  width: auto;
  height: auto;
  margin: 0;

  &:before {
    background-color: ${theme.palette.main500};
    top: 0;
    left: 0;
    z-index: 1;
    height: 100%;
    transition: all 0.2s;
    ${HomeCategoriesCategory}:hover & {
      background-color: ${theme.palette.grey800};
      transition: all 0.2s;
    }
  }

  &:before,
  &:after {
    width: 100%;
    content: "";
    display: block;
    position: absolute;
    transition: width 0.8s cubic-bezier(0.23, 1, 0.32, 1) 0.7s,
      opacity 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }

  @media only screen and (max-width: 768px) {
    padding: 4px 23px 5px 16px;
    font-size: 16px;
    font-size: 1rem;
    line-height: 24px;
    line-height: 1.5rem;
    letter-spacing: 0;
  }

  span {
    z-index: 1;
    display: inline-block;
    position: relative;
  }
`;

const HomeCategoriesImageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 50px 35px 15px;
  img {
    align-self: start;
    max-width: 100%;
    flex-shrink: 0;
    opacity: 1;
    transform: scale(0.95);
    transition: 0.6s cubic-bezier(0.23, 1, 0.32, 1);
    ${HomeCategoriesCategory}:hover & {
      transform: scale(1);
      transition: 0.6s cubic-bezier(0.23, 1, 0.32, 1);
    }
  }
`;

export default function HomeCategories() {
  return (
    <HomeCategoriesWrapper>
      <HomeCategoriesHeader>Categorías</HomeCategoriesHeader>
      <HomeCategoriesContainer>
        {dataCategories.map((i, index) => (
          <HomeCategoriesCategoryContainer key={index}>
            <HomeCategoriesCategoryWrapper>
              <HomeCategoriesCategory>
                <a href={i.link}>
                  <HomeCategoriesLabel>
                    <span>{i.name}</span>
                  </HomeCategoriesLabel>
                  <HomeCategoriesImageContainer>
                    <img
                      src={require(`../../images/home/categories/${i.image}`)}
                    />
                  </HomeCategoriesImageContainer>
                </a>
              </HomeCategoriesCategory>
            </HomeCategoriesCategoryWrapper>
          </HomeCategoriesCategoryContainer>
        ))}
      </HomeCategoriesContainer>
    </HomeCategoriesWrapper>
  );
}
