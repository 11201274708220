

export const dataCategories = 
[
    {
        "id": "01",
        "name":"RECEPTORES",
        "image":"receptores.jpg",
        "link":"/productos/Receptores",
    },
    {
        "id": "02",
        "name":"AMPLIFICADORES",
        "image":"amplificadores.jpg",
        "link":"/productos/Amplificadores",
    },
    {
        "id": "03",
        "name":"ALTAVOCES",
        "image":"altavoces.jpg",
        "link":"/productos/Altavoces",
    },
    {
        "id": "04",
        "name":"SUBWOOFERS",
        "image":"subwoofers.jpg",
        "link":"/productos/Subwoofers",
    },
    {
        "id": "05",
        "name":"PROCESADORES DE AUDIO",
        "image":"procesadores-de-sonido.jpg",
        "link":"/productos/Procesadores de audio",
    },
    {
        "id": "06",
        "name":"ASISTENCIA AL CONDUCTOR",
        "image":"asistencia-al-conductor.jpg",
        "link":"/productos/Asistencia al conductor",
    },
    {
        "id": "07",
        "name":"ESPECÍFICO DE VEHÍCULOS",
        "image":"vehiculos.jpg",
        "link":"/productos/Receptores",
    },
    {
        "id": "08",
        "name":"MARINOS Y POWERSPORTS",
        "image":"marinos-y-receptores.jpg",
        "link":"/productos/Marinos",
    },

];










