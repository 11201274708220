export default function SpecsFlagship () {
    return (
        <div className="o-section__spec">
            <div id="js-spec_line" className="p-index__spec_wrap">
                <h2 className="p-index__spec_line">
                    <picture>
                        <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/spec_line_sp.png')} />
                        <img src={require('../../images/alpineflagship/spec_line_pc.png')} alt="Hi Spec Hi-Res sounds" />
                    </picture>
                    <span id="js-spec_line_target" className="p-index__spec_line_bg" style={{height: '607.781px'}}></span>
                </h2>
                <h3 className="p-index__spec_title is-l_us">
                    <picture>
                        <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/spec_title_sp.png')} />
                        <img src={require('../../images/alpineflagship/spec_title_pc.png')} alt="Delivering a studio recording-level sound" />
                    </picture>
                </h3>
                <div className="p-index__spec_sub is-l_us">
                    <p>Nace un sistema de sonido de alta resolución de espectro completo.</p>
                </div>
                <div className="o-text_box__base is-c_wh is-top_spec">
                    <p>Las fuentes digitales de sonido han progresado hacia bits y frecuencias de muestreo más altos a lo largo del tiempo. Los rangos de especificación más altos utilizados en los estudios de grabación de música son 384kHz/32 bits. Sin embargo, las fuentes de sonido pierden calidad cuando se reproducen en CDs o por streaming. El audio de Alta Resolución fue creado para satisfacer el deseo de los oyentes de disfrutar la música como fue grabada originalmente. La reproducción de fuentes de sonido de espectro completo requiere una capacidad de reproducción avanzada y un entorno ideal para escucharla. El sistema Alpine F#1 Status reproducirá la mejor fuente de sonido de alta resolución, transmitiendo las intenciones originales de los artistas</p>
                </div>
                <div className="p-index__spec_thumb">
                    <picture>
                        <source 
                            type="image/png" 
                            media="(max-width:767px)" 
                            srcset={require('../../images/alpineflagship/spec_sp.png')}
                        />
                        <img src={require('../../images/alpineflagship/spec_pc.png')} alt="" />
                    </picture>
                </div>
                <a href="/alpineflagship/aprendemas/#lm1" className="p-index__learn_more1 is-l_us">
                    <picture>
                        <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/learn_more1_sp.png')} />
                        <img src={require('../../images/alpineflagship/learn_more1_pc.png')} alt="Learn more: About high resolution" />
                    </picture>
                </a>
            </div>
        </div>
    )
}