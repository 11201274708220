import Header from "../../components/header/Header";
import "./index.css";
import "./top.css";
import IntroFlagship from "../../components/flagship/IntroFlagship";
import SpecsFlagship from "../../components/flagship/SpecsFlagship";
import PerfectSyncFlagship from "../../components/flagship/PerfectSyncFlagship";
import CommitmentFlagship from "../../components/flagship/CommitmentFlagship";
import ProductInfoFlagship from "../../components/flagship/ProductInfoFlagship";
import SliderFlagship from "../../components/flagship/SliderFlagship";
import GlobalFlagshipNav from "../../components/flagship/GlobalFlagshipNav";

export default function Flagship () {
    return (
        <>
            <Header sectionIn />
            <SliderFlagship />
                <main>
                    <div className="l-main__wrap">
                        <div className="o-box__nav_flagship is-top_sp">
                            <GlobalFlagshipNav />
                        </div>
                        
                        <IntroFlagship />
                        <SpecsFlagship />
                        <PerfectSyncFlagship />
                        <CommitmentFlagship />
                        <ProductInfoFlagship />
                    </div>
                </main>

        </>
    );
}



    

