export default function ProductInfoFlagship () {
    return (
        <div id="js-pi_line" className="o-section__product_info">

            <h2 className="p-index__pi_line">
                <picture>
                    <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/pi_line_sp.png')} />
                    <img src={require('../../images/alpineflagship/pi_line_pc.png')} alt="PRODUCT INFORMATION" />
                </picture>
                <span id="js-pi_line_target" className="p-index__pi_line_bg" style={{height: '1626.54px'}}></span>
            </h2>

            <div className="o-flex_box__pi_care">
                <div className="p-index__pi_title">
                    <img src={require('../../images/alpineflagship/pi_band.png')} alt="" />
                </div>
                <div className="p-index__pi_line_care">* El Alpine F#1 Status estará disponible a la venta exclusivamente como un sistema completo, y sus componentes no estarán disponibles por separado.</div>
            </div>

            <ul className="p-index__product_info_list">
                <li className="is-item_thumb2">
                    <div className="o-box__fade_item js-fade_item is-fade_active">
                    <div className="p-index__item_thumb2">
                        <picture>
                        <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/item_thumb2_sp.png')} />
                            <img src={require('../../images/alpineflagship/item_thumb2_pc.png')} alt="" />
                        </picture>
                    </div>

                    <a href="/alpineflagship/producto#pl_hds" className="is-item_thumb2">
                        <picture>
                        <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/learn_more_sp.png')} />
                            <img src={require('../../images/alpineflagship/learn_more_pc.png')} alt="Learn more" />
                        </picture>
                    </a>
                    </div>
                </li>
                <li className="is-item_thumb3">
                    <div className="o-box__fade_item js-fade_item is-fade_active">
                        <div className="p-index__item_thumb3">
                            <picture>
                            <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/item_thumb3_sp.png')} />
                                <img src={require('../../images/alpineflagship/item_thumb3_pc.png')} alt="" />
                            </picture>
                        </div>

                        <a href="/alpineflagship/producto#pl_dap" className="is-item_thumb3">
                            <picture>
                            <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/learn_more_sp.png')} />
                                <img src={require('../../images/alpineflagship/learn_more_pc.png')} alt="Learn more" />
                            </picture>
                        </a>
                    </div>
                </li>
                <li className="is-item_thumb4">
                    <div className="o-box__fade_item js-fade_item is-fade_active">
                    <div className="p-index__item_thumb4">
                        <picture>
                        <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/item_thumb4_sp.png')} />
                            <img src={require('../../images/alpineflagship/item_thumb4_pc.png')} alt="" />
                        </picture>
                    </div>

                    <a href="/alpineflagship/producto#pl_hdp" className="is-item_thumb4">
                        <picture>
                        <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/learn_more_sp.png')} />
                            <img src={require('../../images/alpineflagship/learn_more_pc.png')} alt="Learn more" />
                        </picture>
                    </a>
                    </div>
                </li>
                <li className="is-item_thumb5">
                    <div className="o-box__fade_item js-fade_item is-fade_active">
                    <div className="p-index__item_thumb5">
                        <picture>
                        <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/item_thumb5_sp.png')} />
                            <img src={require('../../images/alpineflagship/item_thumb5_pc.png')} alt="" />
                        </picture>
                    </div>

                    <a href="/alpineflagship/producto#pl_hda" className="is-item_thumb5">
                        <picture>
                        <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/learn_more_sp.png')} />
                            <img src={require('../../images/alpineflagship/learn_more_pc.png')} alt="Learn more" />
                        </picture>
                    </a>
                    </div>
                </li>
                <li className="is-item_thumb6">
                    <div className="o-box__fade_item js-fade_item is-fade_active">
                    <div className="p-index__item_thumb6">
                        <picture>
                        <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/item_thumb6_sp.png')} />
                            <img src={require('../../images/alpineflagship/item_thumb6_pc.png')} alt="" />
                        </picture>
                    </div>

                    <a href="/alpineflagship/producto#pl_hdz" className="is-item_thumb6">
                        <picture>
                        <source type="image/png" media="(max-width:767px)" srcset={require('../../images/alpineflagship/learn_more_sp.png')} />
                            <img src={require('../../images/alpineflagship/learn_more_pc.png')} alt="Learn more" />
                        </picture>
                    </a>
                    </div>
                </li>
            </ul>

      </div>
    )
} 
